import React from "react";
import { Card, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Href } from "../../Constants";
import { useWallet } from "../../Context/WalletContext";

const ConnectWalletModal = ({ connectWallet }) => {
  const { editModal, toggleEdit, setActiveTab, activeTab, currentModal, setCurrentModal, isExtensionInstalled, setAccounts, accounts, setIsExtensionInstalled, selectedAccount, setSelectedAccount } = useWallet();

  const subwalletLink = "https://www.subwallet.app/";
  const handleAccountSelect = (account) => {
    localStorage.setItem("selectedAccount", JSON.stringify(account));
    setSelectedAccount(account);
  };

  const disconnectWallet = () => {
    localStorage.removeItem("isConnectedToWallet");
    setSelectedAccount(null);
    localStorage.removeItem("selectedAccount");
    setAccounts([]);
  };

  const handleConnectClick = () => {
    if (isExtensionInstalled) {
      accounts?.length ? disconnectWallet() : connectWallet();
    } else {
      window.open(subwalletLink, "_blank");
    }
  };

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
      <ModalHeader className="Connect-wallet" toggle={currentModal == "connect" ? toggleEdit : false}>
        {currentModal === "connect" ? "Connect" : "Accounts"}
        <button className="btn-primary-invert  ms-2" onClick={() => setCurrentModal(currentModal === "connect" ? "account" : "connect")}>
          {currentModal === "account" && <i className="fa fa-angle-left me-2"/>}
          Go to {currentModal === "account" ? "connect" : "accounts"}
          {currentModal === "connect" && <i className="fa fa-angle-right "/>}
        </button>
        {currentModal === "account" && selectedAccount != null && (
          <a href={Href} className="disconnect-btn" onClick={() => setSelectedAccount(null)}>
            disconnect
            <i className="fa-solid fa-link-slash ms-1"/>
          </a>
        )}
      </ModalHeader>
      <ModalBody>
        {currentModal === "connect" ? (
          <div className="custom-tabs-transaction">
            <Nav tabs>
              <NavItem>
                <NavLink href={Href} className={`${activeTab === "1" ? "active" : ""}`} onClick={() => setActiveTab("1")}>
                  Extensions
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="6" className=" mt-3">
                    <Card className="custom-wallet-card">
                      <div className="wallet-logo">
                        <div className="logo">
                          <img className="img-fluid" src={`../assets/svg/sub-wallet.svg`} alt="wallet" />
                        </div>
                        <a href={Href} className="connect-btn" onClick={handleConnectClick}>
                          {isExtensionInstalled ? (accounts?.length ? "Disconnect" : "+ Connect") : "Please install extension"}
                        </a>
                      </div>
                      <div className="data-info">
                        <h4 className="title">SubWallet</h4>
                        <a href={subwalletLink} target="_blank" className="link-subwallet" rel="noreferrer">
                          subwallet.app <i className="fa-solid fa-up-right-from-square ms-1"/>
                        </a>
                      </div>
                    </Card>
                  </Col>
                  <Col sm="6" className=" mt-3">
                    <Card className="custom-wallet-card">
                      <div className="wallet-logo">
                        <div className="logo">
                          <img className="img-fluid" src={`../assets/svg/sub-wallet.svg`} alt="wallet" />
                        </div>
                        <a href={Href} className="connect-btn" onClick={handleConnectClick}>
                          {isExtensionInstalled ? (accounts?.length ? "Disconnect" : "+ Connect") : "Please install extension"}
                        </a>
                      </div>
                      <div className="data-info">
                        <h4 className="title">Talisman</h4>
                        <a href={subwalletLink} target="_blank" className="link-subwallet" rel="noreferrer">
                          talisman.xyz <i className="fa-solid fa-up-right-from-square ms-1"/>
                        </a>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12" className="mt-3">
                    <Card className="custom-wallet-card">No data found</Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        ) : accounts?.length ? (
          accounts?.map((item) => (
            <div className={`custom-account-card mt-2 ${selectedAccount?.meta?.name === item?.meta?.name ? "active" : ""}`} onClick={() => handleAccountSelect(item)}>
              <div className="custom-card-item">
                <div className="custom-test-item">
                  <img className="img-fluid custom-test-logo" src={`../assets/svg/sub-wallet-icon.svg`} alt="sub-wallet" />
                  <span>{item?.meta?.name}</span>
                </div>
                <div className="subwallet-logo">
                  <img className="img-fluid" src={`../assets/svg/sub-wallet.svg`} alt="sub-wallet" />
                </div>
              </div>
              <div className="custom-item">
                <span className="sub-title">{item?.address}</span>
              </div>
            </div>
          ))
        ) : (
          "No accounts found"
        )}
      </ModalBody>
    </Modal>
  );
};

export default ConnectWalletModal;
