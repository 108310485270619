import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createExchangeAccounts, updateExchangeAccounts } from "../../Api/ApiList";
import { usePerformMutation } from "../../Api/PerformMutation"; // Use the mutation hook
import FormInput from "../../CommonComponents/InputFields/FormInput";
import { useExchangeBalance } from "../../Context/ExchangeBalanceContext";

const EditModal = ({ refetchGetData, isEditMode, setEditMode }) => {
  const { editModal, editExchangeBalanceData, toggleEdit } = useExchangeBalance();
  const [loading, setLoading] = useState(false);

  // Use the mutation hook for performing API requests
  const { mutate } = usePerformMutation(
    () => {
      refetchGetData(); // Refetch the data after mutation
      reset();
      toggleEdit();
      setEditMode(false);
    },
    () => {
      setLoading(false); // Reset loading state on error
    }
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      address: "",
      label: "",
    },
  });

  const handleAccount = (data) => {
    setLoading(true);
    const apiEndpoint = isEditMode ? updateExchangeAccounts : createExchangeAccounts;
    const conditionalData = {
      id: data.id,
      label: data.label,
      address: data.address,
    };
    // Trigger the mutation function
    mutate({ url: apiEndpoint, data: conditionalData });
    setLoading(false);
  };

  useEffect(() => {
    if (isEditMode && editExchangeBalanceData) {
      setValue("id", editExchangeBalanceData.id);
      setValue("address", editExchangeBalanceData.address);
      setValue("label", editExchangeBalanceData.label);
    } else {
      reset();
    }
  }, [isEditMode, editExchangeBalanceData, setValue, reset]);

  return (
    <Modal
      size="lg"
      centered
      isOpen={editModal}
      toggle={() => {
        toggleEdit();
        setEditMode(false);
      }}
      className="transaction-modal"
    >
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Account" : "Create Account"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleAccount)} noValidate>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormInput helperText={'Use comma separator to add more than 1 addresses'} label={"Address:"} name={"address"} errors={errors} register={register} placeholder={"Enter address"} classes={{ inputClass: "search-transaction" }} validationRules={{ required: "Address is required" }} />
            </Col>
            <Col sm="12">
              <FormInput label={"Label:"} name={"label"} errors={errors} register={register} placeholder={"Enter label"} classes={{ inputClass: "search-transaction" }} validationRules={{ required: "Label is required" }} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="" type="submit">
            {isEditMode ? "Update" : "Create"} {loading && <i className="fa-solid fa-circle-notch fa-spin" />}
          </Button>
          <Button
            color="light"
            className="border border-1"
            onClick={() => {
              toggleEdit();
              reset();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
