import { hexToU8a } from "@polkadot/util";
import { blake2AsHex } from "@polkadot/util-crypto";
import React, { Fragment, useState } from "react";
import { Plus } from "react-feather";
import toast from "react-hot-toast";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { useWallet } from "../../Context/WalletContext";
import Loader from "../../Layout/Loader";
import TableTop from "../Common/TableTop";
import DecodedDetails from "./DecodedDetails";
import GenerateCallDataModal from "./GenerateCallData";

const AvailWallet = () => {
  const { api } = useWallet();
  const [callData, setCallData] = useState("");
  const [decodedData, setDecodedData] = useState(null);
  const [callDataModal, setCallDataModal] = useState(false);
  const toggleEdit = () => setCallDataModal((prev) => !prev);
  const [sender, setSender] = useState("");
  const [callHash, setCallHash] = useState("");

  const [isDecoding, setIsDecoding] = useState(false);

  const decodeCallData = async () => {
    if (!api || !callData) return;
    setIsDecoding(true);

    try {
      const callHash = await blake2AsHex(hexToU8a(callData));
      setCallHash(callHash);
      // Clean the input hex string
      const cleanHex = callData.startsWith("0x") ? callData : `0x${callData}`;

      // Convert hex to Uint8Array
      const callDataU8a = hexToU8a(cleanHex);

      // Try to decode as Call directly
      const decodedCall = api.createType("Call", callDataU8a);

      // Get the call index
      const callIndex = decodedCall.callIndex;

      // Find the call in metadata
      const { method, section } = api.registry.findMetaCall(callIndex);

      // Get the arguments
      const args = decodedCall.args.map((arg) => arg.toHuman());

      // Set the decoded information
      setDecodedData({
        section,
        method,
        args,
        callIndex: callIndex.toString(),
        raw: {
          hex: cleanHex,
          bytes: Array.from(callDataU8a),
        },
      });
    } catch (error) {
      try {
        const metadata = api.runtimeMetadata.asLatest;

        // Get the first two bytes for pallet and call indices
        const callDataArray = Array.from(hexToU8a(callData));
        const palletIndex = callDataArray[0];
        const callIndex = callDataArray[1];

        // Find the pallet
        const pallet = metadata.pallets.find((p) => p.index.toNumber() === palletIndex);

        if (!pallet) {
          toast.error(`Pallet with index ${palletIndex} not found`);
          // throw new Error(`Pallet with index ${palletIndex} not found`);
        }

        // Get the calls for this pallet
        if (!pallet.calls.isSome) {
          toast.error(`No calls found for pallet ${pallet.name.toString()}`);
          // throw new Error(`No calls found for pallet ${pallet.name.toString()}`);
        }

        const calls = pallet.calls.unwrap();
        const callVariants = calls.type.def.asVariant.variants;
        const call = callVariants.find((v) => v.index.toNumber() === callIndex);

        if (!call) {
          toast.error(`Call with index ${callIndex} not found`);
          // return;
        }

        setDecodedData({
          section: pallet.name.toString(),
          method: call.name.toString(),
          args: "Arguments could not be decoded. Raw data provided.",
          raw: {
            hex: callData,
            bytes: callDataArray,
          },
          indices: {
            pallet: palletIndex,
            call: callIndex,
          },
        });

        console.log("Decoded using fallback method:", {
          pallet: pallet.name.toString(),
          call: call.name.toString(),
        });
      } catch (fallbackError) {
        toast.error("Fallback decode error:", fallbackError);
        setDecodedData({
          error: `${error.message}\nFallback error: ${fallbackError.message}`,
          input: callData,
        });
      }
    } finally {
      setIsDecoding(false);
    }
  };

  if (!api) return <Loader />;

  return (
    <Fragment>
      <section className="common-table custom-decode-table">
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <h4>Avail Transaction Decoder </h4>
            <Button size="sm" onClick={toggleEdit} color="transparent" className="btn-outline-secondary">
              <Plus size={15} />
              Generate Call Data
            </Button>
          </CardHeader>
          <CardBody>
            <TableTop noPaginate noSearchbar />
            <Row className="g-3">
              <Col sm="12">
                <FormGroup className="form-group custom-label-data">
                  <label className="mb-2 form-label ">Avail Transaction Data :</label>
                  <textarea className="form-control search-transaction" placeholder="Enter Avail transaction call data (hex format)..." value={callData} onChange={(e) => setCallData(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <Button color="transparent" className="btn-dark me-2" onClick={decodeCallData}>
              {isDecoding ? "Decoding..." : "Decode"}
            </Button>
            {/* <Button color="lo" className="border border-1" onClick={signTransaction}>
              Sign Transaction
            </Button> */}
            {decodedData && <DecodedDetails decodedData={decodedData} callHash={callHash} />}
          </CardBody>
        </Card>
      </section>
      <GenerateCallDataModal toggleEdit={toggleEdit} callDataModal={callDataModal} setSender={setSender} setCallData={setCallData} />
    </Fragment>
  );
};

export default AvailWallet;
