import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useSearchParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Tooltip } from "reactstrap";
import { getLiquiditiesApi, getPoolAddresses, syncLiquidities } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { LiquidityTableColumnData } from "../../Data/TableColumnsData";
import useDebounce from "../../Hooks/useDebounce";
import Loader from "../../Layout/Loader";
import CommonPagination from "../Common/CommonPagination";
import CommonTable from "../Common/CommonTable";
import TableTop from "../Common/TableTop";
import AddContractModal from "./AddContractModal";
import LiquidityDetailCard from "./LiquidityDetailCard";
import toast from "react-hot-toast";

const Liquidity = () => {
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [searchTerm, setSearchTerm] = useState("");
  const search = useDebounce(searchTerm?.trim(), 500);
  const [sortOrder, setSortOrder] = useState(false);
  const [sortColumn, setSortColumn] = useState("id");
  const [currentAddress, setCurrentAddress] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentAddressData, setCurrentAddressData] = useState([]);
  const toggleEdit = () => {
    setEditModal((prev) => !prev);
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  const handlePoolAddress = (e) => {
    const poolAddressInput = e.target.value;
    setCurrentAddress(poolAddressInput);
    setPage(1);
    setSearchParams({ pool_address: poolAddressInput });
  };

  const { data: poolAddressData, refetch, isLoading: poolAddressLoading } = useGetApi({ url: `${getPoolAddresses}`, options: { enabled: true, select: (data) => data?.data }, successFunction: false });
  const { isLoading: isLoadingGetData, data } = useGetApi({ url: `${getLiquiditiesApi}`, params, options: { enabled: !!Object.keys(params)?.length }, successFunction: false });
  const { refetch: refreshData } = useGetApi({ url: syncLiquidities, params: { pool_address: currentAddress }, options: { enabled: false, refetchOnMount: false } });

  useEffect(() => {
    if (currentAddress) {
      setParams((prevParams) => ({
        ...prevParams,
        page,
        limit,
        search,
        sortOrder: sortOrder ? "desc" : "asc",
        sortColumn: sortColumn,
        pool_address: currentAddress,
      }));
    }
  }, [page, limit, search, sortColumn, sortOrder, currentAddress]);

  const initialAddress = searchParams.get("pool_address");

  useEffect(() => {
    if (poolAddressData?.length > 0) {
      const isValidAddress = poolAddressData.some((data) => data?.id?.toString() === initialAddress?.toString());

      if (isValidAddress) {
        setCurrentAddress(initialAddress);
        setCurrentAddressData(poolAddressData.find((item) => item?.id == initialAddress));
      } else {
        // Set the first address by default
        const firstAddress = poolAddressData[0];
        setCurrentAddress(firstAddress?.id);
        setCurrentAddressData(firstAddress);
        setSearchParams({ pool_address: firstAddress?.id });
      }
    }
  }, [poolAddressData, currentAddress]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = async () => {
    setIsRefreshing(true); // Disable button
    try {
      await refreshData();
      toast.success("Data refreshed successfully");
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    } finally {
      setIsRefreshing(false); // Enable button
    }
  };

  if (poolAddressLoading || isLoadingGetData) return <Loader />;

  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader>
            <h4>Liquidity Providers</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              limit={limit}
              setLimit={setLimit}
              setPage={setPage}
              dropdowns={[
                <>
                  <span>|</span>
                  <span>Address :</span>

                  <select value={currentAddress} type="select" onChange={handlePoolAddress} className="form-select w-auto">
                    <option value="" disabled>
                      Select Address
                    </option>
                    {poolAddressData?.length > 0 ? (
                      poolAddressData.map((item, i) => (
                        <option key={i} value={item?.id}>
                          {item?.address}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No Address Found
                      </option>
                    )}
                  </select>
                </>,
              ]}
              extraComponents={[
                {
                  jsx: (
                    <Button color="border border-1" onClick={toggleEdit}>
                      <Plus size={15} /> Add Contract
                    </Button>
                  ),
                },
                {
                  jsx: (
                    <>
                      <span id="refresh-tooltip" style={{ cursor: "pointer" }}>
                        <Button disabled={!currentAddress || isRefreshing} color="transparent" className="p-0">
                          <div className="light-box" onClick={handleRefresh}>
                            <i className="fa-solid fa-rotate"/>
                          </div>
                        </Button>
                      </span>
                      <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh-tooltip"} toggle={toggle}>
                        <p>Refresh</p>
                      </Tooltip>
                    </>
                  ),
                },
              ]}
            />
            {currentAddress && <LiquidityDetailCard addressData={currentAddressData} />}
            <div className="custom-scrollbar table-responsive">
              <CommonTable columns={LiquidityTableColumnData(data?.data, page, limit,currentAddress,poolAddressData)} data={data?.data || []} isLoading={isLoadingGetData} handleSort={handleSort} sortOrder={sortOrder} tableClass="transaction-table" />
            </div>
            <AddContractModal toggleEdit={toggleEdit} refetchAddress={refetch} editModal={editModal} />
            {!isLoadingGetData && data?.data?.length > 0 && <CommonPagination totalPages={data?.totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
    </Fragment>
  );
};

export default Liquidity;
