import React, { createContext, useContext, useState } from "react";

const ExchangeBalanceContext = createContext();

export const ExchangeBalanceProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const [editExchangeBalanceData, setEditExchangeBalanceData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  return <ExchangeBalanceContext.Provider value={{ editModal, setEditModal, deleteModal, toggleDelete, setDeleteModal, toggleEdit, setEditExchangeBalanceData, editExchangeBalanceData, searchTerm, setSearchTerm }}>{children}</ExchangeBalanceContext.Provider>;
};

export const useExchangeBalance = () => {
  return useContext(ExchangeBalanceContext);
};
