import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { updateTransactionApi } from "../../Api/ApiList";
import { usePerformMutation } from "../../Api/PerformMutation";
import { useTransaction } from "../../Context/TransactionContext";
import FormInput from "../../CommonComponents/InputFields/FormInput";

const EditModal = ({ refetchTransaction }) => {
  const { editModal, toggleEdit, editTransactionData } = useTransaction();
  const [loading, setLoading] = useState(false); // State to track loading status
  const { mutate } = usePerformMutation(
    () => {
      refetchTransaction();
      reset();
      toggleEdit();
    },
    () => {
      setLoading(false);
    }
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: editTransactionData?.id,
      block_number: editTransactionData?.block_number,
      event_id: editTransactionData?.event_id,
      amount: editTransactionData?.amount,
      from_account: editTransactionData?.from_account,
      to_account: editTransactionData?.to_account,
    },
  });

  const handleTransaction = async (data) => {
    setLoading(true);
    const { from_account, from_label, to_account, to_label, amount } = data;
    const conditionalData = {
      from_address: from_label !== "" && to_label !== "" ? from_account : to_label !== "" ? null : from_account,
      from_label,
      to_address: from_label !== "" && to_label !== "" ? to_account : from_label !== "" ? null : to_account,
      to_label,
      starting_balance: amount,
    };
    await mutate({ url: updateTransactionApi, data: conditionalData });
    setLoading(false);
  };

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
      <ModalHeader toggle={toggleEdit}>Edit Transaction</ModalHeader>
      <Form onSubmit={handleSubmit(handleTransaction)} noValidate>
        <ModalBody>
          <Row className="g-3">
            <Col sm="6">
              <FormInput label={"No:"} readOnly name={"id"} register={register} errors={errors} placeholder={"Enter number"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="6">
              <FormInput label={"Block No :"} readOnly name={"block_number"} register={register} errors={errors} placeholder={"Enter block number"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="6">
              <FormInput label={"Event Id:"} readOnly name={"event_id"} register={register} errors={errors} placeholder={"Enter ID"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="6">
              <FormInput label={"Amount:"} readOnly name={"amount"} register={register} errors={errors} placeholder={"Enter amount"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="12">
              <FormInput label={"From Account:"} readOnly name={"from_account"} register={register} errors={errors} placeholder={"Enter from account"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="12">
              <FormInput label={"To Account:"} readOnly name={"to_account"} register={register} errors={errors} placeholder={"Enter to account"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="6">
              <FormInput label={"From Label:"} name={"from_label"} register={register} errors={errors} placeholder={"Enter from label"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="6">
              <FormInput label={"To Label:"} name={"to_label"} register={register} errors={errors} placeholder={"Enter to label"} classes={{ inputClass: "search-transaction" }} />
            </Col>
            <Col sm="6">
              <FormInput label={"Starting Balance:"} name={"amount"} register={register} errors={errors} placeholder={"Enter balance"} classes={{ inputClass: "search-transaction" }} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="" type="submit">
            Update {loading && <i className="fa-solid fa-circle-notch fa-spin" />}
          </Button>
          <Button color="light" className="border border-1" onClick={toggleEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
