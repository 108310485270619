import React from "react";

const TransactionLoader = () => {
  return (
    <div className="item signing">
      <div className="wrapper">
        <div className="gif-box">
          <div className="short">
            <div className="variant-push">
              <img src="../../assets/images/loader.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionLoader;
