import Account from "../Pages/Account/Account";
import ForgotPassword from "../Pages/Auth/ForgotPassword/ForgotPassword";
import LogIn from "../Pages/Auth/Login/LogIn";
import UpdatePassword from "../Pages/Auth/UpdatePassword/UpdatePassword";
import AvailInfo from "../Pages/AvailInfo";
import AvailWalletContainer from "../Pages/AvailWallet";
import BridgeTransfer from "../Pages/BridgeTransfer";
import Budget from "../Pages/Budget";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ExchangeBalanceHistoryContainer from "../Pages/ExchangeBalanceHistory";
import ExchangeBalanceContainer from "../Pages/ExchangeBalances";
import ExpensesContainer from "../Pages/Expenses";
import Invoices from "../Pages/Invoices";
import LiquidityContainer from "../Pages/Liquidity";
import Multisig from "../Pages/Multisig";
import MultisigTransaction from "../Pages/MultisigTransactions";
import NotificationsContainer from "../Pages/Notifications";
import PaymentHistoryContainer from "../Pages/PaymentHistory";
import PeoplesContainer from "../Pages/Peoples";
import RolesContainer from "../Pages/Roles";
import RolesFormContainer from "../Pages/RolesForm";
import Transaction from "../Pages/Transaction/Transaction";
import UsersContainer from "../Pages/Users";

export const protRoutes = [
  { path: "/transaction", element: <Transaction /> },
  { path: "/account", element: <Account /> },
  { path: "/statistics", element: <Dashboard /> },
  { path: "/notifications", element: <NotificationsContainer /> },
  { path: "/budget/:category", element: <Budget /> },
  { path: "/billing/invoices", element: <Invoices /> },
  { path: "/billing/payment_history", element: <PaymentHistoryContainer /> },
  { path: "/bridge_transfer", element: <BridgeTransfer /> },
  { path: "/peoples", element: <PeoplesContainer /> },
  { path: "/expenses", element: <ExpensesContainer /> },
  { path: "/users/all_user", element: <UsersContainer /> },
  { path: "/users/role", element: <RolesContainer /> },
  { path: "/users/role_form", element: <RolesFormContainer /> },
  { path: "/decoder", element: <AvailWalletContainer /> },
  { path: "/liquidity", element: <LiquidityContainer /> },
  { path: "/avail_info", element: <AvailInfo /> },
  { path: "/multisig/util", element: <Multisig /> },
  { path: "/multisig/multisig_transactions", element: <MultisigTransaction /> },
  { path: "/exchange_balances/accounts", element: <ExchangeBalanceContainer /> },
  { path: "/exchange_balances/history", element: <ExchangeBalanceHistoryContainer /> },
];

export const authRoutes = [
  { path: `/login`, element: <LogIn /> },
  { path: `/forgot_password`, element: <ForgotPassword /> },
  { path: `/update_password/:token`, element: <UpdatePassword /> },
];
