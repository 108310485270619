import React from "react";
import { formatAmount } from "../../../Utils/CustomFunctions";

const VolumeDetailCard = ({ volumeChange, coinMarketData }) => {
  return (
    <div className="price-usd">
      <h6 className="text-center">24h Volume</h6>
      <div className="custom-price">
        <div className={`price badge-light-${volumeChange > 0 ? "success" : volumeChange < 0 ? "danger" : "warning"}`}>
          <h6> $ {formatAmount(Number(coinMarketData?.volume_24h)?.toFixed(2)) || 0}</h6>
          <span>
            <i className={`fa-solid fa-arrow-${volumeChange > 0 ? "trend-up" : volumeChange < 0 ? "trend-down" : ""}`}/>
            {/* <div className="usd"> {coinMarketData?.volume_change_24h?.toFixed(3)} %</div> */}
            <div className="usd"> {Number(volumeChange) ? volumeChange : "0 %"}</div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default VolumeDetailCard;
