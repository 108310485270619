import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a, isNumber } from "@polkadot/util";
import { blake2AsHex, createKeyMulti, encodeAddress } from "@polkadot/util-crypto";
import { signedExtensions } from "avail-js-sdk";
import { BN } from "bn.js";
import toast from "react-hot-toast";
import { checkJsonFormat } from "./CustomFunctions";

export const getInjectorMetadata = (api) => {
  return {
    chain: api.runtimeChain.toString(),
    specVersion: api.runtimeVersion.specVersion.toNumber(),
    tokenDecimals: api.registry.chainDecimals[0] || 18,
    tokenSymbol: api.registry.chainTokens[0] || "AVAIL",
    genesisHash: api.genesisHash.toHex(),
    ss58Format: isNumber(api.registry.chainSS58) ? api.registry.chainSS58 : 0,
    chainType: "substrate",
    icon: "substrate",

    /** !! IMPORTANT !!
     * This is the important part, we tell the extension how to handle our signedExtension (even if it seems it's already there)
     **/
    userExtensions: signedExtensions,
  };
};
export const getOtherSignatories = (members, fromAddress) => {
  const updatedMembers = Array.isArray(members) ? members : checkJsonFormat(members);
  return updatedMembers
    ? updatedMembers
        .filter((member) => member.address !== fromAddress)
        .map((member) => member.address)
        .sort()
    : [];
};

export const generateEncodedCallData = async (api, moduleName, methodName, args, fromAdd) => {
  const fromAddress = fromAdd || args[0]?.Id || args[0]; // Extract address

  // Ensure the amount is a BN instance
  const amount = args[1] instanceof BN ? args[1] : new BN(String(args[1]).replace(/,/g, "")); // Handle string with commas

  const tx = api.tx[moduleName][methodName](fromAddress, amount);
  const encodedCallData = tx.method.toHex(); // Encoded transaction data
  return encodedCallData;
};

export const modifyAvailBalance = (balance) => {
  return Number(balance?.substr(0, balance.length - 18))?.toFixed(2);
};

export const getAvailBalance = async (api, address) => {
  try {
    // Fetch the account balance for the specified address
    const { data: freeBalance } = await api.query.system.account(address);

    // Convert the free balance to a string and parse it as a number
    const availableBalance = freeBalance?.free?.toString();

    // Format the balance by dividing it by 10^8 and ensuring 2 decimal places

    return modifyAvailBalance(availableBalance);
  } catch (error) {
    console.error("Error fetching Avail balance:", error);
    return null;
  }
};

export const signTransaction = async (api, data, senderDetails, setTransactionUpdates, hash, encodedCallData, otherSuccess, otherError) => {
  if (!api) {
    toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
    return;
  }
  try {
    const injector = await web3FromSource("subwallet-js");
    if (!api) await injector.metadata.provide(getInjectorMetadata(api));
    const fromAddress = data?.proxiedName || data?.to_address || data?.toAddress;
    const callHash = hash || (await blake2AsHex(hexToU8a(encodedCallData)));

    const otherSignatories = getOtherSignatories(senderDetails?.members, fromAddress);
    const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, null, callHash, {
      refTime: 196085000,
      proofSize: 3593,
    });

    await transfer.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
      if (status.isInBlock) {
        toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
      } else if (status.isFinalized) {
        toast.success("Transaction finalized.");
      } else {
        toast.success(`Transaction status: ${status.type}`);
      }

      events.forEach(({ phase, event: { data, method, section } }) => {
        setTransactionUpdates(`First approval done for ${data.hash}`);
        console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
      });
    });

    otherSuccess && otherSuccess();
    setTransactionUpdates(`First approval done for ${data.hash}`);
  } catch (err) {
    otherError && otherError();
    setTransactionUpdates(`Error occurred for ${data.hash}`);
    toast.error(`Transaction failed: ${err.message}`);
    console.error(err);
  }
};

export const getPendingMultisigTransactions = async (api, address) => {
  try {
    const entries = await api.query.multisig.multisigs.entries(address);

    if (entries.length === 0) {
      return [];
    }

    return entries.map(([key]) => {
      return key.args[1].toString()
    })
  } catch (error) {
    console.error("Error fetching pending multisig transactions:", error);
    return []; // Return an empty array in case of error
  }
};

export const checkIfProxied = async (api, address) => {
  try {
    if (!api) {
      console.error("API not initialized");
      return false;
    }

    if (!address || typeof address !== "string" || address.length !== 48) {
      console.error("Invalid address format:", address);
      return false;
    }

    const proxies = await api.query.proxy.proxies(address);

    const [proxyList] = proxies.toHuman();
    if (!proxyList || proxyList.length === 0) {
      return false;
    }

    return proxyList[0]; // Assuming delegate is the first item
  } catch (error) {
    console.error("Error fetching proxy data:", error);
    return false;
  }
};

export async function getTransactionDetails(api, callData) {
  // Create API instance

  try {
    // Decode the call data
    const call = api.createType("Call", callData);

    // Get metadata for the call
    const { section, method } = api.registry.findMetaCall(call.callIndex);

    // Process arguments
    const args = call.args.map((arg, index) => ({
      name: call.meta.args[index].name.toString(),
      value: arg.toHuman(), // or .toJSON() for raw values
    }));

    return {
      pallet: section,
      method: method,
      arguments: args,
    };
  } catch (error) {
    console.error("Error decoding transaction:", error);
    throw error;
  }
}

export const generateMultisigAddress = (threshold, addresses) => {
  const SS58Prefix = 42;

  // Address as a byte array.
  const multiAddress = createKeyMulti(addresses, threshold);

  // Convert byte array to SS58 encoding.
  const Ss58Address = encodeAddress(multiAddress, SS58Prefix);

  return Ss58Address;
};

// Get maybe time-point (height and index for next approval )
export const getMaybeTimePoint = async (api, multisigAddress, callHash) => {
  // Initialize the API

  // Query the multisig storage for the multisig address
  const multisigData = await api.query.multisig.multisigs(multisigAddress, callHash);

  // Check if multisig data exists
  if (multisigData.isSome) {
    const { when } = multisigData.unwrap();
    const timePoint = {
      height: when.height.toString(),
      index: when.index.toString(),
    };

    return timePoint;
  } else {
    console.log("No multisig data found (likely the first approval).");
    return null; // Return null for the first approval
  }
};
export const getTransactionFee = async (tx, sender) => {
  try {
    const info = await tx.paymentInfo(sender);
    return info.partialFee.toBn(); // Returns fee in raw Planck units
  } catch (error) {
    console.error("Error fetching transaction fee:", error);
    return new BN(0); // Return zero as a fallback
  }
};

export const getMaxWeightDetails = async (call, address) => {
  try {
    const paymentInfo = await call.paymentInfo(address);
    return {
      refTime: paymentInfo.weight.refTime.toBn(),
      proofSize: paymentInfo.weight.proofSize.toBn(),
    };
  } catch (error) {
    console.error("Error estimating weight:", error);
    throw new Error("Failed to estimate transaction weight.");
  }
};

export const getMultisigProposer = async (api, multisigAccount, callHash) => {
  try {
    // Fetch multisig transaction details
    const multisigInfo = await api.query.multisig.multisigs(multisigAccount, callHash);

    if (multisigInfo.isNone) {
      console.log("No multisig transaction found for the given call hash.");
      return null;
    }

    const { depositor } = multisigInfo.unwrap(); // Extract the initializer (proposer)
    return depositor.toString();
  } catch (error) {
    console.error("❌ Error fetching multisig proposer:", error);
    return null;
  }
};

export const checkAddressType = async (api, address) => {
  // Query multisig status
  const multisigInfo = await api.query.multisig.multisigs.entries(address);
  const isMultisig = multisigInfo.length > 0;

  // Query proxy status
  const proxies = await api.query.proxy.proxies(address);
  const isProxied = proxies[0].length > 0;

  return { isMultisig, isProxied };
};
