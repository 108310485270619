import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Tooltip } from "reactstrap";
import { deleteAccountApi, getAccountApi, refreshAccountApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useAccount } from "../../Context/AccountContext";
import useDebounce from "../../Hooks/useDebounce";
import usePermissionCheck from "../../Hooks/usePermissionCheck";
import Loader from "../../Layout/Loader";
import { toMilliseconds } from "../../Utils/CustomFunctions";
import AdvanceFilter from "../Common/AdvanceFilter";
import CommonPagination from "../Common/CommonPagination";
import { calculateRemainingTime, exportToCSV, handleRefresh } from "../Common/CustomFunctions";
import DeleteModal from "../Common/DeleteModal";
import TableTop from "../Common/TableTop";
import AccountDataTable from "./AccountDataTable";
import EditModal from "./EditModal";

const AccountContainer = () => {
  const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);
  const localKey = "accountLastRefreshTime";
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);
  const { editModal, toggleEdit, searchTerm, setSearchTerm, deleteModal, toggleDelete, editAccountData } = useAccount();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [sortOrder, setSortOrder] = useState(false);
  const [sortColumn, setSortColumn] = useState("id");
  const [editMode, setEditMode] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("Refresh"); // Tooltip content
  const [remainingTime, setRemainingTime] = useState();
  const [params, setParams] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const [create] = usePermissionCheck(["create"], "account");

  const { refetch: refreshData, isLoading } = useGetApi({ url: refreshAccountApi, options: { enabled: false, refetchOnMount: true }, navigate: navigate });
  const [advancedFilter, setAdvancedFilter] = useState({
    startDate: null,
    endDate: null,
    minAmount: null,
    maxAmount: null,
    fromAddress: null,
    toAddress: null,
  });

  const debouncedStartDate = useDebounce(advancedFilter.startDate, 500);
  const debouncedEndDate = useDebounce(advancedFilter.endDate, 500);
  const debouncedMinAmount = useDebounce(advancedFilter.minAmount, 500);
  const debouncedMaxAmount = useDebounce(advancedFilter.maxAmount, 500);
  const debouncedFromAddress = useDebounce(advancedFilter.fromAddress, 500);
  const debouncedToAddress = useDebounce(advancedFilter.toAddress, 500);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const { isLoading: isLoadingGetData, data: accountData, refetch: refetchGetData } = useGetApi({ url: getAccountApi, params, options: { refetchOnWindowFocus: false, enabled: !!Object.keys(params).length }, navigate: navigate });

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  const addAccount = () => {
    setEditMode(false);
    toggleEdit();
  };

  const csvDataExport = () => {
    return exportToCSV(accountData, "account");
  };

  useEffect(() => {
    if (accountData) {
      setTotalPages(accountData.totalPages);
    }
  }, [accountData]);

  useEffect(() => {
    setPage(1);

    if (searchTerm.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (search.trim()?.length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, isLoadingGetData, refetchGetData]);

  useEffect(() => {
    // Check if `from_address` and `to_address` are not empty
    if (debouncedFromAddress?.trim() !== "" && debouncedToAddress?.trim() !== "") {
      setParams({
        ...params,
        startDate: debouncedStartDate,
        endDate: debouncedEndDate,
        minAmount: debouncedMinAmount,
        maxAmount: debouncedMaxAmount,
        from_address: debouncedFromAddress,
        to_address: debouncedToAddress,
      });
    } else {
      // Set params without address fields if they are empty
      setParams({
        ...params,
        startDate: debouncedStartDate,
        endDate: debouncedEndDate,
        minAmount: debouncedMinAmount,
        maxAmount: debouncedMaxAmount,
        from_address: null, // or null, depending on how your API handles these values
        to_address: null, // or null
      });
    }
  }, [debouncedStartDate, debouncedEndDate, debouncedFromAddress, debouncedToAddress, debouncedMinAmount, debouncedMaxAmount]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
      sortOrder: sortOrder ? "asc" : "desc",
      sortColumn: sortColumn,
    }));
  }, [search, page, limit, sortColumn, sortOrder]);

  useEffect(() => {
    const lastRefreshTime = localStorage.getItem(localKey);
    if (lastRefreshTime) {
      const now = new Date().getTime();
      const timeSinceLastRefresh = now - lastRefreshTime;

      if (timeSinceLastRefresh < REFRESH_INTERVAL) {
        setIsRefreshDisabled(true);
        const intervalId = setInterval(() => {
          const remainingTime = calculateRemainingTime(lastRefreshTime);
          if (remainingTime) {
            setTooltipContent(`Refresh available in`);
            setRemainingTime(remainingTime);
          } else {
            setIsRefreshDisabled(false);
            setTooltipContent("Refresh");
            clearInterval(intervalId);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [REFRESH_INTERVAL]);

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader>
            <h4>Account</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              advanceFilter={{ setShowFilter: setShowFilter, showFilter: showFilter }}
              limit={limit}
              searchTerm={searchTerm}
              setLimit={setLimit}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
              exportFile={csvDataExport}
              extraComponents={[
                create && {
                  jsx: (
                    <Button color="border border-1" onClick={addAccount}>
                      <Plus size={15} /> Add Account
                    </Button>
                  ),
                },
                {
                  jsx: (
                    <>
                      <span id="refresh_account_tooltip" style={{ cursor: "pointer" }}>
                        <Button disabled={isRefreshDisabled} color="transparent" className="p-0">
                          <div className="light-box" onClick={() => handleRefresh(localKey, refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL, setTooltipContent, calculateRemainingTime, setRemainingTime)}>
                            <i className="fa-solid fa-rotate" />
                          </div>
                        </Button>
                      </span>
                      <Tooltip placement={"top"} isOpen={tooltipOpen} target={"refresh_account_tooltip"} toggle={toggle}>
                        <p>
                          {tooltipContent} <b>{remainingTime}</b>{" "}
                        </p>
                      </Tooltip>
                    </>
                  ),
                },
              ]}
            />

            {showFilter ? <AdvanceFilter params={params} setParams={setParams} advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} fieldNotToInclude={["fromAddress", "toAddress"]} /> : null}
            <div className="custom-scrollbar table-responsive">
              <AccountDataTable accountData={accountData} handleSort={handleSort} isLoadingGetData={isLoadingGetData} setEditMode={setEditMode} sortOrder={sortOrder} />
            </div>
            {!isLoadingGetData && accountData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
      {deleteModal && <DeleteModal isOpen={deleteModal} deleteEndpoint={deleteAccountApi} itemData={{ id: editAccountData?.id }} itemType="Account" toggleModal={toggleDelete} refetchCallback={refetchGetData} />}
      {editModal && <EditModal refetchTransaction={refetchGetData} isEditMode={editMode} toggleEdit={toggleEdit} />}
    </Fragment>
  );
};

export default AccountContainer;
