import { hexToU8a } from "@polkadot/util";
import { BN } from "bn.js";
import toast from "react-hot-toast";

// Decoded call data for final approval
export const decodeCallData = async (api, callData) => {
  if (!api || !callData) return;
  // setIsDecoding(true);

  try {
    // Clean the input hex string
    const cleanHex = callData.startsWith("0x") ? callData : `0x${callData}`;

    // Convert hex to Uint8Array
    const callDataU8a = hexToU8a(cleanHex);

    // Try to decode as Call directly
    const decodedCall = api.createType("Call", callDataU8a);

    // Get the call index
    const callIndex = decodedCall.callIndex;

    // Find the call in metadata
    const { method, section } = api.registry.findMetaCall(callIndex);

    // Get the arguments
    const args = decodedCall.args.map((arg) => arg.toHuman());

    // Set the decoded information
    return {
      section,
      method,
      args,
      callIndex: callIndex.toString(),
      raw: {
        hex: cleanHex,
        bytes: Array.from(callDataU8a),
      },
    };
  } catch (error) {
    // Try alternative decoding method if first attempt fails
    toast.error(`Some error occurred- ${error}`);
  }
};

export const getTransactionWeight = async (transaction, sender) => {
  if (!transaction) {
    throw new Error("Invalid transaction provided for weight calculation.");
  }

  const transactions = Array.isArray(transaction) ? transaction : [transaction];

  let totalRefTime = new BN(0);
  let totalProofSize = new BN(0);

  for (const tx of transactions) {
    try {
      const info = await tx.paymentInfo(sender);

      if (info && info.weight) {
        // Check if info and info.weight exist
        totalRefTime = totalRefTime.add(info.weight.refTime);
        totalProofSize = totalProofSize.add(info.weight.proofSize);
      } else {
        console.warn("Transaction weight information not available for transaction:", tx);
        // Handle the case where weight information is missing.  Options:
        // 1. Skip this transaction (as in the current code).
        // 2. Throw an error:  throw new Error("Weight info missing");
        // 3. Use a default value:  totalRefTime = totalRefTime.add(new BN(0)); // Or some other default
      }
    } catch (error) {
      console.error("Error getting payment info for transaction:", tx, error);
      // Handle the error appropriately, e.g., skip the transaction or throw an error.
    }
  }

  return {
    refTime: totalRefTime,
    proofSize: totalProofSize,
  };
};

export const getStructuredData = (data) =>
  data.map((item) => {
    const to_address = item?.args?.dest?.Id;
    const amount = Number(String(item?.args?.value).replace(/,/g, "")) / Math.pow(10, 18);
    return {
      to_address: to_address,
      amount: amount,
    };
  });

export const getBlockNumber = async (api, status) => {
  const blockHash = status.asInBlock.toHex();
  const signedBlock = await api.rpc.chain.getBlock(blockHash);
  const blockNumber = await signedBlock.block.header.number.toNumber();
  return blockNumber
};
