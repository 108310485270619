import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import { blake2AsHex, isAddress } from "@polkadot/util-crypto";
import { BN } from "bn.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createMultisigTransactionApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import { truncateMiddle } from "../../../Utils/CustomFunctions";
import { getAvailBalance, getInjectorMetadata, getOtherSignatories } from "../../Common/AvailCommon";
import { checkJsonFormat } from "../../Common/CustomFunctions";
import { getBlockNumber } from "../Utils";

const SendInitialAvailProxied = ({ isOpen, toggle, senderDetails, refetchGetData }) => {
  const [loading, setLoading] = useState(false);
  const { setTransactionUpdates, setTransactionLoading } = useMultisig();
  const [encodedCallData, setEncodedCallData] = useState(""); // New state for encoded call data
  const { api, accounts } = useWallet();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      signatory: "",
      multisigAddress: "",
      from_address: "", // Leave it empty initially
      to_address: "",
      amount: "",
      selected_signatory: "",
    },
  });
  const { mutate } = usePerformMutation(() => refetchGetData());

  const closeModal = () => {
    toggle("initialProxy");
  };

  const handleCancel = () => {
    closeModal();
    setEncodedCallData("");
    reset({
      from_address: senderDetails?.ms_address || "",
      to_address: "",
      amount: "",
    });
  };

  const generateCallDataAndHash = async (data) => {
    if (!api) {
      throw new Error("API is not initialized.");
    }

    try {
      const fromAddress = data?.from_address; // Ensure sender address is valid
      const toAddress = data?.to_address; // Ensure receiver address

      // Convert decimal amount to large unit representation (integer)
      const amountInWei = new BN(
        (parseFloat(data.amount.replace(/,/g, "")) * 1e18).toFixed(0) // Convert to integer
      );

      if (!fromAddress || !toAddress || amountInWei.isZero()) {
        throw new Error("Missing required transaction parameters.");
      }

      // Construct the inner transaction (e.g., balance transfer)
      const innerCall = await api.tx.balances.transferKeepAlive(toAddress, amountInWei);

      // Create the proxied transaction
      const proxiedTransaction = api.tx.proxy.proxy(fromAddress, "Any", innerCall);

      // Encode transaction data
      const encodedCallData = proxiedTransaction.method.toHex();

      // Compute call hash
      const callHash = blake2AsHex(hexToU8a(encodedCallData));

      return { encodedCallData, callHash };
    } catch (error) {
      console.error("Error generating call data:", error);
      throw new Error(`Error generating call data: ${error.message}`);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    // Call generateEncodedCallData with the necessary parameters
    const { encodedCallData } = await generateCallDataAndHash(data);
    // Set the generated encoded call data in the state
    setEncodedCallData(encodedCallData);
    setLoading(false);
  };

  const signTransaction = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      setLoading(true);
      const injector = await web3FromSource("subwallet-js");
      if (!api) await injector.metadata.provide(getInjectorMetadata(api));
      const fromAddress = data?.selected_signatory;
      const callHash = await blake2AsHex(hexToU8a(encodedCallData));
      const otherSignatories = getOtherSignatories(senderDetails?.members, fromAddress);

      const transfer = api.tx.multisig.approveAsMulti(senderDetails?.threshold, otherSignatories, null, callHash, {
        refTime: 405686925,
        proofSize: 8299,
      });

      setTransactionLoading(true);
      await transfer.signAndSend(fromAddress, { signer: injector.signer }, async ({ status, events }) => {
        let extrinsicIndex = null;
        if (status.isInBlock) {
          events.forEach(({ phase }) => {
            if (phase.isApplyExtrinsic) {
              extrinsicIndex = phase.asApplyExtrinsic.toNumber();
            }
          });

          const blockNumber = await getBlockNumber(api, status);
          const mutableData = { call_data: encodedCallData, extrinsic: String(blockNumber) + "-" + String(extrinsicIndex), block: blockNumber, from: senderDetails?.proxied_address, to: data?.to_address, signers: JSON.stringify([fromAddress]), hash: callHash, threshold: senderDetails?.threshold, status: "pending", amount: data?.amount, comment: "Proxied transaction (to single account)" };
          await mutate({ url: createMultisigTransactionApi, data: mutableData });
          setTransactionLoading(false);
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`First approval done for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || "",
        to_address: "",
        amount: "",
      });
      closeModal();
      setLoading(false);
      setEncodedCallData("");
      setTransactionUpdates(`First approval done for ${data.hash}`);
    } catch (err) {
      setLoading(false);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
      setTransactionLoading(false);
    }
  };

  // Update the 'from_address' when selectedAccount changes
  useEffect(() => {
    if (senderDetails) {
      setValue("selected_signatory", accounts[0]?.address); // Set the value programmatically
      setValue("from_address", senderDetails?.proxied_address); // Set the value programmatically
      setValue("multisigAddress", senderDetails?.ms_address + ` [${senderDetails?.ms_name}] `); // Set the value programmatically
    }
  }, [senderDetails, setValue]);

  return (
    <Modal size="xl" centered isOpen={isOpen} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Send Funds Via Proxied"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(encodedCallData ? signTransaction : onSubmit)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="6">
              <FormInput
                label="Send From Account:"
                name={"from_address"}
                errors={errors}
                register={register}
                validationRules={{
                  required: "Address is required",
                }}
                helperText={"The sending account that will be used to send this transaction. Any applicable fees will be paid by this account."}
                readOnly
              />
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Signatory:</label>
                <select {...register("selected_signatory", { required: "Signatory must be selected" })} className={`form-control ${errors.selected_signatory ? "is-invalid" : ""}`}>
                  <option value="" disabled>
                    Select address
                  </option>
                  {accounts
                    ?.filter((member) => checkJsonFormat(senderDetails?.members)?.some((account) => account.address.toLowerCase() === member.address.toLowerCase()))
                    ?.map((member, index) => (
                      <option key={index} value={member?.address}>
                        {`${member?.meta?.name?.toUpperCase()} [${truncateMiddle(member?.address, 16)}]`}
                      </option>
                    ))}
                </select>
                {errors.selected_signatory && <div className="invalid-feedback">{errors.selected_signatory.message}</div>}
              </FormGroup>
            </Col>
            {senderDetails?.ms_address && (
              <Col sm="12">
                <FormInput
                  label="Multisig Account:"
                  name={"multisigAddress"}
                  errors={errors}
                  register={register}
                  validationRules={{
                    required: "Address is required",
                  }}
                  helperText={"The proxy is one of the allowed proxies on the account, as set and filtered by the transaction type."}
                  readOnly
                />
              </Col>
            )}

            <Col sm="6">
              <FormInput
                label="* To Address:"
                name={"to_address"}
                errors={errors}
                register={register}
                validationRules={{
                  required: "Address is required",
                  validate: async (value) => {
                    if (!isAddress(value)) {
                      return `Address is not valid`;
                    }
                    return true;
                  },
                }}
                placeholder="Enter to address"
                helperText={"The beneficiary will have access to the transferred fees when the transaction is included in a block."}
              />
            </Col>
            <Col sm="6">
              <FormInput
                label="* Amount:"
                name={"amount"}
                errors={errors}
                register={register}
                validationRules={{
                  required: "Amount is required",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Amount must be a valid number",
                  },
                  validate: async (value) => {
                    const availableBalance = await getAvailBalance(api, watch("from_address"));
                    if (Number(value.replace(/,/g, "")) > availableBalance) {
                      return `Amount should not exceed (${availableBalance} AVAIL balance is transferrable)`;
                    }
                    return true;
                  },
                }}
                placeholder="Enter amount"
                helperText={"Amount is the total number of AVAIL "}
              />
            </Col>
            {/* Display the generated encoded call data */}
            {encodedCallData && (
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Encoded Call Data:</label>
                  <textarea className="form-control" value={encodedCallData} rows="3" readOnly />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? (encodedCallData ? "Signing..." : "Submitting...") : encodedCallData ? "Sign And Send" : "Submit"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SendInitialAvailProxied;
