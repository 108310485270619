// import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// import { useAuth } from "../Context/AuthContext";
// import ErrorPage from "../Layout/403";
// import Layout from "../Layout/Layout";
// import ProtectedRoute from "./ProtectedRoute";
// import RedirectRoute from "./RedirectRoute";
// import { authRoutes, protRoutes } from "./Routes";
// import { useEffect, useState } from "react";
// import { MenuList } from "../Data/Layout/SidebarData";

// const Routers = () => {
//   const { isAuthenticated, accountData } = useAuth();
//   const [filteredRoute, setFilteredRoute] = useState(null);
//   console.log("🚀 ~ Routers ~ filteredRoute:", filteredRoute);
//   const [loading, setLoading] = useState(false);

//   const findRedirectRoute = (menuList, userPermissions) => {
//     const allowedPermissions = JSON.parse(userPermissions || "[]");
//     if (allowedPermissions.includes("transaction.index")) return "/transaction";

//     const findFirstAvailableRoute = (menus) => {
//       for (let menu of menus) {
//         if (menu.type === "link" && menu.permission?.some((perm) => allowedPermissions.includes(perm))) {
//           return menu.path;
//         }
//         if (menu.type === "sub" && menu.children) {
//           const childRoute = findFirstAvailableRoute(menu.children);
//           if (childRoute) return childRoute;
//         }
//       }
//       return null;
//     };

//     for (let section of menuList) {
//       const route = findFirstAvailableRoute(section.mainMenus);
//       if (route) return route;
//     }

//     return "/transaction";
//   };

//   useEffect(() => {
//     if (accountData?.role?.permissions) {
//       const test = findRedirectRoute(MenuList, accountData.role.permissions);
//       setFilteredRoute(test);
//     }
//     setLoading(true);
//   }, [accountData, isAuthenticated]);

//   return (
//     // <BrowserRouter>
//     <Routes>
//       {authRoutes.map(({ path, element }, i) => (
//         <Route key={i} path={path} element={<RedirectRoute filteredRoute={filteredRoute}>{element}</RedirectRoute>} />
//       ))}
//       {protRoutes.map(({ path, element }, i) => (
//         <Route key={i} element={<Layout />}>
//           <Route path={path} element={<ProtectedRoute>{element}</ProtectedRoute>} />
//         </Route>
//       ))}
//       <Route element={<Outlet />}>
//         <Route path="/forbidden" element={<ErrorPage />} />
//       </Route>

//       {loading && filteredRoute && <Route path="/" element={<Navigate to={isAuthenticated ? filteredRoute : "/login"} />} />}
//       <Route path="*" element={<Navigate to={"/login"} />} />
//     </Routes>
//     // </BrowserRouter>
//   );
// };

// export default Routers;
import { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { checkJsonFormat } from "../Components/Common/CustomFunctions";
import { useAuth } from "../Context/AuthContext";
import { MenuList } from "../Data/Layout/SidebarData";
import ErrorPage from "../Layout/403";
import Layout from "../Layout/Layout";
import Loader from "../Layout/Loader";
import ProtectedRoute from "./ProtectedRoute";
import RedirectRoute from "./RedirectRoute";
import { authRoutes, protRoutes } from "./Routes";

const Routers = () => {
  const { isAuthenticated, accountData, setAccountData, setRole, role } = useAuth();
  const [filteredRoute, setFilteredRoute] = useState(null);
  const [loading, setLoading] = useState(true);

  const findRedirectRoute = (menuList, userPermissions) => {
    if (!userPermissions || !Array.isArray(userPermissions)) return null;

    // Check if user has direct access to `/transaction`
    if (userPermissions.includes("transaction.index")) return "/transaction";

    // Find first permitted route recursively
    const findFirstAvailableRoute = (menus) => {
      for (let menu of menus) {
        if (menu.type === "link" && menu.permission?.some((perm) => userPermissions.includes(perm))) {
          return menu.path;
        }
        if (menu.type === "sub" && menu.children) {
          const childRoute = findFirstAvailableRoute(menu.children);
          if (childRoute) return childRoute;
        }
      }
      return null;
    };

    return menuList.reduce((route, section) => route || findFirstAvailableRoute(section.mainMenus), null) || "/transaction";
  };
  useEffect(() => {
    const storedData = checkJsonFormat(localStorage.getItem("role"));
    if (!accountData || !role) {
      setAccountData(storedData);
      setRole(storedData?.name);
    }
  }, []);

  useEffect(() => {
    setFilteredRoute(null);
    if (accountData?.permissions) {
      const validPermissions = checkJsonFormat(accountData.permissions);
      if (validPermissions) {
        const newRoute = findRedirectRoute(MenuList, validPermissions);
        localStorage.setItem("default_route", newRoute);
        // navigate(newRoute);
        setFilteredRoute(newRoute);
      }
    }
    if (!isAuthenticated) {
      localStorage.clear();
    }
    setLoading(false);
  }, [accountData,  isAuthenticated]);

  // if (loading && !filteredRoute) return <Loader />; // Prevents rendering before data is available

  return (
    <Routes>
      {authRoutes.map(({ path, element }, i) => (
        <Route key={i} path={path} element={<RedirectRoute filteredRoute={filteredRoute}>{element}</RedirectRoute>} />
      ))}

      {protRoutes.map(({ path, element }, i) => (
        <Route key={i} element={<Layout />}>
          <Route path={path} element={<ProtectedRoute>{element}</ProtectedRoute>} />
        </Route>
      ))}

      {/* Ensure proper redirection after authentication */}
      {!loading && filteredRoute && <Route path="/" element={<Navigate to={isAuthenticated && filteredRoute ? filteredRoute : "/login"} replace />} />}
      <Route path="*" element={<Navigate to="/login" />} />

      {!loading && !filteredRoute && (
        <Route element={<Outlet />}>
          <Route path="/forbidden" element={<ErrorPage />} />
        </Route>
      )}
    </Routes>
  );
};

export default Routers;
