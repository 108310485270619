import ApexCharts from "apexcharts";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Card, CardBody, CardHeader, Col } from "reactstrap";
import { formatNumber, getUniqueDates } from "../../Utils/CustomFunctions";

const BalanceStatisticChart = ({ data }) => {
  const [showAll, setShowAll] = useState(false); // Toggle state for "All" label
  const dateSeries = getUniqueDates(data?.data, "date");

  function transformData(data, showAll) {
    const result = {};
    const totalByDate = {};

    data?.forEach((item) => {
      const label = item.exchange_account?.label;
      const date = item.date;
      const amount = parseFloat(item.amountTotalRounded) || 0;

      if (!result[label]) {
        result[label] = { name: label, data: [] };
      }

      if (!totalByDate[date]) {
        totalByDate[date] = 0;
      }

      result[label].data.push(amount);
      totalByDate[date] += amount;
    });

    if (showAll) {
      return [
        {
          name: "All",
          data: Object.values(totalByDate),
        },
      ];
    }

    return Object.values(result);
  }

  const balanceHistoryChartData = {
    series: transformData(data?.data, showAll),
    options: {
      chart: {
        id: "balance-history-chart",
        height: 350,
        type: "line",
        zoom: { enabled: false },
        stacked: false,
        toolbar: { show: false },
      },
      colors: ["#77B6EA", "#545454", "#878f97", "#3ca3fc", "#ffb829", "#fc564a", "#3d8f5c", "#ed80c4"],
      dataLabels: { enabled: false },
      stroke: { curve: "straight", width: 1 },
      // title: {
      //   text: showAll ? "Total Balance History" : "Balance History",
      //   align: "left",
      //   style: { fontSize: "18px", color: "var(--table-font-color)" },
      // },
      xaxis: { categories: dateSeries },
      yaxis: {
        labels: {
          formatter: (val) => formatNumber(val), // Ensures 2 decimal places
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
      markers: {
        size: 5,
        color: "#8884d9",
        strokeColors: "#8884d9",
        strokeWidth: 2,
        fillOpacity: 1,
        shape: "circle",
        hover: {
          sizeOffset: 1.5,
        },
      },
    },
  };

  return (
    <Col lg="12">
      <Card className="common-chart balance-history">
        <CardHeader>
          <h3>{showAll ? "Total Balance History" : "Balance History"}</h3>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              ApexCharts.exec("balance-history-chart", "resetSeries"); // Reset hidden series
              setShowAll((prev) => !prev);
            }}
          >
            {showAll ? "Show Individual Exchanges" : "Show All Exchanges"}
          </Button>
        </CardHeader>
        <CardBody>
          <ReactApexChart options={balanceHistoryChartData.options} series={transformData(data?.data, showAll)} type="line" height={350} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default BalanceStatisticChart;
