import React, { useEffect, useState } from "react";
import { dynamicSvg, handleCopyCode } from "../../Utils/CustomFunctions";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";

const DecodedDetails = ({ decodedData, callHash }) => {
  const [transactionsTableData, setTransactionsTableData] = useState([]);
  useEffect(() => {
    const fetchDecodedTransaction = async () => {
      if (decodedData) {
        try {
          let transactions = [];
          if (decodedData?.section == "utility" && decodedData?.method == "batch") {
            transactions = decodedData?.args[0]?.map((tx) => ({
              id: tx.args.dest.Id,
              value: tx.args.value,
              method: tx.method,
              section: tx.section,
            }));
          } else if (decodedData?.section == "proxy" && decodedData?.method == "proxy") {
            transactions = decodedData?.args[2].args?.calls?.length
              ? decodedData?.args[2].args?.calls?.map((tx) => ({
                  id: tx.args.dest.Id,
                  value: tx.args.value,
                  method: tx.method,
                  section: tx.section,
                }))
              : [
                  {
                    id: decodedData?.args?.[2]?.args?.dest?.Id,
                    value: decodedData?.args?.[2]?.args?.value,
                    method: decodedData?.args[2]?.method,
                    section: decodedData?.args[2]?.section,
                  },
                ];
          } else {
            transactions = [
              {
                id: decodedData?.args?.[0]?.Id,
                value: decodedData?.args?.[1],
                method: decodedData?.method,
                section: decodedData?.section,
              },
            ];
          }
          setTransactionsTableData(transactions);
        } catch (error) {
          console.error("Failed to decode call data:", error);
        }
      }
    };

    fetchDecodedTransaction();
  }, [decodedData]);
  return (
    <>
      <Card className="data-wallet-card mt-4">
        <CardHeader className="border-0 p-0">
          <Row>
            <Col xs="6">
              <div className="custom-data-label">
                <label>decoded call</label>
                <span>{decodedData?.section}</span>
              </div>
            </Col>
            <Col xs="6">
              <div>
                <span>{decodedData?.method}</span>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {transactionsTableData?.map((item, i) => (
            <>
              <div className="custom-wallet-body">
                <div onClick={() => handleCopyCode(item.id)} className="custom-ring">
                  <img className="img-fluid" src={dynamicSvg("ring-wallet.svg")} alt="icon" />
                </div>
                <span className="custom-data-label">dest: {item?.id}</span>
              </div>
              <div className="custom-wallet-body mt-3">
                <span className="custom-data-label">value: Compact'</span>
                <div className="d-flex justify-content-between align-items-center">
                  <span>{item?.value}</span>
                  <span>{item?.section}</span>
                  <span>{item?.method}</span>
                </div>
              </div>
            </>
          ))}
        </CardBody>
        <CardFooter className="custom-wallet-footer border-0">
          <div className="custom-data-call">
            <span className="custom-data-title d-block">Encoded call hash</span>
            <p>{callHash}</p>
          </div>
          <div onClick={() => handleCopyCode(callHash, "Hash")} style={{ cursor: "pointer" }}>
            <i className="fa-regular fa-copy" />
          </div>
        </CardFooter>
      </Card>
      <div className="custom-roll-card">
        <div className="custom-card__header">Encoding Details :</div>
        <div className="custom-card__body">
          <p>
            <span>Call Index:</span> {decodedData?.callIndex}
          </p>
          {/* <p>
            <span>Dest:</span> {decodedData?.args[0]?.Id}
          </p>
          <p>
            <span>Value:</span> 13000064a73b6e00d
          </p> */}
          <div className="custom-data-link mt-2">
            <Link target="_blank" to={`https://explorer.avail.so/?rpc=${process.env.REACT_APP_AVAIL_RPC_ENDPOINT}#/extrinsics/decode/${decodedData?.raw?.hex}`}>
              View Link
            </Link>
            <i className="fa-solid fa-up-right-from-square ms-1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DecodedDetails;
