import React from "react";
import { useBudget } from "../../../Context/BudgetContext";
import usePermissionCheck from "../../../Hooks/usePermissionCheck";
import { handleCapitalizeMultiple } from "../../../Utils/CustomFunctions";
import CommonTable from "../../Common/CommonTable";

const AvailWalletPayments = ({ data, isLoadingGetData, setEditMode, handleDelete, page, limit }) => {
  const { handleSort, sortOrder } = useBudget();
  const [edit, destroy] = usePermissionCheck(["edit", "destroy"], "budget");
  const columns = [
    { label: "Vendor", key: "vendor" },
    { label: "POC", key: "poc" },
    { label: "Expense Category", key: "expense_category" },
    { label: "Type of Expense", key: "expense_type" },
    { label: "Currency", key: "currency" },
    { label: "TWAP Price", key: "twap_price", type: "amount", isSortable: true },
    { label: "Amount in USD", key: "amountUSD", type: "amount", isSortable: true },
    { label: "Amount in Avail", key: "amountAvail", isSortable: true },
    { label: "Wallet Address", key: "wallet_address", type: "address", truncate: true },
    { label: "Confirmation (TXN)", key: "confirmation", type: "address", truncate: true },
    { label: "Status", key: "status", isSortable: true, render: (item) => <span className={`badge ${item?.status === "Live" || item?.status === "Completed" || item?.status === "Paid" ? "badge-light-success" : item?.status === "Expired" ? "badge-light-danger" : "badge-light-warning"}`}> {handleCapitalizeMultiple(item?.status)} </span> },
  ];

  return <CommonTable serialNo currentPage={page} perPage={limit} handleSort={handleSort} sortOrder={sortOrder} tableClass={"budget-table"} columns={columns} data={data?.data || []} isLoading={isLoadingGetData} onDelete={destroy ? handleDelete : false} onEdit={edit ? setEditMode : false} />;
};

export default AvailWalletPayments;
