import { useEffect, useState } from "react";
import SidebarMenuList from "./SidebarMenuList";
import { useGetApi } from "../../Api/useGetApi";
import { useAuth } from "../../Context/AuthContext";

const Sidebar = ({ setIsSidebarOpen, isSidebarOpen }) => {
  const [activeMenu, setActiveMenu] = useState([]);
  const { isAuthenticated, setAccountData, setRole } = useAuth();

  const token = localStorage.getItem("finance_token");

  // const { data, refetch, isLoading, fetchStatus } = useGetApi({ url: "/api/user/self", options: { enabled: false, refetchOnWidowFocus: false } });

  // useEffect(() => {
  //   isAuthenticated && fetchStatus == "idle" && refetch();
  // }, []);

  // useEffect(() => {
  //   if (data && !isLoading) {
  //     localStorage.setItem("role", JSON.stringify(data?.role));
  //     setRole(data?.role?.name);
  //   }
  //   setAccountData(data);
  // }, [token, data, isLoading]);

  return (
    <div className={`sidebar-wrapper ${isSidebarOpen ? "close_icon" : ""} custom-scroll`}>
      <div>
        <nav className="sidebar-main">
          <div id="sidebar-menu">
            <ul className="sidebar-links" id="simple-bar">
              <li className="back-btn w-100">
                <img src="/assets/images/avail-logo.svg" className="logo" height={55} width={190} alt="" />
                <div className="mobile-back text-end " onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                  <span className="text-secondary">
                    <i className="fa-solid fa-bars" />
                  </span>
                </div>
              </li>
              <li className="icon-sidebar">
                <img src="/assets/images/small-logo.svg" className="logo" height={40} width={40} alt="" />
              </li>
              <SidebarMenuList activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
