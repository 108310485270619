import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createOrUpdateBudgetApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import useNotifications from "../../../Api/useNotifications";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useBudget } from "../../../Context/BudgetContext";
import useOutsideClick from "../../../Hooks/useOutsideClick";

const AvailWalletModal = ({ isEditMode }) => {
  const { refetch, editModal, editBudgetData, toggleEdit } = useBudget();

  const { mutate } = usePerformMutation(
    () => {
      refetch();
      reset();
      toggleEdit();
      setTimeout(() => {
        refetchGetData();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );
  const [loading, setLoading] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const datePickerRef = useRef(null);
  const { refetchGetData } = useNotifications();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "avail_wallet",
      id: null,
      vendor: null,
      invoice_date: null,
      invoice_no: null,
      poc: null,
      expense_category: null,
      expense_type: null,
      currency: null,
      amount: 0,
      wallet_address: null,
      confirmation: null,
      status: null,
      amountAvail: null,
      amountUSD: null,
      twap_price: null,
    },
  });

  const handleBudget = async (data) => {
    setLoading(true);
    await mutate({ url: createOrUpdateBudgetApi, data: data });
    setLoading(false);
  };

  const handleDateChange = (date) => {
    setValue("invoice_date", date);
    setDatePickerVisible(false);
  };

  const toggleDatePicker = () => {
    setDatePickerVisible((prev) => !prev);
  };

  useOutsideClick(datePickerRef, () => setDatePickerVisible(false));

  useEffect(() => {
    if (isEditMode && editBudgetData) {
      setValue("id", editBudgetData.id);
      setValue("vendor", editBudgetData.vendor);
      setValue("invoice_date", editBudgetData.invoice_date ? new Date(editBudgetData.invoice_date) : null);
      setValue("invoice_no", editBudgetData.invoice_no);
      setValue("poc", editBudgetData.poc);
      setValue("expense_category", editBudgetData.expense_category);
      setValue("expense_type", editBudgetData.expense_type);
      setValue("currency", editBudgetData.currency);
      setValue("amount", editBudgetData.amount);
      setValue("wallet_address", editBudgetData.wallet_address);
      setValue("confirmation", editBudgetData.confirmation);
      setValue("twap_price", editBudgetData.twap_price);
      setValue("amountUSD", editBudgetData.amountUSD);
      setValue("amountAvail", editBudgetData.amountAvail);
      setValue("status", editBudgetData.status);
    } else {
      reset();
    }
  }, [isEditMode, editBudgetData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal budget-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Avail Wallet Payment" : "Create Avail Wallet Payment"} </ModalHeader>
      <Form onSubmit={handleSubmit(handleBudget)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            {/* Vendor */}
            <Col sm="6">
              <FormInput label={"Vendor:"} name={"vendor"} errors={errors} register={register} placeholder={"Enter vendor"} validationRules={{ required: "Vendor is required" }} />
            </Col>

            {/* Invoice Date */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Invoice Date:</label>
                <div className="calander-icon" ref={datePickerRef}>
                  <DatePicker showYearDropdown selected={watch("invoice_date")} className={`form-control ${errors.invoice_date ? "is-invalid" : ""}`} placeholderText="Select invoice date" onChange={handleDateChange} open={datePickerVisible} />
                  <i className="fa-solid fa-calendar-days" onClick={toggleDatePicker} />
                </div>
                {errors.invoice_date && <div className="invalid-feedback">{errors.invoice_date.message}</div>}
              </FormGroup>
            </Col>

            {/* Invoice No */}
            <Col sm="6">
              <FormInput label={"Invoice No:"} name={"invoice_no"} errors={errors} register={register} placeholder="Enter invoice number" />
            </Col>

            {/* POC */}
            <Col sm="6">
              <FormInput label={"POC:"} name={"poc"} errors={errors} register={register} placeholder="Enter POC" />
            </Col>

            {/* Expense Category */}
            <Col sm="6">
              <FormInput label={"Expense Category:"} name={"expense_category"} errors={errors} register={register} placeholder="Enter expense category" />
            </Col>

            <Col sm="6">
              <FormInput label={"TWAP Price:"} name={"twap_price"} type="number" errors={errors} register={register} placeholder="Enter TWAP price" />
            </Col>

            <Col sm="6">
              <FormInput label={"Amount (in USD):"} name={"amountUSD"} type="number" errors={errors} register={register} placeholder="Enter amount in USD" validationRules={{ required: "Amount USD is required" }} />
            </Col>

            <Col sm="6">
              <FormInput label={"Amount (in avail):"} name={"amountAvail"} type="number" errors={errors} register={register} placeholder="Enter amount in AVAIL" validationRules={{ required: "Amount AVAIL is required" }} />
            </Col>

            {/* Currency */}
            <Col sm="6">
              <FormInput label={"Currency:"} name={"currency"} validationRules={{ required: "Currency is required" }} errors={errors} register={register} placeholder="Enter currency" />
            </Col>

            {/* Amount */}
            <Col sm="6">
              <FormInput label={"Amount:"} name={"amount"} errors={errors} register={register} placeholder="Enter amount" type="number" />
            </Col>

            {/* Wallet Address */}
            <Col sm="6">
              <FormInput label={"Wallet Address:"} name={"wallet_address"} errors={errors} register={register} placeholder="Enter wallet address" />
            </Col>

            {/* Confirmation */}
            <Col sm="6">
              <FormInput label={"Confirmation:"} name={"confirmation"} errors={errors} register={register} placeholder="Enter confirmation" />
            </Col>

            {/* Type of Expense */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Type of Expense:</label>
                <select {...register("expense_type")} className={`form-control search-users ${errors.expense_type ? "is-invalid" : ""}`}>
                  <option value="">Select an option</option>
                  <option value="Recurring">Recurring</option>
                  <option value="One-time payment">One-time payment</option>
                </select>
              </FormGroup>
            </Col>

            {/* Status */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Status:</label>
                <select {...register("status", { required: "Status is required" })} className={`form-control search-users ${errors.status ? "is-invalid" : ""}`}>
                  <option value="">Select status</option>
                  <option value="Paid">Paid</option>
                  <option value="Pending">Pending</option>
                </select>
                {errors.status && <div className="invalid-feedback">{errors.status.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button type="submit" color="" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AvailWalletModal;
