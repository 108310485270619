export const treasuryBalanceChart = {
  series: [
    {
      name: "Desktops",
      data: [530000, 530000, 530000, 530000, 530000, 530000, 530000],
    },
  ],
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    width: 1,
  },
  grid: {
    show: true,
    borderColor: "var(--chart-border-color)",
    strokeDashArray: 3,
    position: "back",
  },
  colors: ["#8884d9"],
  fill: {
    type: "solid",
    gradient: {
      shade: "dark",
      type: "vertical",
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    labels: {
      style: {
        fontFamily: "Rubik, sans-serif",
        colors: "var(--chart-text-color)",
      },
    },
    axisBorder: {
      show: true,
      color: "var(--chart-text-color)",
    },
    axisTicks: {
      show: true,
      color: "var(--chart-text-color)",
    },
  },
  yaxis: {
    axisBorder: {
      show: true,
      color: "var(--chart-text-color)",
    },
    axisTicks: {
      show: true,
      color: "var(--chart-text-color)",
    },
    min: 0,
    max: 600000,
    labels: {
      style: {
        colors: "var(--chart-text-color)",
      },
    },
  },
  markers: {
    size: 5,
    colors: ["#fff"],
    strokeColors: "#8884d9",
    strokeWidth: 2,
    fillOpacity: 1,
    shape: "circle",
    hover: {
      sizeOffset: 1.5,
    },
  },
};

export const bankBalanceChart = {
  series: [
    {
      name: "Desktops",
      data: [530000, 530000, 530000, 530000, 530000, 530000, 530000],
    },
  ],
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    width: 1,
  },
  grid: {
    show: true,
    borderColor: "rgba(82, 82, 108, 0.2)",
    strokeDashArray: 3,
    position: "back",
  },
  colors: ["#82ca9e"],
  fill: {
    type: "solid",
    gradient: {
      shade: "dark",
      type: "vertical",
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    labels: {
      style: {
        fontFamily: "Rubik, sans-serif",
        colors: "var(--chart-text-color)",
        tickPlacement: "between",
      },
    },
    axisBorder: {
      show: true,
      color: "var(--chart-text-color)",
    },
    axisTicks: {
      show: true,
      color: "var(--chart-text-color)",
    },
  },
  yaxis: {
    axisBorder: {
      show: true,
      color: "var(--chart-text-color)",
    },
    axisTicks: {
      show: true,
      color: "var(--chart-text-color)",
    },
    title: {
      text: "Points",
    },
    min: 0,
    max: 600000,
    title: {
      text: undefined,
    },
    labels: {
      style: {
        colors: "var(--chart-text-color)",
      },
    },
  },
  markers: {
    size: 5,
    colors: ["#fff"],
    strokeColors: "#82ca9e",
    strokeWidth: 2,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    shape: "circle",
    offsetX: 0,
    offsetY: 0,
    onClick: undefined,
    onDblClick: undefined,
    showNullDataPoints: true,
    hover: {
      size: undefined,
      sizeOffset: 1.5,
    },
  },
};

//column chart

export const topBankTransferChart = {
  series: [
    {
      name: "Refunds",
      type: "column",
      data: [550000, 450000, 350000, 250000, 150000],
    },
  ],
  chart: {
    height: 295,
    type: "line",
    stacked: false,
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      enabledOnSeries: [2],
      top: 10,
      left: 0,
      blur: 4,
      color: "#7366FF",
      opacity: 0.2,
    },
    offsetX: 0,
  },
  stroke: {
    width: [0],
    curve: "smooth",
    dashArray: [0],
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  colors: ["#82ca9e"],
  fill: {
    type: "solid",
    gradient: {
      shade: "dark",
      type: "vertical",
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  grid: {
    show: true,
    borderColor: "rgba(82, 82, 108, 0.2)",
    strokeDashArray: 3,
    position: "back",
  },
  legend: {
    show: false,
  },
  markers: {
    size: 0,
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May"],
    labels: {
      style: {
        fontFamily: "Rubik, sans-serif",
        colors: "var(--chart-text-color)",
      },
    },
    axisBorder: {
      show: true,
      color: "var(--chart-text-color)",
    },
    axisTicks: {
      show: true,
      color: "var(--chart-text-color)",
    },
  },
  yaxis: {
    axisBorder: {
      show: true,
      color: "var(--chart-text-color)",
    },
    axisTicks: {
      show: true,
      color: "var(--chart-text-color)",
    },
    title: {
      text: "Points",
    },
    min: 0,
    max: 600000,
    title: {
      text: undefined,
    },
    labels: {
      style: {
        colors: "var(--chart-text-color)",
      },
    },
  },
};

export const topTreasuryChart = (categories, spendData) => ({
  series: [
    {
      name: "Amount",
      type: "column", // Ensure type matches
      data: spendData || [],
    },
  ],
  chart: {
    height: 400,
    type: "bar", // Align with ReactApexChart type
    stacked: false,
    toolbar: { show: false },
  },
  stroke: {
    width: [1],
    curve: "smooth",
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  colors: ["#8884d9"],
  fill: {
    type: "solid",
    gradient: {
      shade: "dark",
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  grid: {
    show: true,
    borderColor: "var(--chart-border-color)",
  },
  xaxis: {
    categories: categories || [],
    labels: { style: { colors: "var(--chart-text-color)" } },
  },
  yaxis: {
    min: 0,
    title: { text: "Amount" },
    labels: { style: { colors: "var(--chart-text-color)" } },
  },
});
