import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import { getMultisigTransactionApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useBridgerTransfer } from "../../Context/BridgeTransferContext";
import { MultisigTransactionsTableColumnData } from "../../Data/TableColumnsData";
import useDebounce from "../../Hooks/useDebounce";
import Loader from "../../Layout/Loader";
import CommonPagination from "../Common/CommonPagination";
import CommonTable from "../Common/CommonTable";
import TableTop from "../Common/TableTop";

const MultisigTransactions = () => {
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const navigate = useNavigate();
  const { searchTerm, setSearchTerm } = useBridgerTransfer();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const { isLoading: isLoadingGetData, data: multisigTransactionsData, refetch: refetchGetData } = useGetApi({ url: getMultisigTransactionApi, params, options: { enabled: !!Object.keys(params)?.length,refetchOnWindowFocus:false }, successFunction: false, navigate: navigate });
  useEffect(() => {
    if (searchTerm?.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  // total pages of data
  useEffect(() => {
    if (multisigTransactionsData) {
      setTotalPages(multisigTransactionsData.totalPages);
    }
  }, [multisigTransactionsData]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
    }));
  }, [search, page, limit]);

  useEffect(() => {
    if (search?.trim().length > 0) {
      isLoadingGetData && refetchGetData();
    }
  }, [search, page, limit, isLoadingGetData, refetchGetData]);

  if (isLoadingGetData) return <Loader />;
  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader>
            <h4>Multisig Transactions</h4>
          </CardHeader>
          <CardBody>
            <TableTop limit={limit} searchTerm={searchTerm} setLimit={setLimit} setPage={setPage} setSearchTerm={setSearchTerm} />
            <div className="custom-scrollbar table-responsive">
              <CommonTable serialNo currentPage={page} perPage={limit} columns={MultisigTransactionsTableColumnData} data={multisigTransactionsData?.data || []}  isLoading={isLoadingGetData} tableClass="transaction-table transfer-table people-table" />
            </div>
            {!isLoadingGetData && multisigTransactionsData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
    </Fragment>
  ); 
};

export default MultisigTransactions;
