// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useAuth } from "../Context/AuthContext";

// const RedirectRoute = ({ children, filteredRoute }) => {
//   const { isAuthenticated } = useAuth();

//   return isAuthenticated ? <Navigate to={filteredRoute} /> : children;
// };

// export default RedirectRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

const RedirectRoute = ({ children, filteredRoute }) => {
  const { isAuthenticated } = useAuth();

  if (!filteredRoute) return children; // Prevent redirecting when filteredRoute is not set

  return isAuthenticated ? <Navigate to={filteredRoute}  /> : children;
};

export default RedirectRoute;
