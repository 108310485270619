import React, { useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getMultisigApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useMultisig } from "../../Context/MultisigContext";
import { useWallet } from "../../Context/WalletContext";
import useDebounce from "../../Hooks/useDebounce";
import usePermissionCheck from "../../Hooks/usePermissionCheck";
import { handleCopyCode, truncateMiddle } from "../../Utils/CustomFunctions";
import CommonTooltip from "../Common/CommonTooltip";
import { checkJsonFormat } from "../Common/CustomFunctions";

const MultisigAddressCard = ({ filteredMultisigData, transactionData, pendingTransactionCounts, handleSend, handlePendingTransaction, balances, toggleDropdown, openDropdownId, setRefetchMultisig }) => {
  const { toggleEdit, toggleDelete, setEditMultisigData, setMultisigPage, searchMultisig, multisigData, multisigPage, setMultisigData, setTotalMultisigPages, multisigPagelimit, setEditMode } = useMultisig();
  const { api } = useWallet();
  const [params, setParams] = useState({});
  const { data: fetchedMultisigData, refetch: refetchMultisig, isLoading } = useGetApi({ url: getMultisigApi, params, options: { enabled: !!Object.keys(params)?.length, refetchOnWindowFocus: false } });
  const debouncedSearchMultisig = useDebounce(searchMultisig?.trim(), 500);
  const [edit, destroy] = usePermissionCheck(["edit", "destroy"], "multisig_admin");
  const isWalletConnected = localStorage.getItem("isConnectedToWallet");

  const handleEdit = (id) => {
    setEditMode(true);
    const singleEditData = multisigData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditMultisigData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = multisigData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditMultisigData(singleEditData);
    }
  };

  // Example usage
  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page: multisigPage,
      limit: multisigPagelimit,
      search: debouncedSearchMultisig,
    }));
  }, [debouncedSearchMultisig, multisigPage, multisigPagelimit]);

  useEffect(() => {
    if (searchMultisig?.trim() === "") {
      setMultisigPage(1);
    }
  }, [searchMultisig]);

  useEffect(() => {
    if (debouncedSearchMultisig?.trim().length > 0) {
      isLoading && refetchMultisig();
    }
  }, [debouncedSearchMultisig, multisigPage, multisigPagelimit, isLoading, refetchMultisig]);

  useEffect(() => {
    setMultisigData(fetchedMultisigData);
  }, [fetchedMultisigData]);

  useEffect(() => {
    if (refetchMultisig) {
      setRefetchMultisig(() => refetchMultisig);
    }
  }, [refetchMultisig]);

  useEffect(() => {
    // Check if `multisigData` and `api` are ready
    if (multisigData?.data?.length && api) {
      setTotalMultisigPages(multisigData.totalPages);
    }
  }, [multisigData, api]);

  return (
    <Card className="mb-3">
      <CardHeader>
        <h6 className=" custom-chain-heading badge-light-success ">
          Multisig<span>on-chain multisig accounts</span>
        </h6>
      </CardHeader>
      <CardBody>
        <Row>
          {filteredMultisigData?.length ? (
            filteredMultisigData?.map((item, i) => (
              <Col xl="6" key={i}>
                <Card className="custom-multisig badge-light-success">
                  <CardBody>
                    <div className="multisig"></div>
                    <div className="w-full d-flex justify-content-between">
                      <div className="multisig-row">
                        <span className="multisig-title">{item?.ms_name?.toUpperCase()}</span>
                        <div className="d-flex">
                          <span className="shortAddress me-1">{truncateMiddle(item?.ms_address, 18)}</span>
                          <i className="fa-regular fa-copy" style={{ cursor: "pointer", color: "#0094D5" }} onClick={() => handleCopyCode(item?.ms_address)} />
                        </div>
                      </div>
                      <div className="d-flex">
                        {pendingTransactionCounts && Object.keys(pendingTransactionCounts)?.length && pendingTransactionCounts[item?.ms_address]?.length ? (
                          <div className="position-relative">
                            <Button onClick={() => handlePendingTransaction(item)} size="sm" color="warning">
                              <i className="fa-regular fa-clock fa-lg" style={{ color: "#fff" }} />
                            </Button>
                            <span className="badge badge-danger transaction-badge ">{pendingTransactionCounts[item?.ms_address]?.length}</span>
                          </div>
                        ) : null}
                        <CommonTooltip placement="top" id={`pending-transactions`} content={"Pending transactions"} color={"primary"} />
                        {edit ? (
                          <>
                            <Button id={`edit-multisig-${i}`} onClick={() => handleEdit(item?.id)} size="sm" color="primary" className="custom-send-btn custom-primary-btn">
                              <i className="fa-solid fa-pen-to-square" />
                            </Button>
                            <CommonTooltip placement="top" id={`edit-multisig-${i}`} content={"Edit Multisig"} color={"primary"} />{" "}
                          </>
                        ) : null}
                        {destroy ? (
                          <>
                            <Button id={`delete-multisig-${i}`} onClick={() => handleDelete(item?.id)} size="sm" color="primary" className="custom-send-btn custom-primary-btn">
                              <i className="fa-solid fa-trash" />
                            </Button>
                            <CommonTooltip placement="top" id={`delete-multisig-${i}`} content={"Delete Multisig"} color={"primary"} />{" "}
                          </>
                        ) : null}
                        <div id={`batch-call-${i}`}>
                          <Button size="sm" disabled={isWalletConnected ? false : true} color="primary" className="custom-send-btn " onClick={() => handleSend(item, "batchCalls")}>
                            Batch Calls
                            <i className="fa-solid fa-share-nodes ms-2" />
                          </Button>
                          {!isWalletConnected && <CommonTooltip placement="top" id={`batch-call-${i}`} content={"Wallet is not connected"} color={"primary"} />}
                        </div>
                        <div id={`send-avail-${i}`}>
                          <Button size="sm" disabled={isWalletConnected ? false : true} color="primary" className="custom-send-btn " onClick={() => handleSend(item, "callData")}>
                            Send
                            <i className="fa-solid fa-paper-plane ms-2" />
                          </Button>
                          {!isWalletConnected && <CommonTooltip placement="top" id={`send-avail-${i}`} content={"Wallet is not connected"} color={"primary"} />}
                        </div>
                        <div className="dropdown">
                          <Button id={`view-details-${i}`} onClick={() => toggleDropdown(item?.id)} className="dropdown-toggle" />
                        </div>
                        <CommonTooltip placement="top" id={`view-details-${i}`} content={"View Details"} color={"primary"} />
                      </div>
                    </div>
                    <div className="multi-count">
                      <span>{balances[item?.ms_address] === null || balances[item?.ms_address] === undefined ? "Loading..." : balances[item?.ms_address] + " AVAIL"}</span>
                    </div>
                    <div className={`multisig-mem ${openDropdownId === item?.id ? "is-open" : ""}`}>
                      <span className="team-title">Members :</span>
                      <div className="d-flex w-100 justify-content-between flex-wrap gap-2">
                        <div>
                          {checkJsonFormat(item?.members)?.map((member, index) => (
                            <>
                              <Badge key={index} style={{ cursor: "pointer" }} className="me-3" color="primary" id={`TooltipExample-from-${member?.meta?.name?.split(" ")?.join("")}`} onClick={() => handleCopyCode(member?.address)}>
                                {member?.meta?.name?.toUpperCase()}
                              </Badge>
                              <CommonTooltip classes={"me-3"} key={index} placement="right" id={`TooltipExample-from-${member?.meta?.name?.split(" ")?.join("")}`} content={member.address} color={"primary"} />
                            </>
                          ))}
                        </div>
                        <div id={`create-proxy-${i}`}>
                          <Button disabled={Number(balances[item?.ms_address]) < 14} size="sm" color="secondary" outline onClick={() => handleSend(item, "proxy")}>
                            Create Proxy
                            <i className="ms-2 fa-solid fa-universal-access" />
                          </Button>
                          {Number(balances[item?.ms_address]) < 14 ? <CommonTooltip placement="top" id={`create-proxy-${i}`} content={"Minimum 13 Avail required to create proxy"} color={"primary"} /> : null}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <div className="text-center no-found">{isWalletConnected ? "No Multisig account found" : "Wallet is not connected "}</div>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default MultisigAddressCard;
