import React from "react";
import { Edit, Lock, Trash2, Upload } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Table } from "reactstrap";
import InnerLoader from "../../Layout/InnerLoader";
import { formatAmount, formatDateTime, handleCopyCode, truncateMiddle } from "../../Utils/CustomFunctions";
import CommonTooltip from "../Common/CommonTooltip";

// Utility function to get nested properties
const getNestedValue = (obj, key) => {
  return key.split(".").reduce((o, x) => (o === undefined || o === null ? o : o[x]), obj);
};

const DynamicTable = ({ columns, data, serialNo, currentPage, perPage, isLoading, handleSort, sortOrder, onEdit, onDelete, uploadFile, tableClass }) => {
  const renderCellContent = (col, item) => {
    const emptyCellData = "--";
    const cellData = getNestedValue(item, col.key) || emptyCellData; // Use the utility function here
    const redirectLink = col.redirectLinkPrefix ? `${col.redirectLinkPrefix}${cellData}` : null;

    switch (col.type) {
      case "date":
        return cellData === emptyCellData ? emptyCellData : formatDateTime(cellData);

      case "amount":
        return formatAmount(cellData);

      case "boolean":
        return cellData ? <i className="fa-regular fa-circle-check treasury-right" /> : <i className="fa-regular fa-circle-xmark treasury-cancel" />;

      case "address": {
        const truncatedData = col?.truncate && (col.useTooltip ? !col.useTooltip(item) : true) ? truncateMiddle(cellData, 12) : cellData;
        return (
          <div className="copy-account">
            {col.useTooltip && col.useTooltip(item) ? (
              <CommonTooltip placement="right" id={`TooltipExample-${col.key}-${item.id}`} content={item[col.tooltipContent]} color={col.tooltipColor || "danger"} redirectLink={redirectLink ? redirectLink : false}>
                {col.useTooltip(item)}
              </CommonTooltip>
            ) : redirectLink ? (
              <Link to={redirectLink} target="_blank">
                {truncatedData}
              </Link>
            ) : (
              truncatedData
            )}
            {cellData !== emptyCellData && <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item[col.tooltipContent] || cellData)} />}
          </div>
        );
      }

      case "link":
        return (
          <Link to={redirectLink} target="_blank">
            {cellData}
          </Link>
        );

      default:
        return col.render ? col.render(item) : cellData;
    }
  };

  return (
    <Table bordered className={tableClass ? tableClass : "transaction-table"}>
      <thead>
        <tr>
          {serialNo && (
            <th className="head-text">
              <p className="header-name">No.</p>
            </th>
          )}
          {columns?.map((col) => (
            <th key={col.key} className="head-text" onClick={col.isSortable ? () => handleSort(col.key) : null}>
              <p className="header-name">
                {col.label}
                {col.isSortable && (sortOrder ? <i className="fa-solid fa-sort-up" /> : <i className="fa-solid fa-sort-down" />)}
              </p>
            </th>
          ))}
          {(onEdit || onDelete || uploadFile) && (
            <th className="head-text">
              <p className="header-name justify-content-center">Action</p>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={columns?.length + (onEdit || onDelete || uploadFile ? 1 : 0) + (serialNo ? 1 : 0)} className="text-center">
              <InnerLoader />
            </td>
          </tr>
        ) : data?.length > 0 ? (
          data?.map((item, i) => (
            <tr key={item.id}>
              {serialNo && <td>{i + 1 + (currentPage - 1) * perPage}</td>}

              {columns?.map((col) => (
                <td key={col.key}>{renderCellContent(col, item)}</td>
              ))}

              {!item?.is_reserved ? (
                (onEdit || onDelete || uploadFile) && (
                  <td>
                    <div className="common-flex justify-content-center">
                      {onEdit && (
                        <div className="outer-box">
                          <Button color="transparent" className="flex-center" onClick={() => onEdit(item?.id)}>
                            <Edit size={20} />
                          </Button>
                        </div>
                      )}
                      {uploadFile && (
                        <div className="outer-box">
                          <Button color="transparent" onClick={() => uploadFile(item?.id)}>
                            <Upload size={10} />
                          </Button>
                        </div>
                      )}
                      {onDelete && (
                        <div className="outer-box">
                          <Button color="transparent" className="flex-center trash-icon" onClick={() => onDelete(item?.id)}>
                            <Trash2 size={20} />
                          </Button>
                        </div>
                      )}
                    </div>
                  </td>
                )
              ) : (
                <td>
                  <div className="outer-box" id="lock_tooltip">
                    {/* <Button color="transparent" className="flex-center" onClick={() => onEdit(item?.id)}> */}
                    <Lock size={20} />
                    {/* </Button> */}
                  </div>
                  <CommonTooltip placement="top" id={`lock_tooltip`} content={"Only admin have access"} color={"danger"} />
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns?.length + 1 + (onEdit || onDelete || uploadFile ? 2 : 0)} className="text-center no-found">
              No data found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DynamicTable;
