import React from "react";
import { formatAmount } from "../../../Utils/CustomFunctions";

const DexVolumeCard = ({ dexVolumeChanges, ethVolume, baseDexVolume, ethDexVolumeChanges, baseDexVolumeChanges }) => {
  return (
    <div className="price-usd">
      <h6 className="text-center"> Prev Dex Volume</h6>
      <div className="custom-price">
        <div className={`price badge-light-`}>
          <div className="dex-volume row">
              <div className={`volume col-xxl-6 col-xl-12 badge-light-${ethDexVolumeChanges > 0 ? "success" : ethDexVolumeChanges < 0 ? "danger" : "warning"}`}>
                <h5>AVAIL/USDC ETH</h5>
                <div className="d-flex">
                  <h6>{formatAmount(ethVolume) || 0}</h6>{" "}
                  <span>
                    <i className={`fa-solid fa-arrow-${ethDexVolumeChanges > 0 ? "trend-up" : ethDexVolumeChanges < 0 ? "trend-down" : ""}`}/>
                    <div className="usd">{ethDexVolumeChanges || 0} %</div>
                  </span>
                </div>
              </div>
              <div className={`volume col-xxl-6 col-xl-12 badge-light-${baseDexVolumeChanges > 0 ? "success" : baseDexVolumeChanges < 0 ? "danger" : "warning"}`}>
                <h5>AVAIL/USDC BASE</h5>
                <div className="d-flex">
                  <h6>{formatAmount(baseDexVolume) || 0}</h6>
                  <span>
                    <div className="usd">
                      <i className={`fa-solid fa-arrow-${baseDexVolumeChanges > 0 ? "trend-up" : baseDexVolumeChanges < 0 ? "trend-down" : ""}`}/>
                      {baseDexVolumeChanges || 0} %
                    </div>
                  </span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DexVolumeCard;
