import React, { createContext, useContext, useState } from "react";
import { useGetApi } from "../Api/useGetApi";
import { getWalletAccountsApi } from "../Api/ApiList";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [editModal, setEditModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentModal, setCurrentModal] = useState("connect");
  const [api, setApi] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { data: walletData, refetch: refetchWalletAccounts } = useGetApi({ url: getWalletAccountsApi, options: { enabled: false, refetchOnMount: false, refetchOnWindowFocus: false } });

  return <WalletContext.Provider value={{ refetchWalletAccounts, walletData, activeTab, setSelectedAccount, selectedAccount, setIsExtensionInstalled, isExtensionInstalled, setAccounts, accounts, setCurrentModal, setApi, api, currentModal, setActiveTab, editModal, setEditModal, toggleEdit, searchTerm, setSearchTerm }}>{children}</WalletContext.Provider>;
};

export const useWallet = () => {
  return useContext(WalletContext);
};
