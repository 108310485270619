import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader } from "reactstrap";
import { availStatistics, coinMarketApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import Loader from "../../Layout/Loader";
import { calculatePercentChange } from "../../Utils/CustomFunctions";
import AvailInfoCards from "./AvailInfoCards";
import AvailInfoCharts from "./AvailInfoCharts";

const AvailInfoContainer = () => {
  const [volumeChange, setVolumeChange] = useState(0);
  const [priceChange, setPriceChange] = useState(0);
  const [holderPercentage, setHolderPercentage] = useState(null);
  const [baseDexVolume, setBaseDexVolume] = useState(null);
  const [ethVolume, setETHDexVolume] = useState(null);
  const [lastSecondPercentageHolder, setLastSecondPercentageHolder] = useState(null);
  const [holdersChangePerDay, setHoldersChangePerDay] = useState(null);
  const [ethDexVolumeChanges, setEthDexVolumeChanges] = useState(0);
  const [baseDexVolumeChanges, setBaseDexVolumeChanges] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState("day");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const navigate = useNavigate();
  const { data, isLoading } = useGetApi({
    url: availStatistics,
    params: { filterType: selectedPeriod }, // Only pass params if both startDate and endDate exist
    options: {
      enabled: !!(startDate && endDate), // Only enable query if both dates are provided
      select: (res) => res || [],
    },
    successFunction: false,
    navigate: navigate,
  });

  const requiredData = ["avail_account_holder_distribution", "eth_account_holder_distribution", "base_account_holder_distribution", "st_eth_account_holder_distribution", "st_base_account_holder_distribution"];
  const updatedDatasets = data?.data?.map((item) => {
    const distributionSums = {
      dust: 0,
      "1-100": 0,
      "10000+": 0,
      "100-1000": 0,
      "1000-10000": 0,
    };

    // Total sum of all categories for percentage calculation
    let totalSum = 0;

    // Iterate over the required fields and sum up the values
    requiredData.forEach((field) => {
      if (item?.[field]) {
        const distribution = item[field];
        Object.keys(distribution).forEach((key) => {
          distributionSums[key] += distribution[key] || 0; // Add value to the respective category
        });
      }
    });

    // Calculate the total sum of all categories
    totalSum = Object.values(distributionSums).reduce((sum, value) => sum + value, 0);

    // return distributionSums;
    const percentages = {};
    Object.keys(distributionSums).forEach((key) => {
      percentages[key] = totalSum > 0 ? ((distributionSums[key] / totalSum) * 100).toFixed(1) : "0.0";
    });

    return distributionSums;
  });

  const { data: coinMarketData, isLoading: coinMarketDataLoader } = useGetApi({ url: coinMarketApi, options: { enabled: true, select: (res) => res?.data?.data?.["32376"]?.quote?.USD }, successFunction: false, navigate: navigate });

  function calculateDistribution(datasets) {
    // Initialize overall totals and grand total
    const overallTotals = { "1-100": 0, "10000+": 0, "100-1000": 0, "1000-10000": 0, dust: 0 };
    let grandTotal = 0;

    // Calculate sums for each dataset and overall totals
    datasets.forEach((dataset) => {
      if (dataset && typeof dataset === "object") {
        for (const key in dataset) {
          overallTotals[key] += dataset[key] || 0;
        }
        // grandTotal += 0;}
        grandTotal += Object.values(dataset).reduce((sum, value) => sum + value, 0);
      }
    });

    // Calculate percentages
    const percentages = {};
    for (const key in overallTotals) {
      percentages[key] = grandTotal > 0 ? ((overallTotals[key] / grandTotal) * 100).toFixed(2) : "0.00";
    }

    return { overallTotals, grandTotal, percentages };
  }
  useEffect(() => {
    const holdersChangePerDay = calculatePercentChange(lastSecondPercentageHolder?.grandTotal, holderPercentage?.grandTotal);
    setHoldersChangePerDay(holdersChangePerDay);
  }, [holderPercentage, lastSecondPercentageHolder]);

  useEffect(() => {
    if (data) {
      const { avail_account_holder_distribution, base_account_holder_distribution, eth_account_holder_distribution, st_base_account_holder_distribution } = data?.latestStatistic || {};
      const { avail_account_holder_distribution: last_second_avail_account_holder_distribution, base_account_holder_distribution: last_second_base_account_holder_distribution, eth_account_holder_distribution: last_second_eth_account_holder_distribution, st_base_account_holder_distribution: last_second_st_base_account_holder_distribution } = data?.yesterdayStatistics || {};
      // const calculatedTotalHolders = avail_account_holders + base_account_holders + eth_account_holders + st_base_account_holders + st_eth_account_holders;
      setVolumeChange(calculatePercentChange(data?.latestStatistic?.prev_volume, coinMarketData?.volume_24h)?.percentageChange || 0);
      setPriceChange(calculatePercentChange(data?.latestStatistic?.price, coinMarketData?.price)?.percentageChange || 0);
      const dataSets = [avail_account_holder_distribution, base_account_holder_distribution, eth_account_holder_distribution, st_base_account_holder_distribution];
      const lastSecondDataSet = [last_second_avail_account_holder_distribution, last_second_eth_account_holder_distribution, last_second_base_account_holder_distribution, last_second_st_base_account_holder_distribution];
      const lastSecondPercentageHolder = calculateDistribution(lastSecondDataSet);
      const percentageHolder = calculateDistribution(dataSets);
      setLastSecondPercentageHolder(lastSecondPercentageHolder);
      setHolderPercentage(percentageHolder);
      setBaseDexVolume(Number(data?.latestStatistic?.prev_dex_volume_base));
      setETHDexVolume(Number(data?.latestStatistic?.prev_dex_volume_eth));
      const availEthChange = calculatePercentChange(data?.yesterdayStatistics?.prev_dex_volume_eth, data?.latestStatistic?.prev_dex_volume_eth);
      const availBaseChange = calculatePercentChange(data?.yesterdayStatistics?.prev_dex_volume_base, data?.latestStatistic?.prev_dex_volume_base);
      if (availEthChange?.percentageChange) {
        setEthDexVolumeChanges(Number(availEthChange.percentageChange));
      }
      if (availBaseChange) {
        setBaseDexVolumeChanges(Number(availBaseChange.percentageChange));
      }
    }
  }, [data, coinMarketData, selectedPeriod]); 

  const dateRangeOptions = [
    { label: "Month", value: "month" },
    { label: "Week", value: "week" },
    { label: "Day", value: "day" },
  ];

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handlePeriodChange = (range) => {
    setSelectedPeriod(range);

    const today = new Date();
    let start, end;

    switch (range) {
      case "day":
        start = today;
        end = today;
        break;
      case "week":
        const firstDayOfWeek = today.getDate() - today.getDay();
        start = new Date(today.setDate(firstDayOfWeek));
        const lastDayOfWeek = start.getDate() + 6;
        end = new Date(today.setDate(lastDayOfWeek));
        break;
      case "month":
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      default:
    }

    setStartDate(formatDate(start));
    setEndDate(formatDate(end));
  };

  useEffect(() => {
    handlePeriodChange(selectedPeriod);
  }, []);

  if (isLoading || coinMarketDataLoader) return <Loader />;

  return (
    <section className="common-table">
      <Card>
        <CardHeader className="avail-info">
          <div className="d-flex justify-content-between ">
            <h4>Avail Info</h4>
            <select value={selectedPeriod} type="select" className="form-select w-auto" onChange={(e) => handlePeriodChange(e.target?.value)}>
              {dateRangeOptions.map((item) => (
                <option key={item?.label} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
          <AvailInfoCards ethDexVolumeChanges={ethDexVolumeChanges} baseDexVolumeChanges={baseDexVolumeChanges} baseDexVolume={baseDexVolume} coinMarketData={coinMarketData} ethVolume={ethVolume} holderPercentage={holderPercentage} holdersChangePerDay={holdersChangePerDay} priceChange={priceChange} volumeChange={volumeChange} />
        </CardHeader>
      </Card>
      <AvailInfoCharts data={data} updatedDatasets={updatedDatasets} />
    </section>
  );
};

export default AvailInfoContainer;
