import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Plus, RotateCw } from "react-feather";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { deleteMultisigApi, deleteProxiedApi, getMultisigTransactionApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useMultisig } from "../../Context/MultisigContext";
import { useProxied } from "../../Context/ProxiedContext";
import { useWallet } from "../../Context/WalletContext";
import Loader from "../../Layout/Loader";
import { getAvailBalance, getPendingMultisigTransactions } from "../Common/AvailCommon";
import { checkJsonFormat, deepParseJson } from "../Common/CustomFunctions";
import DeleteModal from "../Common/DeleteModal";
import AddMultisigModal from "./Modals/AddMutisigModal";
import AddProxiedModal from "./Modals/AddProxiedModal";
import BatchCallsModal from "./Modals/BatchCallsModal";
import BatchCallsProxiedModal from "./Modals/BatchCallsProxiedModal";
import PendingTransactionModal from "./Modals/PendingTransactionModal";
import ProxyModal from "./Modals/ProxyModal";
import SendAvailModal from "./Modals/SendAvailModal";
import SendInitialAvailProxied from "./Modals/SendInitialAvailProxied";
import MultisigAddressCard from "./MultisigAddressCard";
import ProxiedAddressCard from "./ProxiedAddressCard";
import TotalBalanceCard from "./TotalBalanceCard";
import TransactionLoader from "./Utils/TransactionLoader";
import usePermissionCheck from "../../Hooks/usePermissionCheck";
import { useAuth } from "../../Context/AuthContext";
import { blake2AsHex } from "@polkadot/util-crypto";

const MultisigContainer = () => {
  const { api } = useWallet();
  const [multisigTransactions, setPendingMultisigTransactions] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [refetchMultisig, setRefetchMultisig] = useState(null);
  const [refetchProxied, setRefetchProxied] = useState(null);
  const [balances, setBalances] = useState({}); // Store balances by address
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [sendAddress, setSendAddress] = useState("");
  const [pendingTransactionCounts, setPendingTransactionCounts] = useState({});
  const { refetchWalletAccounts, accounts } = useWallet();
  const { editProxiedData, deleteProxiedModal, toggleProxiedDelete, proxiedData, toggleProxiedEdit, editProxiedModal } = useProxied();
  const { transactionUpdates, setEditMode, editMode, toggleEdit, toggleDelete, multisigLoading, deleteModal, editModal, multisigData, editMultisigData, transactionLoading } = useMultisig();
  const { isLoading, data: transactionData, refetch: refetchGetData } = useGetApi({ url: getMultisigTransactionApi, params: { status: "pending" }, options: { enabled: false, refetchOnWidowFocus: false }, successFunction: false });
  const isWalletConnected = localStorage.getItem("isWalletConnected");
  const [create] = usePermissionCheck(["create"], "multisig_admin");
  const { role } = useAuth();

  const [modalState, setModalState] = useState({
    addProxy: false,
    hashes: false,
    callData: false,
    proxy: false,
    initialProxy: false,
    batchCalls: false,
    batchCallsProxied: false,
  });

  // Unified modal toggle function
  const toggleModal = (modalName) => {
    setModalState((prev) => ({
      ...prev,
      [modalName]: !prev[modalName],
    }));
  };
  // const toggleCallDataModal = () => setCallDataModal((prev) => !prev);
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleSend = (item, modal) => {
    setSendAddress(item);
    setTimeout(() => {
      toggleModal(modal);
    }, 500);
  };
  const groupHashesBySender = () => {
    try {
      const pendingTransactionData = transactionData?.data?.reduce((acc, tx) => {
        if (!acc[tx?.from]) {
          acc[tx?.from] = [];
        }
        acc[tx?.from].push(tx?.hash);
        return acc;
      }, {});
      setPendingTransactionCounts(pendingTransactionData);
    } catch (err) {
      console.error("Error fetching pending transactions:", err);
    }
  };


  // 1. Get user's addresses
  const userAddresses = useMemo(() => accounts?.map((account) => account.address) || [], [accounts]);

  // 2. Filter multisig data to show only cards where user is a member
  const filteredMultisigData = useMemo(() => {
    if (!multisigData?.data) return [];
    if (role?.toLowerCase() === "admin") return multisigData.data;

    return multisigData.data.filter((item) => {
      const members = checkJsonFormat(item.members);
      return members.some((member) => userAddresses.includes(member.address));
    });
  }, [multisigData, userAddresses, role]);

  const filteredProxiedData = useMemo(() => {
    if (!proxiedData?.data || !multisigData?.data) return [];

    if (role?.toLowerCase() === "admin") return proxiedData.data;
    return proxiedData.data.filter((proxiedItem) => {
      // Get associated multisig details
      const multisigDetails = deepParseJson(proxiedItem.multisig_details);
      const linkedMultisig = multisigData.data.find((ms) => ms.ms_address === multisigDetails?.ms_address);

      // Check if user is member of linked multisig
      if (linkedMultisig) {
        const members = checkJsonFormat(linkedMultisig.members);
        return members.some((member) => userAddresses.includes(member.address));
      }
      return false;
    });
  }, [proxiedData, multisigData, userAddresses, role]);

  const fetchBalances = async () => {
    try {
      const balanceData = await Promise.all(
        [...filteredMultisigData, ...(filteredProxiedData || [])]?.map(async (item) => {
          const balance = await getAvailBalance(api, item?.ms_address || item?.proxied_address);
          return { address: item?.ms_address || item?.proxied_address, balance };
        })
      );

      // Calculate total balance
      const total = balanceData.reduce((acc, item) => acc + parseFloat(item.balance), 0);
      setTotalBalance(total);

      // Convert the result to a key-value pair for quick lookup
      const balanceMap = balanceData.reduce((acc, item) => {
        acc[item.address] = item.balance;
        return acc;
      }, {});
      setBalances(balanceMap);
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const handlePendingTransaction = (item) => {
    toggleModal("hashes");
    setSendAddress(item);
  };

  useEffect(() => {
    refetchWalletAccounts();
    refetchGetData();
    // fetchPendingTransactions();
  }, []);

  useEffect(() => {
    if (api) {
      fetchBalances();
      groupHashesBySender();
    }
  }, [multisigData, api, transactionUpdates, proxiedData, transactionData, accounts, isWalletConnected]);

  if (!api || multisigLoading || isLoading) return <Loader />;

  return (
    <Fragment>
      <section className="common-table">
        {transactionLoading && <TransactionLoader />}
        <Card className="mb-3">
          <CardHeader className="d-flex justify-content-between gap-2">
            <h4>Multisig Utils</h4>
            {create && (
              <div className="d-flex gap-2 flex-wrap">
                <Button size="sm" color="transparent" className="btn-outline-secondary" onClick={toggleEdit}>
                  <Plus size={15} />
                  Multisig
                </Button>
                <Button size="sm" color="transparent" className="btn-outline-secondary" onClick={toggleProxiedEdit}>
                  <Plus size={15} />
                  Import Proxy
                </Button>
                <Button size="sm" color="transparent" className="btn-outline-secondary" onClick={toggleEdit}>
                  <RotateCw size={15} />
                  Fetch transaction online
                </Button>
              </div>
            )}
          </CardHeader>
          <CardBody>
            <TotalBalanceCard totalBalance={totalBalance} />
          </CardBody>
        </Card>
        <MultisigAddressCard filteredMultisigData={filteredMultisigData} setRefetchMultisig={setRefetchMultisig} balances={balances} handlePendingTransaction={handlePendingTransaction} handleSend={handleSend} multisigData={multisigData} toggleDropdown={toggleDropdown} pendingTransactionCounts={pendingTransactionCounts} openDropdownId={openDropdownId} />
        <ProxiedAddressCard filteredProxiedData={filteredProxiedData} setRefetchProxied={setRefetchProxied} multisigData={multisigData} handlePendingTransaction={handlePendingTransaction} pendingTransactionCounts={pendingTransactionCounts} balances={balances} openDropdownId={openDropdownId} toggleDropdown={toggleDropdown} handleSend={handleSend} />
      </section>
      {editModal && <AddMultisigModal isEditMode={editMode} refetchMultisig={refetchMultisig} setEditMode={setEditMode} />}
      {deleteModal && <DeleteModal isOpen={deleteModal} deleteEndpoint={deleteMultisigApi} itemData={{ id: editMultisigData?.id }} itemType="Multisig" toggleModal={toggleDelete} refetchCallback={refetchMultisig} />}
      {deleteProxiedModal && <DeleteModal isOpen={deleteProxiedModal} deleteEndpoint={deleteProxiedApi} itemData={{ id: editProxiedData?.id }} itemType="Proxied" toggleModal={toggleProxiedDelete} refetchCallback={refetchProxied} />}
      {modalState?.callData && <SendAvailModal refetchGetData={refetchGetData} senderDetails={sendAddress} toggle={toggleModal} isOpen={modalState?.callData} />}
      {modalState?.initialProxy && <SendInitialAvailProxied refetchGetData={refetchGetData} senderDetails={sendAddress} toggle={toggleModal} isOpen={modalState?.initialProxy} />}
      {modalState?.hashes && <PendingTransactionModal refetchGetData={refetchGetData} mainTransactionData={pendingTransactionCounts} refetchProxied={refetchProxied} setPendingMultisigTransactions={setPendingMultisigTransactions} multisigTransactions={multisigTransactions} toggle={toggleModal} isOpen={modalState?.hashes} senderDetails={sendAddress} />}
      {editProxiedModal && <AddProxiedModal refetchProxied={refetchProxied} senderDetails={sendAddress} toggle={toggleModal} />}
      {modalState?.proxy && <ProxyModal refetchGetData={refetchGetData} isOpen={modalState?.proxy} senderDetails={sendAddress} toggle={toggleModal} />}
      {modalState?.batchCalls && <BatchCallsModal refetchGetData={refetchGetData} isOpen={modalState.batchCalls} senderDetails={sendAddress} toggle={toggleModal} />}
      {modalState?.batchCallsProxied && <BatchCallsProxiedModal refetchGetData={refetchGetData} isOpen={modalState.batchCallsProxied} senderDetails={sendAddress} toggle={toggleModal} />}
    </Fragment>
  );
};

export default MultisigContainer;
