import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import { blake2AsHex } from "@polkadot/util-crypto";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createMultisigTransactionApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import { truncateMiddle } from "../../../Utils/CustomFunctions";
import { getInjectorMetadata, getOtherSignatories } from "../../Common/AvailCommon";
import { checkJsonFormat } from "../../Common/CustomFunctions";
import { getBlockNumber } from "../Utils";

const ProxyModal = ({ isOpen, toggle, senderDetails, refetchGetData }) => {
  const [loading, setLoading] = useState(false);
  const { setTransactionUpdates, setTransactionLoading } = useMultisig();
  const { api, accounts } = useWallet();
  const { mutate } = usePerformMutation(() => {
    refetchGetData();
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      proxiedAddress: "", // Leave it empty initially
      callData: "",
      callHash: "",
      currentAddress: accounts?.[0]?.address || "",
    },
  });

  const closeModal = () => {
    toggle("proxy");
  };

  const getCreatePureCallData = async () => {
    if (!api) {
      toast.error("Please ensure that API is connected");
      return;
    }

    try {
      const createPureCall = api.tx.proxy.createPure("Any", 0, 0);
      const hexCallData = createPureCall.method.toHex();
      const callHash = blake2AsHex(hexToU8a(hexCallData));

      return {
        hexCallData,
        callHash,
      };
    } catch (error) {
      console.error("Error generating createPure call data:", error);
      toast.error("Failed to generate proxy call hash");
    }
  };

  const signTransaction = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      setLoading(true);
      const injector = await web3FromSource("subwallet-js");
      if (!api) await injector.metadata.provide(getInjectorMetadata(api));
      const fromAddress = data?.currentAddress;
      const otherSignatories = getOtherSignatories(senderDetails?.members, fromAddress);
      const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, null, data.callHash, {
        refTime: 196085000,
        proofSize: 3593,
      });

      setTransactionLoading(true);
      await transfer.signAndSend(fromAddress, { signer: injector.signer }, async ({ status, events }) => {
        let extrinsicIndex = null;
        if (status.isInBlock) {
          events.forEach(({ phase }) => {
            if (phase.isApplyExtrinsic) {
              extrinsicIndex = phase.asApplyExtrinsic.toNumber();
            }
          });
          const blockNumber = await getBlockNumber(api, status);
          const mutableData = { call_data: watch("callData"), extrinsic: String(blockNumber) + "-" + String(extrinsicIndex), block: blockNumber, from: senderDetails?.ms_address, to: null, signers: JSON.stringify([fromAddress]), hash: watch("callHash"), threshold: senderDetails?.threshold, status: "pending", amount: null, comment: "Multisig transaction (proxy creation)" };
          await mutate({ url: createMultisigTransactionApi, data: mutableData });
          setTransactionLoading(false);
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`First approval done for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      closeModal();
      setLoading(false);
      setTransactionUpdates(`First approval done for ${data.hash}`);
    } catch (err) {
      setLoading(false);

      setTransactionUpdates(`Error occurred for ${data.hash}`);
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
      setTransactionLoading(false);
    }
  };

  const handleCancel = () => {
    closeModal();
    reset({
      proxiedAddress: senderDetails?.ms_address || "",
      proxiedName: "",
      callData: "",
    });
  };

  useEffect(() => {
    if (!api) {
      return;
    }
    setValue("proxiedAddress", senderDetails?.ms_address);
    const fetchCallData = async () => {
      const { hexCallData, callHash } = await getCreatePureCallData();
      setValue("callData", hexCallData);
      setValue("callHash", callHash);
    };

    fetchCallData();
  }, [senderDetails]);

  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Add Proxied Account"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(signTransaction)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormInput label="Account:" name={"proxiedAddress"} helperText={" The sending account that will be used to send this transaction. Any applicable fees will be paid by this account."} errors={errors} register={register} placeholder={"Address being proxied"} validationRules={{ required: "Proxied account address is required" }} readOnly />
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Multisig signatory:</label>
                <select {...register("currentAddress", { required: "Signatory must be selected" })} className={`form-control ${errors.currentAddress ? "is-invalid" : ""}`}>
                  <option value="" disabled>
                    Select address
                  </option>
                  {accounts
                    ?.filter((member) => checkJsonFormat(senderDetails?.members)?.some((account) => account.address.toLowerCase() === member.address.toLowerCase()))
                    ?.map((member, index) => (
                      <option key={index} value={member?.address}>
                        {`${member?.meta?.name?.toUpperCase()} [${truncateMiddle(member?.address, 16)}]`}
                      </option>
                    ))}
                </select>
                <p className="helper-text">The signatory is one of the allowed accounts on the multisig, making a recorded approval for the transaction.</p>
                {errors.currentAddress && <div className="invalid-feedback">{errors.currentAddress.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormInput label="Call data:" name={"callData"} helperText={"The full call data that can be supplied to a final call to multi approvals"} errors={errors} register={register} validationRules={{ required: "Signatory must be selected" }} readOnly placeholder="Call data for approval" />
            </Col>
            <Col sm="12">
              <FormInput label="Call hash:" name={"callHash"} helperText={"The call hash as calculated for this transaction"} errors={errors} register={register} placeholder="Call data for approval" validationRules={{ required: "Signatory must be selected" }} readOnly />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? "Adding..." : "Add"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ProxyModal;
