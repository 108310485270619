import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import { readAllNotificationsApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import useNotifications from "../../Api/useNotifications";
import { NotificationTableColumnData } from "../../Data/TableColumnsData";
import Loader from "../../Layout/Loader";
import CommonTable from "../Common/CommonTable";

const Notifications = () => {
  const navigate = useNavigate();
  const { isLoading: isLoadingGetData, notificationsData, refetchGetData } = useNotifications();
  const { refetch: refetchReadAllNotificationsData } = useGetApi({ url: readAllNotificationsApi, options: { refetchOnWindowFocus: false, enabled: false }, navigate: navigate });

  useEffect(() => {
    refetchGetData()
      .then((res) => {
        const isAllNotificationRead = res?.data?.data?.filter((data) => !data?.mark_as_read);
        if (isAllNotificationRead?.length) {
          refetchReadAllNotificationsData().then(() =>
            setTimeout(() => {
              refetchGetData();
            }, 2000)
          );
        } else {
          return;
        }
      })
      .catch((err) => {
        return err;
      });
  }, []);

  if (isLoadingGetData) return <Loader />;

  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader>
            <h4>Notification</h4>
          </CardHeader>
          <CardBody>
            <div className="custom-scrollbar table-responsive">
              <CommonTable  columns={NotificationTableColumnData} data={notificationsData?.data} isLoading={isLoadingGetData} tableClass="notification-table mb-0" />
            </div>
          </CardBody>
        </Card>
      </section>
    </Fragment>
  );
};

export default Notifications;
