import React, { Fragment } from "react";
import { checkJsonFormat } from "../../Components/Common/CustomFunctions";
import { useAuth } from "../../Context/AuthContext";
import { MenuList } from "../../Data/Layout/SidebarData";
import Loader from "../../Layout/Loader";
import Menulist from "./Menulist";

const SidebarMenuList = ({ activeMenu, setActiveMenu }) => {
  const { accountData, role } = useAuth();
  const ignoreSidebarItems = []; // just add title of sidebar item
  const ignoreList = role === "admin" ? [...ignoreSidebarItems] : ignoreSidebarItems;
  const getPermissionArray = (sidebarItems) => {
    return sidebarItems?.reduce((filteredItems, item) => {
      const clonedItem = { ...item };

      if (ignoreList.includes(item.title)) {
        filteredItems.push(item);
      }
      if (clonedItem.permission) {
        // Filter permissions based on permission `name` rather than `id`
        clonedItem.permission = clonedItem.permission.filter((perm) => {
          if (typeof accountData?.permissions == "string") {
            let arrayPermissions = checkJsonFormat(accountData?.permissions);
            return arrayPermissions?.some((p) => p === perm);
          } else {
            return accountData?.permissions?.some((p) => p === perm);
          }

          // return true;
        });
      }

      if (clonedItem?.children && clonedItem.children.length > 0) {
        clonedItem.children = getPermissionArray(clonedItem.children);
      }

      if (clonedItem?.permission?.length > 0 || (clonedItem?.children && clonedItem?.children?.length > 0)) {
        filteredItems.push(clonedItem);
      }
      return filteredItems;
    }, []);
  };

  const ModifiedSidebar = MenuList.map((menuCategory) => ({
    ...menuCategory,
    mainMenus: getPermissionArray(menuCategory.mainMenus),
  }));

  // useEffect(() => {
  //   let storedRole = localStorage.getItem("role");

  //   if (storedRole) {
  //     const parsedRole = JSON.parse(storedRole);
  //     setRole(parsedRole.name);
  //   }
  // }, []);
  if (!accountData) return <Loader />;
  return (
    <ul className="custom-scrollbar sidebar-listed-custom">
      {ModifiedSidebar?.map((item) => (
        <Fragment key={item.headingTitle}>
          {item?.mainMenus?.length ? (
            <li className="sidebar-heading-title">
              <div className="custom-heading-sidebar">
                <p>{item?.headingTitle}</p>
              </div>
            </li>
          ) : null}
          <Menulist menu={item?.mainMenus} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
        </Fragment>
      ))}
    </ul>
  );
};

export default SidebarMenuList;
