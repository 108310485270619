import React from "react";
import { useBudget } from "../../../Context/BudgetContext";
import usePermissionCheck from "../../../Hooks/usePermissionCheck";
import { formatDateTime, handleCapitalizeMultiple } from "../../../Utils/CustomFunctions";
import CommonTable from "../../Common/CommonTable";

const BankPaymentsTable = ({ data, isLoadingGetData, handleEdit, handleDelete, page, limit }) => {
  const [edit, destroy] = usePermissionCheck(["edit", "destroy"], "budget");
  const { handleSort, sortOrder } = useBudget();
  const columns = [
    {
      key: "to",
      label: "To Address",
      isSortable: true,
    },
    {
      key: "date",
      label: "Invoice Date",
      render: (item) => (item?.date ? formatDateTime(item?.date) : "--"),
      isSortable: true,
    },
    {
      key: "id",
      label: "Invoice No",
      isSortable: true,
    },
    {
      key: "expense_category",
      label: "Expense Category",
    },
    {
      key: "type_of_expense",
      label: "Type of Expense",
    },
    {
      key: "currency",
      label: "Currency",
    },
    {
      key: "amount",
      label: "Amount",
      type: "amount",
      isSortable: true,
    },
    {
      key: "amountUSD",
      label: "Amount (USD)",
      type: "amount",
      isSortable: true,
    },
    {
      key: "status",
      label: "Status",
      isSortable: true,
      render: (item) => (
        <span color="transparent" className={`badge ${item?.status === "Paid" || item?.status === "Completed" ? "badge-light-success" : item?.status === "Expired" || item?.status === "Cancelled" ? "badge-light-danger" : "badge-light-warning"}`}>
          {" "}
          {handleCapitalizeMultiple(item?.status)}{" "}
        </span>
      ),
    },
  ];

  return <CommonTable serialNo currentPage={page} perPage={limit} handleSort={handleSort} sortOrder={sortOrder} tableClass={"budget-table"} data={data?.data || []} columns={columns} isLoading={isLoadingGetData} onEdit={edit ? handleEdit : false} onDelete={destroy ? handleDelete : false} />;
};

export default BankPaymentsTable;
