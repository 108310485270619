import { Link } from "react-router-dom";
import { checkJsonFormat, deepParseJson } from "../../Components/Common/CustomFunctions";
import { calculatePercentChange, formatAmount, formatNumber, handleCapitalizeMultiple, handleCopyCode, truncateMiddle } from "../../Utils/CustomFunctions";
import CommonTooltip from "../../Components/Common/CommonTooltip";

export const InvoiceTableColumnData = [
  { key: "due_date", label: "Due Date", type: "date" },
  { key: "paid_at", label: "Paid At", type: "date" },
  { key: "label", label: "Label" },
  { key: "currency", label: "Currency" },
  { key: "contract_id", label: "Contactor Id" },
  { key: "total", label: "Amount", type: "amount" },
  { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status?.toString() === "paid" ? "success" : item?.status?.toString() === "pending" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status?.toString())}</span> },
];

export const PaymentHistoryTableColumnData = [
  { key: "paid_at", label: "Paid At", type: "date" },
  { key: "label", label: "Label" },
  {
    key: "workers",
    label: "Workers",
    render: (item) =>
      item?.workers
        ? checkJsonFormat(item?.workers)
            ?.map((item) => item?.name)
            ?.join(",")
        : "--",
  },
  { key: "payment_currency", label: "Payment Currency" },
  { key: "total", label: "Amount", type: "amount" },
  { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status?.toString() === "paid" ? "success" : item?.status?.toString() === "pending" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status?.toString())}</span> },
];

export const BridgeTransferTableColumnData = [
  { key: "hash", label: "Hash", type: "address", truncate: true, redirectLinkPrefix: "https://etherscan.io/tx/" },
  { key: "from_account", label: "From Account", type: "address", truncate: true, redirectLinkPrefix: "https://etherscan.io/address/" },
  { key: "to_account", label: "To Account", type: "address", truncate: true, redirectLinkPrefix: `${process.env.REACT_APP_SUBSCAN_URL}/account/` },
  { key: "amount", label: "Amount", type: "amount" },
  { key: "timestamp", label: "Timestamp", type: "date" },
];

export const ExpensesTableColumnData = [
  { key: "expense_id", label: "Expense Id", type: "address", truncate: true },
  { key: "reported_by.full_name", label: "Reported By" },
  { key: "description", label: "Description" },
  { key: "type", label: "Type", render: (item) => handleCapitalizeMultiple(item?.type) },
  { key: "total_amount", label: "Amount", type: "amount" },
  { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status?.toString() === "paid" ? "success" : item?.status?.toString() === "pending" || item?.status?.toString() === "approved" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status)}</span> },
];

export const LiquidityTableColumnData = (data, page, pageSize, current, poolData) => {
  const formattedDate = (date) => date.replace("T", " ").split(".")[0];
  const currentPool = poolData?.filter((item) => item?.id == current);
  if (!Array.isArray(data)) {
    console.error("LiquidityTableColumnData received non-iterable data:", data);
    return [];
  }
  const filteredData = data.filter((item) => item.pool_id == 2);
  const minId = Math.min(...filteredData.map((item) => item.id));
  return [
    {
      key: "id",
      label: "No.",
      render: (item) => <td> {data[0]?.pool_id !== 1 ? (page - 1) * pageSize + (item.id - minId + 1) : item?.id}</td>,
    },
    {
      key: "block",
      label: "Block",
      render: (item) => (
        <Link to={currentPool?.network != "base" ? `https://basescan.org/block/${item.block}` : `https://etherscan.io/block/${item.block}`} target="_blank">
          {item?.block}
        </Link>
      ),
    },
    {
      key: "type",
      label: "Type",
      render: (item) => <span className={`badge badge-light-${item?.type === "BUY" || item?.type === "BUY AVAIL" ? "success" : "danger"}`}>{handleCapitalizeMultiple(item?.type)}</span>,
      isSortable: true,
    },
    {
      key: "tx_hash",
      label: "Transaction Hash",
      // type: "address",
      tooltipContent: "to_account",
      tooltipColor: "success",
      truncate: true,

      render: (item) => {
        return (
          <div className="copy-account">
            <Link to={currentPool?.network != "base" ? `https://basescan.org/tx/${item.tx_hash}` : `https://etherscan.io/tx/${item.tx_hash}`} target="_blank">
              {truncateMiddle(item?.tx_hash, 18)}
            </Link>
            <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.tx_hash, "hash")} />
          </div>
        );
      },
    },
    {
      key: "usdc_amount",
      label: "USDC Amount",
      type: "amount",
      isSortable: true,
    },
    {
      key: "avail_amount",
      label: "Avail Amount",
      type: "amount",
    },
    {
      key: "price_usdc",
      label: "Price Usdc",
      type: "amount",
      isSortable: true,
    },
    {
      key: "slippage",
      label: "Slippage",
      render: (item) => formatAmount(item?.slippage) + "%",
    },

    {
      key: "timestamp",
      label: "Timestamp",
      // type: "date",
      render: (item) => formattedDate(item?.timestamp),
      isSortable: true,
    },
  ];
};

export const NotificationTableColumnData = [
  {
    key: "notification",
    label: "Notification",
    render: (item) => (
      <div className={`${item?.mark_as_read ? "notify-push" : "notify-visible"}`}>
        <p>{item.title}</p>
        <span>{item.description}</span>
      </div>
    ),
  },
  {
    key: "status",
    label: "Status",
    render: (item) => <span className={`badge badge-light-${item?.status === "completed" ? "success" : "warning"}`}>{item?.status === "completed" ? "Executed" : "Pending"}</span>,
  },
];

export const PeoplesDataTableColumnData = [
  { key: "people_id", label: "People Id", type: "address", truncate: true },
  { key: "full_name", label: "Person" },
  { key: "birth_date", label: "Birth Date", type: "date" },
  { key: "timezone", label: "Timezone" },
  { key: "country", label: "Country" },
  { key: "job_title", label: "Job Title" },
  { key: "hiring_type", label: "Hiring Type", render: (item) => handleCapitalizeMultiple(item?.hiring_type) },
  { key: "hiring_status", label: "Hiring Status", render: (item) => <span className={`badge badge-light-${item?.hiring_status?.toLowerCase() === "active" ? "success" : item?.hiring_status?.toLowerCase() === "onboarding" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.hiring_status)}</span> },
];

export const TransactionTableColumnData = [
  {
    key: "extrinsic_id",
    label: "Extrinsic Id",
    isSortable: true,
    type: "link",
    redirectLinkPrefix: `${process.env.REACT_APP_SUBSCAN_URL}/extrinsic/`,
  },
  {
    key: "from_account",
    label: "From Account",
    type: "address",
    useTooltip: (item) => item.from_label,
    tooltipContent: "from_account",
    tooltipColor: "danger",
    redirectLinkPrefix: `${process.env.REACT_APP_SUBSCAN_URL}/account/`,
    truncate: true,
  },
  {
    key: "to_account",
    label: "To Account",
    type: "address",
    useTooltip: (item) => item.to_label,
    tooltipContent: "to_account",
    tooltipColor: "success",
    redirectLinkPrefix: `${process.env.REACT_APP_SUBSCAN_URL}/account/`,
    truncate: true,
  },
  {
    key: "amount",
    label: "Amount",
    isSortable: true,
    type: "amount",
  },
  {
    key: "timestamp",
    label: "Timestamp",
    isSortable: true,
    type: "date",
  },
];

export const UsersTableColumnData = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email",
  },

  {
    key: "role.name",
    label: "Role",
    render: (item) => <span className="badge badge-success">{item?.role?.name?.toUpperCase()}</span>,
  },
  {
    key: "created_at",
    label: "Created At",
    type: "date",
  },
];

export const RolesTableColumnData = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "created_at",
    label: "Created At",
    type: "date",
  },
];

export const MultisigDataTableColumnData = [
  { key: "ms_address", label: "MS Address", truncate: true },
  { key: "ms_name", label: "MS Name" },
  { key: "threshold", label: "Threshold" },
  {
    key: "members",
    label: "Members",
    render: (item) => {
      const members = Array.isArray(item?.members) ? item?.members : JSON.parse(item?.members || "[]");
      return members.map((item, index) => <p key={index}>{item}</p>);
    },
  },
];

export const MultisigTransactionsTableColumnData = [
  { key: "block", label: "Block", type: "link", redirectLinkPrefix: `${process.env.REACT_APP_SUBSCAN_URL}/block/` }, //"https://avail-turing.subscan.io/block/"
  { key: "extrinsic", label: "Extrinsic", type: "link", redirectLinkPrefix: `${process.env.REACT_APP_SUBSCAN_URL}/extrinsic/` },
  { key: "from", label: "From Address", type: "address", truncate: true },
  {
    key: "to",
    label: "To Address",
    render: (item) => {
      if (item?.to) {
        const parsedData = deepParseJson(item.to);
        if (Array.isArray(parsedData)) {
          return parsedData.map((addr, index) => (
            <div className="copy-account">
              <p key={index}>{truncateMiddle(addr?.to_address, 16)}</p> <i className="fa-regular fa-copy" onClick={() => handleCopyCode(addr?.to_address)} />{" "}
            </div>
          ));
        }
        return (
          <div className="copy-account">
            <p>{truncateMiddle(parsedData?.to_address || parsedData, 16)}</p> <i className="fa-regular fa-copy" onClick={() => handleCopyCode(parsedData?.to_address || parsedData)} />
          </div>
        );
      } else {
        return "--";
      }
    },
  },
  {
    key: "amount",
    label: "Amount",
    render: (item) => {
      if (item?.amount) {
        const parsedData = deepParseJson(item.amount);
        if (Array.isArray(parsedData)) {
          return parsedData.map((amt, index) => <p key={index}>{amt?.amount} AVAIL</p>);
        }
        return <p>{parsedData?.amount || parsedData} AVAIL</p>;
      } else {
        return "--";
      }
    },
  },
  {
    key: "threshold",
    label: "Threshold",
  },
  {
    key: "signers",
    label: "Signers",
    render: (item) => {
      const parsedData = deepParseJson(item?.signers);
      return parsedData?.map((item, index) => (
        <div className="copy-account">
          <p key={index}>{truncateMiddle(item, 16)}</p> <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item)} />
        </div>
      ));
    },
  },
  { key: "call_data", label: "Call Data", type: "address", truncate: true },
  { key: "comment", label: "Comments", render: (item) => (Array.isArray(checkJsonFormat(item?.comment)) ? <p>Proxied Address: {checkJsonFormat(item?.comment)[0]}</p> : item?.comment) },
  { key: "status", label: "Status", render: (item) => <span className={`badge badge-light-${item?.status?.toLowerCase() === "completed" ? "success" : item?.status?.toLowerCase() === "pending" ? "warning" : "danger"}`}>{handleCapitalizeMultiple(item?.status)}</span> },
];

export const ExchangeBalancesTableColumnData = [
  {
    key: "address",
    label: "Address",
    type: "address",
    useTooltip: (item) => item.label,
    tooltipContent: "address",
    tooltipColor: "success",
    truncate: true,
  },

  {
    key: "address",
    label: "Address",
    render: (item) =>
      item?.address?.split(",")?.length > 1 ? (
        item?.address?.split(",")?.map((addr) => (
          <p>
            {truncateMiddle(addr, 16)} <i className="fa-regular fa-copy" onClick={() => handleCopyCode(addr)} />
          </p>
        ))
      ) : (
        <p>
          {truncateMiddle(item?.address, 16)} <i className="fa-regular fa-copy" onClick={() => handleCopyCode(item?.address)} />
        </p>
      ),
  },

  {
    key: "created_at",
    label: "Created At",
    type: "date",
  },
];

export const ExchangeBalancesHistoryTableColumnData = (balanceComparison) => {
  return [
    {
      key: "exchange_account.label",
      label: "Label",
    },
    {
      key: "amountTotalRounded",
      label: "Rounded Amount",
      render: (item) => formatNumber(item?.amountTotalRounded),
    },
    {
      key: "amountTotalRounded",
      label: "Rounded Amount",
      render: (item) => formatNumber(item?.amountTotalRounded),
    },

    {
      key: "amountTotalRounded",
      label: "24H Change",
      render: (item) => {
        if (!item?.yesterdayData?.totalAmount || !item?.amountTotalRounded) {
          return "--";
        }

        const change = calculatePercentChange(item?.yesterdayData?.totalAmount, item?.amountTotalRounded);
        const amount = formatAmount(change.absoluteChange);
        const numericAmount = Number(amount?.split(",")?.join(""));
        return (
          <p>
            {amount} {numericAmount > 0 ? <i className="fa-solid fa-arrow-up ms-1" style={{ color: "#28a745" }}></i> : numericAmount < 0 ? <i className="fa-solid fa-arrow-down ms-1" style={{ color: "#c41212" }}></i> : null}
          </p>
        );
      },
    },

    {
      key: "amountTotalRounded",
      label: "24H % Change",
      render: (item) => {
        if (!item?.yesterdayData?.totalAmount || !item?.amountTotalRounded) {
          return "--";
        }

        const change = calculatePercentChange(item?.yesterdayData?.totalAmount, item?.amountTotalRounded);
        const amount = formatAmount(change.percentageChange);
        const numericAmount = Number(amount?.split(",")?.join(""));

        return (
          <p>
            {`${amount} %`} {numericAmount > 0 ? <i className="fa-solid fa-arrow-up ms-1" style={{ color: "#28a745" }}></i> : numericAmount < 0 ? <i className="fa-solid fa-arrow-down ms-1" style={{ color: "#c41212" }}></i> : null}
          </p>
        );
      },
    },
  ];
};
