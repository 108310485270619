import React, { useRef, useState } from "react";
import { Button, Col, FormGroup } from "reactstrap";

const CsvDataValidator = ({ tableData }) => {
  const [csvText, setCsvText] = useState("");
  const [errors, setErrors] = useState([]);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  const preprocessCSVText = (text) => {
    return text
      .split("\n")
      .map(
        (line) =>
          line
            .trim()
            .replace(/[\s,]+/g, ",") // Normalize separators to commas
            .replace(/,+/g, ",") // Remove multiple commas
      )
      .join("\n");
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const processedText = preprocessCSVText(event.target.result);
      setCsvText(processedText);
      validateCSV(processedText);
    };
    reader.readAsText(file);
  };

  const validateCSV = (csvContent) => {
    if (!tableData || !tableData.length) {
      setErrors(["No transaction data available for validation"]);
      return;
    }
    const processedContent = preprocessCSVText(csvContent);
    const csvRows = processedContent.split("\n").filter((row) => row.trim() !== "");
    const csvData = csvRows.map((row, index) => {
      const [address, amount] = row.split(",").map((item) => item.trim());
      return { address, amount: Number(amount), row: index + 1 };
    });

    const validationErrors = [];
    const csvMap = new Map();
    const tableMap = new Map();

    // Process table data
    tableData.forEach((item) => {
      const rawAmount = item.value?.toString().replace(/,/g, "") || "0";
      const amount = Number(rawAmount) / Math.pow(10, 18);
      tableMap.set(item.id, amount);
    });

    // Process CSV data
    csvData.forEach(({ address, amount, row }) => {
      if (isNaN(amount)) {
        validationErrors.push(`Row ${row}: Invalid amount format`);
        return;
      }

      if (csvMap.has(address)) {
        validationErrors.push(`Row ${row}: Duplicate address ${address}`);
      } else {
        csvMap.set(address, { amount, row });
      }
    });

    // Validate addresses and amounts
    const allAddresses = new Set([...tableMap.keys(), ...csvMap.keys()]);

    allAddresses.forEach((address) => {
      if (!tableMap.has(address)) {
        validationErrors.push(`Address ${address} not found in transaction data`);
      } else if (!csvMap.has(address)) {
        validationErrors.push(`Address ${address} missing in CSV`);
      } else {
        const csvAmount = csvMap.get(address).amount;
        const tableAmount = tableMap.get(address);
        if (csvAmount !== tableAmount) {
          validationErrors.push(`Row ${csvMap.get(address).row}: Amount mismatch for ${address} ` + `(Expected: ${tableAmount}, Found: ${csvAmount})`);
        }
      }
    });

    setErrors(validationErrors);
  };

  // const handleChange = (value) => {
  //   const processedValue = preprocessCSVText(value);
  //   setCsvText(processedValue);
  //   if (processedValue.trim()) {
  //     validateCSV(processedValue);
  //   } else {
  //     setErrors([]);
  //   }
  // };

  const handleChange = (value) => {
    const textarea = textareaRef.current;
    if (!textarea) return;
  
    const cursorPosition = textarea.selectionStart; // Save cursor position before update
  
    const processedValue = preprocessCSVText(value);
    setCsvText(processedValue);
  
    // Validate only if there's content
    if (processedValue.trim()) {
      validateCSV(processedValue);
    } else {
      setErrors([]);
    }
  
    // Restore cursor position after state update
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = cursorPosition;
    }, 0);
  };
  

  const handleClear = () => {
    setCsvText("");
    setErrors([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear selected file
    }
  };

  return (
    <>
      <Col sm="5" className="mb-3">
        <FormGroup className="form-group">
          <label className="mb-2 form-label">Upload CSV (To Verify Data):</label>
          <input ref={fileInputRef} type="file" accept=".csv" className="form-control" onChange={handleFileUpload} />
        </FormGroup>
      </Col>

      <Col sm="7">
        <FormGroup>
          <label className="mb-2 form-label">Or Paste CSV</label>
          <div className="position-relative">
            <textarea  ref={textareaRef} className={`form-control ${errors.length > 0 ? "is-invalid" : csvText && !errors?.length ? "is-valid" : ""}`} value={csvText} onChange={(e) => handleChange(e.target.value)} rows={5} placeholder={`5CAtvNu1nBBk3umGuiSExp5W3pswWvuGtYtXZjXzYDKQBozV,1\n...`} />
          </div>
          <Button color="danger" onClick={handleClear} size="sm" className="mt-2">
            Clear
          </Button>
        </FormGroup>

        {errors.length > 0 && (
          <div className="text-danger mt-2">
            <div>{errors[0]}</div>
          </div>
        )}
      </Col>
    </>
  );
};

export default CsvDataValidator;
