import React from "react";
import { Card, Col, Row } from "reactstrap";
import BaseHolderStats from "./BaseHolderStats";
import DexVolumeAndHolderStats from "./DexVolumeAndHolder";
import DexVolumeStats from "./DexVolumeStats";
import ETHHolderStats from "./ETHHolderStats";
import PriceStats from "./PriceStats";
import StBaseHolderStats from "./STBaseHolderStats";
import StETHHolderStats from "./STETHHolderStats";
import TotalHoldersAndVolumeStats from "./TotalHolderAndVolume";
import VolumeStats from "./VolumeStats";
import HolderStats from "./HolderStats";
import { formatDateTime } from "../../../Utils/CustomFunctions";

const AvailInfoCharts = ({ data, updatedDatasets }) => {
  const dateSeries = data?.data?.map((item) => formatDateTime(item.date, true)) ||[];
  return (
    <>
      <Row>
        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <PriceStats dateSeries={dateSeries} data={data?.data} />
            </Row>
          </Card>
        </Col>

        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <VolumeStats dateSeries={dateSeries} data={data?.data} />
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <DexVolumeStats dateSeries={dateSeries} data={data?.data} />
            </Row>
          </Card>
        </Col>
        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <DexVolumeAndHolderStats dateSeries={dateSeries} data={data?.data} />
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <ETHHolderStats dateSeries={dateSeries} data={data?.data} mainData={updatedDatasets} />
            </Row>
          </Card>
        </Col>

        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <BaseHolderStats dateSeries={dateSeries} data={data?.data} mainData={updatedDatasets} />
            </Row>
          </Card>
        </Col>
      </Row>
      <Card>
        <Row className="g-4 treasury-stats">
          <TotalHoldersAndVolumeStats dateSeries={dateSeries} data={data?.data} mainData={updatedDatasets} />
        </Row>
      </Card>
      <Row>
        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <StBaseHolderStats dateSeries={dateSeries} data={data?.data} />
            </Row>
          </Card>
        </Col>

        <Col sm="6">
          <Card>
            <Row className="g-4 treasury-stats">
              <StETHHolderStats dateSeries={dateSeries} data={data?.data} mainData={updatedDatasets} />
            </Row>
          </Card>
        </Col>
      </Row>
      <Card>
        <Row className="g-4 treasury-stats">
          <HolderStats dateSeries={dateSeries} data={data?.data} mainData={updatedDatasets} />
        </Row>
      </Card>
      
    </>
  );
};

export default AvailInfoCharts;
