import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createOrUpdateBudgetApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import useNotifications from "../../../Api/useNotifications";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useBudget } from "../../../Context/BudgetContext";
import useOutsideClick from "../../../Hooks/useOutsideClick";

const BankPaymentModal = ({ refetchBudget, isEditMode }) => {
  const { refetch, editModal, editBudgetData, toggleEdit } = useBudget();

  const { mutate } = usePerformMutation(
    () => {
      refetch();
      reset();
      toggleEdit();
      setTimeout(() => {
        refetchGetData();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );
  const [loading, setLoading] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const datePickerRef = useRef(null);
  const { refetchGetData } = useNotifications();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "bank_payments",
      id: null,
      date: null,
      type_of_transfer: null,
      from: null,
      to: null,
      bank_details: null,
      confirmation: null,
      expense_category: null,
      type_of_expense: null,
      currency: null,
      amount: null,
      amountUSD: null,
      status: null,
    },
  });

  const handleBudget = async (data) => {
    setLoading(true);
    // const apiEndpoint = isEditMode ? updateBudgetApi : createBudgetApi;
    await mutate({ url: createOrUpdateBudgetApi, data: data });
    setLoading(false);
  };

  const handleDateChange = (date) => {
    setValue("date", date);
    setDatePickerVisible(false);
  };

  const toggleDatePicker = () => {
    setDatePickerVisible((prev) => !prev);
  };

  useOutsideClick(datePickerRef, () => setDatePickerVisible(false));

  useEffect(() => {
    if (isEditMode && editBudgetData) {
      setValue("date", editBudgetData.date ? new Date(editBudgetData.date) : null);
      setValue("id", editBudgetData.id ? editBudgetData.id : "");
      setValue("type_of_transfer", editBudgetData.type_of_transfer);
      setValue("from", editBudgetData.from);
      setValue("to", editBudgetData.to);
      setValue("bank_details", editBudgetData.bank_details);
      setValue("confirmation", editBudgetData.confirmation);
      setValue("expense_category", editBudgetData.expense_category);
      setValue("type_of_expense", editBudgetData.type_of_expense);
      setValue("currency", editBudgetData.currency);
      setValue("amount", editBudgetData.amount);
      setValue("amountUSD", editBudgetData.amountUSD);
      setValue("status", editBudgetData.status);
    } else {
      reset();
    }
  }, [isEditMode, editBudgetData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal budget-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Bank Payment " : "Create Bank Payment"} </ModalHeader>
      <Form onSubmit={handleSubmit(handleBudget)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Invoice Date:</label>
                <div className="calander-icon" ref={datePickerRef}>
                  <DatePicker
                    showYearDropdown
                    selected={watch("date")}
                    className={`form-control ${errors.date ? "is-invalid" : ""}`}
                    placeholderText="Select date"
                    onChange={handleDateChange}
                    open={datePickerVisible} // Control visibility
                  />
                  <i className="fa-solid fa-calendar-days" onClick={toggleDatePicker} />
                </div>
                {errors.date && <div className="invalid-feedback">{errors.date.message}</div>}
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormInput name={"type_of_transfer"} label={"Type of Transfer:"} errors={errors} register={register} placeholder="Enter transfer type" />
            </Col>

            <Col sm="6">
              <FormInput name={"from"} label={"From:"} errors={errors} register={register} placeholder="Enter from address" validationRules={{ required: "From field is required" }} />
            </Col>

            <Col sm="6">
              <FormInput name={"to"} label={"To:"} errors={errors} register={register} placeholder="Enter to address" validationRules={{ required: "To field is required" }} />
            </Col>

            <Col sm="6">
              <FormInput name={"bank_details"} label={"Bank Details:"} errors={errors} register={register} placeholder="Enter bank details" />
            </Col>

            <Col sm="6">
              <FormInput name={"confirmation"} label={"Confirmation (TXN):"} errors={errors} register={register} placeholder="Enter confirmation" />
            </Col>

            <Col sm="6">
              <FormInput name={"expense_category"} label={"Expense Category:"} errors={errors} register={register} placeholder="Enter expense category" />
            </Col>

            <Col sm="6">
              <FormInput name={"currency"} label={"Currency:"} errors={errors} register={register} placeholder="Enter currency" validationRules={{ required: "Currency is required" }} />
            </Col>

            <Col sm="6">
              <FormInput name={"amount"} label={"Amount:"} errors={errors} register={register} type="number" placeholder="Enter amount" validationRules={{ required: "Amount is required" }} />
            </Col>

            <Col sm="6">
              <FormInput name={"amountUSD"} label={"Amount (in USD):"} errors={errors} register={register} type="number" placeholder="Enter amount" validationRules={{ required: "Amount USD is required" }} />
            </Col>

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Type of Expense:</label>
                <select {...register("type_of_expense")} className={`form-control search-users ${errors.type_of_expense ? "is-invalid" : ""}`}>
                  <option value="">Select an option</option>
                  <option value="Recurring">Recurring</option>
                  <option value="One-time payment">One-time payment</option>
                </select>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Status:</label>
                <select {...register("status", { required: "Status is required" })} className={`form-control search-users ${errors.status ? "is-invalid" : ""}`}>
                  <option value="">Select status</option>
                  <option value="Paid">Paid</option>
                  <option value="Pending">Pending</option>
                </select>
                {errors.status && <div className="invalid-feedback">{errors.status.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button type="submit" color="" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default BankPaymentModal;
