import { isAddress } from "@polkadot/util-crypto";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createMultisigApi, updateMultisigApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import { truncateMiddle } from "../../../Utils/CustomFunctions";
import { generateMultisigAddress } from "../../Common/AvailCommon";

const AddMultisigModal = ({ isEditMode, refetchMultisig, setEditMode }) => {
  const { editModal, editMultisigData, toggleEdit } = useMultisig();
  const { accounts } = useWallet();
  const [availableSignatories, setAvailableSignatories] = useState(accounts || []);
  const [selectedSignatories, setSelectedSignatories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [generatedAddress, setGeneratedAddress] = useState("");
  const isWalletConnected = localStorage.getItem("isConnectedToWallet");

  const { mutate } = usePerformMutation(
    () => {
      reset();
      toggleEdit();
      setEditMode(false);
      setTimeout(() => {
        refetchMultisig();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: null,
      threshold: null,
      ms_name: null,
      ms_address: null,
      members: [],
      manual_address: "",
      manual_name: "",
    },
  });
  const threshold = watch("threshold");
  const members = watch("members");

  const handleMultisig = async (data) => {
    setLoading(true);
    await mutate({ url: isEditMode ? updateMultisigApi : createMultisigApi, data, isUpdate: isEditMode || false });
    setLoading(false);
  };

  const generateMultisig = async (data) => {
    try {
      const generatedMultisigAddress = generateMultisigAddress(
        data?.threshold,
        data.members?.map((member) => member.address)
      );
      setGeneratedAddress(generatedMultisigAddress);
      setValue("ms_address", generatedMultisigAddress);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleMultisigSubmit = (data) => {
    if (!generatedAddress) {
      generateMultisig(data); // Generate multisig address first
    } else {
      handleMultisig(data); // Call handleMultisig once the address is generated
    }
  };

  const filteredSignatories = availableSignatories.filter((signatory) => signatory?.meta?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) || signatory.address.includes(searchQuery));

  const handleSelectSignatory = (signatory) => {
    setAvailableSignatories((prev) => prev.filter((item) => item !== signatory));
    setSelectedSignatories((prev) => {
      const updated = [...prev, signatory];
      const addresses = updated.map((item) => item); // Extract only the addresses
      setValue("members", addresses, { shouldValidate: true }); // Update form state with addresses
      return updated;
    });
  };

  const handleAddManualSignatory = () => {
    const manualAddress = watch("manual_address");
    const manualName = watch("manual_name");

    if (!manualAddress || !isAddress(manualAddress)) {
      toast.error("Invalid address! Please enter a valid address.");
      return;
    }

    // Check if the address already exists in availableSignatories
    if (availableSignatories.some((signatory) => signatory.address === manualAddress)) {
      toast.error("Address already exists in available signatories!");
      return;
    }

    // Check if the address is already selected
    if (selectedSignatories.some((signatory) => signatory.address === manualAddress)) {
      toast.error("Address is already selected!");
      return;
    }

    // Create a new signatory with "Unknown" name
    const newSignatory = { address: manualAddress, meta: { name: manualName || "Unknown" } };

    setSelectedSignatories((prev) => {
      const updated = [...prev, newSignatory];
      setValue("members", updated, { shouldValidate: true });
      return updated;
    });

    setValue("manual_address", ""); // Clear input after adding
    setValue("manual_name", ""); // Clear input after adding
  };

  const handleDeselectSignatory = (signatory) => {
    setSelectedSignatories((prev) => {
      const updated = prev.filter((item) => item.address !== signatory.address);
      setValue("members", updated, { shouldValidate: true });
      return updated;
    });

    setAvailableSignatories((prev) => [...prev, signatory]);
  };
  const handleReset = () => {
    reset();
    setGeneratedAddress("");
    setSelectedSignatories([]);
    setAvailableSignatories(accounts || []);
  };

  const closeModal = () => {
    toggleEdit();
    setEditMode(false);
  };

  useEffect(() => {
    if (isEditMode && editMultisigData) {
      try {
        // const members = JSON.parse(editMultisigData?.members || "[]");
        const members = Array.isArray(editMultisigData?.members) ? editMultisigData?.members : JSON.parse(editMultisigData?.members || "[]");
        const selectedMembers = Array.isArray(members)
          ? members.map((member) => {
              const signatory = availableSignatories.find((item) => item.address === member?.address);
              return signatory || { address: member?.address, name: "Unknown" };
            })
          : [];

        setSelectedSignatories(selectedMembers);
        setAvailableSignatories(accounts.filter((signatory) => !members.some((member) => member.address === signatory.address)));
        setGeneratedAddress(editMultisigData?.ms_address);
        setValue("threshold", editMultisigData.threshold);
        setValue("id", editMultisigData.id);
        setValue("ms_name", editMultisigData.ms_name);
        setValue("ms_address", editMultisigData.ms_address);
        setValue("members", members);
      } catch (error) {
        console.error("Failed to parse members:", error);
      }
    } else {
      setSelectedSignatories([]);
      setAvailableSignatories(accounts);
      reset();
    }
  }, [isEditMode, editMultisigData, setValue, reset, accounts]);

  useEffect(() => {
    if (threshold > 1) {
      register("members", {
        validate: (value) => {
          if (!Array.isArray(value) || value.length === 0) {
            return "At least one signatory must be selected";
          }
          if (value.length < threshold) {
            return "Selected signatories must be greater than or equal to threshold";
          }
          return true; // Validation passed
        },
      });
    }

    // Force validation check when threshold updates
    trigger("members");
  }, [register, threshold, trigger]);

  useEffect(() => {
    // Reset generated address if any relevant fields change
    if (generatedAddress && !isEditMode) {
      setGeneratedAddress("");
      setValue("ms_address", "");
    }
  }, [threshold, members, isEditMode, setValue]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={closeModal} className="transaction-modal budget-modal multising-budget-model">
      <ModalHeader toggle={closeModal}>{isEditMode ? "Edit Multisig" : "Add Multisig"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleMultisigSubmit)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            {generatedAddress && (
              <Col sm="12">
                <FormInput label="Address:" name={"ms_address"} errors={errors} placeholder="Enter avail address" register={register} validationRules={{ ...register("ms_address", { required: generatedAddress ? "Address is required" : false }) }} readOnly />
              </Col>
            )}
            <Col sm="6">
              <FormInput label="* Name:" name={"ms_name"} helperText={"The name is for unique identification of the account in your owner lists."} errors={errors} placeholder="Enter multisig name" register={register} validationRules={{ required: "Multisig Name is required" }} />
            </Col>

            <Col sm="6">
              <FormInput
                label="* Threshold:"
                name={"threshold"}
                helperText={"The threshold for approval should be less or equal to the number of signatories for this multisig."}
                errors={errors}
                placeholder="Enter threshold"
                register={register}
                validationRules={{
                  required: "Threshold is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Threshold must be a number",
                  },
                  validate: (value) => {
                    if (value < 2) {
                      return "Threshold should be more than 1";
                    }
                  },
                }}
                readOnly={isEditMode ? true : false}
              />
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <input onChange={(e) => setSearchQuery(e.target.value)} className="form-control" type="text" placeholder={`Filter by name or address`} readOnly={isEditMode ? true : false} />
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Add Address Manually:</label>
                <div className="d-flex">
                  <input
                    {...register("manual_address", {
                      validate: async (value) => {
                        if (value && !isAddress(value)) {
                          return `Address is not valid`;
                        }
                        return true;
                      },
                    })}
                    className={`form-control`}
                    type="text"
                    placeholder="Enter manual address"
                  />
                  <input {...register("manual_name")} className={`form-control`} type="text" placeholder="Enter name for manual address" />
                  <Button color="success" onClick={handleAddManualSignatory}>
                    Add
                  </Button>
                </div>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Available Signatories:</label>
                <div className="form-control custom-textarea custom-scrollbar">
                  {isWalletConnected
                    ? filteredSignatories.length
                      ? filteredSignatories.map((item, index) => (
                          <div
                            key={index}
                            className={`signatory-item ${isEditMode ? "disabled" : ""}`}
                            onClick={() => !isEditMode && handleSelectSignatory(item)} // Prevent clicking if isEditMode is true
                            style={{ cursor: isEditMode ? "not-allowed" : "pointer" }} // Change cursor when disabled
                          >
                            {`${item?.meta?.name?.toUpperCase()} `}
                            {`(${truncateMiddle(item?.address, 14)}) `}
                          </div>
                        ))
                      : "No Signatories Available"
                    : "Please ensure wallet is connected"}
                </div>
                <p className="helper-text">
                  The signatories have the ability to create transactions using the multisig and approve transactions sent by others. Once the threshold is reached with approvals, the multisig transaction is enacted on-chain. <br /> Click on a signatory to select it.
                </p>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Selected Signatories:</label>
                <div className={`${errors.members ? "is-invalid" : ""} form-control custom-textarea custom-scrollbar`}>
                  {selectedSignatories.map((item, index) => (
                    <div
                      key={index}
                      className={`signatory-item ${isEditMode ? "disabled" : ""}`}
                      onClick={() => !isEditMode && handleDeselectSignatory(item)} // Prevent clicking if isEditMode is true
                      style={{ cursor: isEditMode ? "not-allowed" : "pointer" }} // Change cursor when disabled
                    >
                      {`${item?.meta?.name?.toUpperCase()}  `}
                      {`(${truncateMiddle(item?.address, 14)})`}
                    </div>
                  ))}
                </div>
                <p className="helper-text">Click on a selected signatory to remove it. It will move back to the available list.</p>
                {errors.members && <div className="invalid-feedback d-block">{errors.members.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          {generatedAddress && !isEditMode && (
            <Button type="button" color="danger" onClick={handleReset}>
              Reset
            </Button>
          )}
          <Button type="submit" color="" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : generatedAddress ? "Add" : "Generate Multisig"} {loading && <i className="fa-solid fa-circle-notch fa-spin" />}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddMultisigModal;
