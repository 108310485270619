import React, { createContext, useContext, useState } from "react";

const MultisigContext = createContext();

export const MultisigProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const [transactionUpdates, setTransactionUpdates] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentModal, setCurrentModal] = useState("connect");
  const [editMultisigData, setEditMultisigData] = useState({});
  const [multisigData, setMultisigData] = useState({});
  const [totalMultisigPages, setTotalMultisigPages] = useState();
  const [multisigPage, setMultisigPage] = useState(1);
  const [multisigPagelimit, setMultisigPageLimit] = useState(25);
  const [searchMultisig, setSearchMultisig] = useState("");
  const [multisigLoading, setMultisigLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [transactionLoading,setTransactionLoading] = useState(false);

  return <MultisigContext.Provider value={{transactionLoading,setTransactionLoading, editMode, setEditMode, setMultisigLoading, multisigLoading, multisigPagelimit, setMultisigPageLimit, searchMultisig, setSearchMultisig, totalMultisigPages, setTotalMultisigPages, multisigPage, setMultisigPage, multisigData, setMultisigData,  transactionUpdates, setTransactionUpdates, toggleDelete, deleteModal, setEditMultisigData, editMultisigData, setCurrentModal, currentModal, editModal, setEditModal, toggleEdit, searchTerm, setSearchTerm }}>{children}</MultisigContext.Provider>;
};
export const useMultisig = () => {
  return useContext(MultisigContext);
};
