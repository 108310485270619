import Papa from "papaparse";
import toast from "react-hot-toast";
import { toMilliseconds } from "../../Utils/CustomFunctions";

export const handleRefresh = (localKey, refreshData, refetchGetData, setIsRefreshDisabled, REFRESH_INTERVAL, setTooltipContent, calculateRemainingTime, setRemainingTime) => {
  const now = new Date().getTime();

  // Set the tooltip content to "Refreshing..." immediately
  setTooltipContent("Refreshing...");

  // Store the refresh time in local storage
  localStorage.setItem(localKey, now);

  // Perform the refresh (refetch the data)
  try {
    refreshData().then((response) => {
      if (response && response?.data?.message) {
        toast.success(response.data?.message);
      }
      setTimeout(() => {
        refetchGetData();
      }, 1000);
    });
  } catch (err) {
    return toast.error("Failed to refresh data");
  }

  // Disable the refresh button
  setIsRefreshDisabled(true);

  // Set the interval to update the tooltip with countdown
  const intervalId = setInterval(() => {
    const remainingTime = calculateRemainingTime(now);
    if (remainingTime) {
      setTooltipContent("Refresh available in");
      setRemainingTime(remainingTime);
    } else {
      setIsRefreshDisabled(false);
      setTooltipContent("Refresh");
      clearInterval(intervalId);
    }
  }, 1000);
};

// Change Page Data Limit
export const handleLimitChange = (e, setLimit, setPage) => {
  setLimit(Number(e.target.value));
  setPage(1);
};

const REFRESH_INTERVAL = toMilliseconds(1, 0, 0);

export const calculateRemainingTime = (lastRefreshTime) => {
  const now = new Date().getTime();
  const timeSinceLastRefresh = now - lastRefreshTime;
  const remainingTime = REFRESH_INTERVAL - timeSinceLastRefresh;

  if (remainingTime > 0) {
    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  }
  return null;
};

export const exportToCSV = (data, fileName) => {
  if (data && data.data && data.data.length > 0) {
    const csv = Papa.unparse(data.data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `${fileName || "test"}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    toast.error("No data to export.");
  }
};

export const extractLinkMenus = (menuList) => {
  const linkMenus = [];

  const traverseMenus = (menus, parentIcon = null) => {
    menus.forEach((menu) => {
      const menuIcon = menu.icon || parentIcon;
      if (menu.type === "link") {
        linkMenus.push({ ...menu, icon: menuIcon });
      }
      if (menu.children) {
        traverseMenus(menu.children, menuIcon);
      }
      if (menu.mainMenus) {
        traverseMenus(menu.mainMenus, menuIcon);
      }
    });
  };

  traverseMenus(menuList);
  return linkMenus;
};

export const checkJsonFormat = (item) => {
  if (!item) return {}; // Handle null/undefined cases safely
  if (typeof item === "string") {
    try {
      return JSON.parse(item);
    } catch (error) {
      console.error("Invalid JSON format:", error);
      return {};
    }
  }
  return item; // If already an object, return as is
};

export const deepParseJson = (data) => {
  if (!data) return {}; // Handle null/undefined cases safely

  if (typeof data === "string") {
    try {
      const parsedData = JSON.parse(data);
      return deepParseJson(parsedData); // Recursively parse if nested JSON
    } catch (error) {
      console.error("Invalid JSON format:", data, error);
      return data; // Return original string if parsing fails
    }
  } else if (Array.isArray(data)) {
    return data.map(deepParseJson); // Recursively parse each item in array
  } else if (typeof data === "object") {
    return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, deepParseJson(value)])); // Recursively parse each property in object
  }
  return data; // Return as is if it's neither string, array, nor object
};
