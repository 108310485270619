import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createOrUpdateBudgetApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import useNotifications from "../../../Api/useNotifications";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useBudget } from "../../../Context/BudgetContext";
import useOutsideClick from "../../../Hooks/useOutsideClick";

const CreditCardPaymentsModal = ({ refetchBudget, isEditMode }) => {
  const { refetch, editModal, editBudgetData, toggleEdit } = useBudget();

  const { mutate } = usePerformMutation(
    () => {
      refetch();
      reset();
      toggleEdit();
      setTimeout(() => {
        refetchGetData();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );
  const [loading, setLoading] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const datePickerRef = useRef(null);
  const { refetchGetData } = useNotifications();

  useOutsideClick(datePickerRef, () => setDatePickerVisible(false));

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "credit_card_payments",
      id: null,
      payment_description: null,
      payment_date: null,
      payment_source: null,
      currency: null,
      amount: null,
      expense_category: null,
      expense_type: null,
      amountUSD: null,
      status: null,
    },
  });

  const handleBudget = async (data) => {
    setLoading(true);
    await mutate({ url: createOrUpdateBudgetApi, data: data });
    setLoading(false);
  };

  const handleDateChange = (date) => {
    setValue("payment_date", date, { v: true });
    setDatePickerVisible(false);
  };

  const toggleDatePicker = () => {
    setDatePickerVisible((prev) => !prev);
  };

  useEffect(() => {
    if (isEditMode && editBudgetData) {
      setValue("id", editBudgetData.id);
      setValue("payment_description", editBudgetData.payment_description);
      setValue("payment_date", editBudgetData.payment_date ? new Date(editBudgetData.payment_date) : null);
      setValue("payment_source", editBudgetData.payment_source);
      setValue("currency", editBudgetData.currency);
      setValue("amount", editBudgetData.amount);
      setValue("expense_category", editBudgetData.expense_category);
      setValue("expense_type", editBudgetData.expense_type);
      setValue("amountUSD", editBudgetData.amountUSD);
      setValue("status", editBudgetData.status);
    } else {
      reset();
    }
  }, [isEditMode, editBudgetData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal budget-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Credit Card Payment" : "Create Credit Card Payment"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleBudget)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            {/* Payment Description */}
            <Col sm="12">
              <FormInput name={"payment_description"} label={"Payment Description:"} errors={errors} register={register} validationRules={{ required: "Payment description is required" }} placeholder="Enter payment description" />
            </Col>
            {/* Payment Date */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Payment Date:</label>
                <div className="calander-icon" ref={datePickerRef}>
                  <DatePicker selected={watch("payment_date")} className="form-control" placeholderText="Select date" onChange={handleDateChange} open={datePickerVisible} showYearDropdown />
                  <i className="fa-solid fa-calendar-days" onClick={toggleDatePicker} />
                </div>
              </FormGroup>
              {errors.payment_date && <div className="invalid-feedback">{errors.payment_date.message}</div>}
            </Col>
            {/* Payment Source */}
            <Col sm="6">
              <FormInput name={"payment_source"} label={"Payment Source:"} errors={errors} register={register} placeholder="Enter payment source" />
            </Col>
            {/* Currency */}
            <Col sm="6">
              <FormInput name={"currency"} label={"Currency:"} errors={errors} register={register} placeholder="Enter currency" validationRules={{ required: "Currency is required" }} />
            </Col>
            {/* Expense Category */}
            <Col sm="6">
              <FormInput name={"expense_category"} label={"Expense Category:"} errors={errors} register={register} placeholder="Enter expense category" />
            </Col>
            {/* Amount */}
            <Col sm="6">
              <FormInput name={"amount"} label={"Amount:"} type="number" errors={errors} register={register} placeholder="Enter amount" validationRules={{ required: "Amount is required" }} />
            </Col>
            {/* Amount in USD */}
            <Col sm="6">
              <FormInput name={"amountUSD"} label={"Amount (in USD):"} type="number" errors={errors} register={register} placeholder="Enter amount in USD" />
            </Col>
            {/* Type of Expense */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Type of Expense:</label>
                <select {...register("expense_type")} className={`form-control search-users ${errors.expense_type ? "is-invalid" : ""}`}>
                  <option value="">Select an option</option>
                  <option value="Recurring">Recurring</option>
                  <option value="One-time payment">One-time payment</option>
                </select>
              </FormGroup>
            </Col>
            {/* Status */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Status:</label>
                <select {...register("status", { required: "Status is required" })} className={`form-control search-users ${errors.status ? "is-invalid" : ""}`}>
                  <option value="">Select status</option>
                  <option value="Paid">Paid</option>
                  <option value="Pending">Pending</option>
                </select>
                {errors.status && <div className="invalid-feedback">{errors.status.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button color="" type="submit" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreditCardPaymentsModal;
