import React from "react";
import { FormGroup } from "reactstrap";

const FormInput = ({ label, name, placeholder, helperText, type = "text", register, errors, validationRules = {}, readOnly = false, classes = {} }) => {
  return (
    <FormGroup className="form-group">
      {label && <label className="mb-2 form-label">{label}</label>}
      <input {...register(name, validationRules)} className={`form-control ${classes?.inputClass ? classes?.inputClass : ""} ${errors[name] ? "is-invalid" : ""}`} type={type} placeholder={placeholder} readOnly={readOnly} />
      {helperText && <p className="helper-text">{helperText}</p>}
      {errors[name] && <div className="invalid-feedback">{errors[name].message}</div>}
    </FormGroup>
  );
};

export default FormInput;
