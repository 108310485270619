import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useNotifications from "../Api/useNotifications";
import { ImagePath } from "../Constants";

const HeaderNotifications = () => {
  const { notificationsData, refetchGetData } = useNotifications();

  useEffect(() => {
    refetchGetData();
  }, []);

  const filteredNotifications = notificationsData?.data?.filter((notification) => !notification?.mark_as_read && notification?.status !== "completed");

  const filteredNotificationData = notificationsData?.data?.filter((notification) => notification?.status !== "completed");

  return (
    <li>
      {filteredNotifications?.length ? <span className="badge badge-danger">{filteredNotifications?.length}</span> : null}
      <div className="light-box">
        <i className="fa-solid fa-bell"/>
      </div>
      <ul className={`notify-dropdown ${!notificationsData?.data?.length ? "no-notify" : ""}`}>
        <li className="notify-head">
          <i className="fa-solid fa-bell"/>
          <h6 className="mb-0">Notifications</h6>
        </li>
        <div className="notification-detail custom-scrollbar">
          {filteredNotificationData?.length ? (
            filteredNotificationData?.slice(0, 4).map((item, i) => (
              <li key={i}>
                <div className="rounded-circle">
                  <i className="fa-solid fa-dollar-sign fa-flip"/>
                </div>
                <div>
                  <p className="title">{item?.title}</p>
                  <span>{item?.description}</span>
                </div>
              </li>
            ))
          ) : (
            <li>
              <div className="empty-notify">
                <img className="img-fluid" src={`${ImagePath}/no-notify.svg`} alt="no notification" />
                <span>There is no new notifications</span>
              </div>
            </li>
          )}
        </div>
        {notificationsData?.data?.length ? (
          <li className="check-notify">
            <Link to="/notifications" className="btn btn-primary">
              Check All Notifications
            </Link>
          </li>
        ) : null}
      </ul>
    </li>
  );
};

export default HeaderNotifications;
