import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getProxiedApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useMultisig } from "../../Context/MultisigContext";
import { useProxied } from "../../Context/ProxiedContext";
import { useWallet } from "../../Context/WalletContext";
import useDebounce from "../../Hooks/useDebounce";
import usePermissionCheck from "../../Hooks/usePermissionCheck";
import { handleCopyCode, truncateMiddle } from "../../Utils/CustomFunctions";
import { checkIfProxied } from "../Common/AvailCommon";
import CommonTooltip from "../Common/CommonTooltip";
import { checkJsonFormat, deepParseJson } from "../Common/CustomFunctions";

const ProxiedAddressCard = ({ filteredProxiedData, setRefetchProxied, balances, toggleDropdown, openDropdownId, handleSend, pendingTransactionCounts, handlePendingTransaction }) => {
  const [multisigAddresses, setMultisigAddresses] = useState({});
  const { api } = useWallet();
  const { setProxiedPage, searchProxied, proxiedData, proxiedPage, setProxiedData, setTotalProxiedPages, proxiedPagelimit, toggleProxiedDelete, setEditProxiedData, isProxiedEditMode, setIsProxiedEditMode, toggleProxiedEdit } = useProxied();
  const { multisigData } = useMultisig();
  const [params, setParams] = useState({});
  const { data: fetchedProxiedData, refetch: refetchProxied, isLoading } = useGetApi({ url: getProxiedApi, params, options: { enabled: !!Object.keys(params)?.length, refetchOnWindowFocus: false } });
  const debouncedSearchProxied = useDebounce(searchProxied?.trim(), 500);
  const isWalletConnected = localStorage.getItem("isConnectedToWallet");
  const [edit, destroy] = usePermissionCheck(["edit", "destroy"], "multisig_admin");

  const handleEdit = (id) => {
    setIsProxiedEditMode(true);
    const singleEditData = filteredProxiedData?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleProxiedEdit();

      setEditProxiedData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = filteredProxiedData?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleProxiedDelete();
      setEditProxiedData(singleEditData);
    }
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page: proxiedPage,
      limit: proxiedPagelimit,
      search: debouncedSearchProxied,
    }));
  }, [debouncedSearchProxied, proxiedPage, proxiedPagelimit]);

  useEffect(() => {
    if (searchProxied?.trim() === "") {
      setProxiedPage(1);
    }
  }, [searchProxied]);

  useEffect(() => {
    if (debouncedSearchProxied?.trim().length > 0) {
      isLoading && refetchProxied();
    }
  }, [debouncedSearchProxied, proxiedPage, proxiedPagelimit, isLoading, refetchProxied]);

  useEffect(() => {
    setProxiedData(fetchedProxiedData);
  }, [fetchedProxiedData]);

  useEffect(() => {
    if (setRefetchProxied) {
      setRefetchProxied(() => refetchProxied);
    }
  }, [refetchProxied, setRefetchProxied]);

  useEffect(() => {
    // Check if `multisigData` and `api` are ready
    if (filteredProxiedData?.length && api) {
      setTotalProxiedPages(proxiedData.totalPages);
    }
  }, [proxiedData, api]);

  useEffect(() => {
    const fetchMultisigAddresses = async () => {
      if (!api) return;

      const addresses = {};

      if (proxiedData?.length && multisigData?.data?.length) {
        for (const account of proxiedData) {
          const isProxied = await checkIfProxied(api, account.proxied_address);
          if (isProxied) {
            const proxiedAddress = account.proxied_address;
            const multisigInfo = multisigData?.data?.find((item) => item?.ms_address === isProxied?.delegate);
            addresses[proxiedAddress] = {
              proxiedAddress: proxiedAddress || "N/A",
              threshold: multisigInfo?.threshold || "N/A",
              members: multisigInfo ? checkJsonFormat(multisigInfo.members) : [],
              multisigName: multisigInfo?.ms_name || "N/A",
              multisigAddress: multisigInfo?.ms_address || "N/A",
            };
          }
        }
        setMultisigAddresses(addresses);
      }
    };

    fetchMultisigAddresses();
  }, [proxiedData, api, multisigData]);

  return (
    <Card className="mb-2">
      <CardHeader>
        <h6 className=" custom-chain-heading badge-light-success ">
          Proxied<span>on-chain proxied accounts</span>{" "}
        </h6>
      </CardHeader>
      <CardBody>
        <Row>
          {filteredProxiedData?.length ? (
            filteredProxiedData?.map((item, i) => (
              <Col xl="6" key={i}>
                <Card className="custom-multisig badge-light-success">
                  <CardBody>
                    <div className="multisig"></div>
                    <div className="w-full d-flex justify-content-between">
                      <div className="multisig-row">
                        <span className="multisig-title">{item?.proxied_name?.toUpperCase()}</span>
                        <div className="d-flex">
                          <span className="shortAddress me-1">{truncateMiddle(item?.proxied_address, 18)}</span>
                          <i className="fa-regular fa-copy" style={{ cursor: "pointer", color: "#0094D5" }} onClick={() => handleCopyCode(item?.proxied_address)} />
                        </div>
                      </div>
                      <div className="d-flex">
                        {pendingTransactionCounts && pendingTransactionCounts[item?.proxied_address]?.length ? (
                          <div className="position-relative">
                            <Button id="pending-transactions" onClick={() => handlePendingTransaction({ ...item, ...deepParseJson(item?.multisig_details) })} size="sm" color="warning">
                              <i className="fa-regular fa-clock fa-lg" style={{ color: "#fff" }} />
                            </Button>
                            <span className="badge badge-danger transaction-badge ">{pendingTransactionCounts[item?.proxied_address]?.length}</span>
                          </div>
                        ) : null}
                        <CommonTooltip placement="top" id={`pending-transactions`} content={"Pending transactions"} color={"primary"} />

                        {edit ? (
                          <>
                            <Button id={`edit-proxied-${i}`} onClick={() => handleEdit(item?.id)} size="sm" color="primary" className="custom-send-btn custom-primary-btn">
                              <i className="fa-solid fa-pen-to-square" />
                            </Button>
                            <CommonTooltip placement="top" id={`edit-proxied-${i}`} content={"Edit Multisig"} color={"primary"} />{" "}
                          </>
                        ) : null}

                        {destroy ? (
                          <>
                            <Button id={`delete-proxied-${i}`} onClick={() => handleDelete(item?.id)} size="sm" color="primary" className="custom-send-btn custom-primary-btn">
                              <i className="fa-solid fa-trash" />
                            </Button>
                            <CommonTooltip placement="top" id={`delete-proxied-${i}`} content={"Delete Proxied"} color={"primary"} />{" "}
                          </>
                        ) : null}
                        <div id={`batch-proxied-${i}`}>
                          <Button size="sm" color="primary" disabled={!isWalletConnected} className="custom-send-btn" onClick={() => handleSend(item, "batchCallsProxied")}>
                            Batch Calls
                            <i className="fa-solid fa-share-nodes ms-2" />
                          </Button>
                          {!isWalletConnected && <CommonTooltip placement="top" id={`batch-proxied-${i}`} content={"Wallet is not connected"} color={"primary"} />}
                        </div>
                        <div id={`send-proxied-${i}`}>
                          <Button size="sm" color="primary" className="custom-send-btn" disabled={!isWalletConnected} onClick={() => handleSend({ ...item, ...deepParseJson(item?.multisig_details) }, "initialProxy")}>
                            Send <i className="fa-solid fa-paper-plane ms-2" />
                          </Button>
                          {!isWalletConnected && <CommonTooltip placement="top" id={`send-proxied-${i}`} content={"Wallet is not connected"} color={"primary"} />}
                        </div>
                        <div className="dropdown">
                          <Button id={`view-details1-${i}`} onClick={() => toggleDropdown(i)} className="dropdown-toggle" />
                        </div>
                        <CommonTooltip placement="top" id={`view-details1-${i}`} content={"View Details"} color={"primary"} />
                      </div>
                    </div>
                    <div className="multi-count">
                      <span>{balances[item?.proxied_address] === null || balances[item?.proxied_address] === undefined ? "Loading..." : balances[item?.proxied_address] + " AVAIL"}</span>
                    </div>
                    <div className={`multisig-mem ${openDropdownId === i ? "is-open" : ""}`}>
                      <span className="team-title">Multisig Address : </span>
                      <div>
                        <CommonTooltip classes={"me-3"} placement="right" id={`TooltipExample-from-${item.id}`} content={deepParseJson(item?.multisig_details)?.ms_address} color={"primary"}>
                          {deepParseJson(item?.multisig_details)?.ms_name}
                        </CommonTooltip>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <div className="text-center no-found">{isWalletConnected ? "No Proxied account found" : "Wallet is not connected "}</div>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProxiedAddressCard;
