  import React, { useEffect, useState } from "react";
  import { useForm } from "react-hook-form";
  import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
  import { createAccountApi, updateAccountApi } from "../../Api/ApiList";
  import { usePerformMutation } from "../../Api/PerformMutation"; // Use the mutation hook
  import { useAccount } from "../../Context/AccountContext";
  import FormInput from "../../CommonComponents/InputFields/FormInput";

  const EditModal = ({ refetchTransaction, isEditMode, toggleEdit }) => {
    const { editModal, editAccountData } = useAccount();
    const [loading, setLoading] = useState(false);

    // Use the mutation hook for performing API requests
    const { mutate } = usePerformMutation(
      () => {
        refetchTransaction(); // Refetch the data after mutation
        reset();
        toggleEdit();
      },
      () => {
        setLoading(false); // Reset loading state on error
      }
    );

    const {
      register,
      handleSubmit,
      reset,
      setValue,
      watch,
      formState: { errors },
    } = useForm({
      defaultValues: {
        id: "",
        address: "",
        spend: "",
        is_treasury: false,
        starting_balance: "",
        label: "",
        is_balance_fetched: false,
      },
    });

    const handleAccount = (data) => {
      setLoading(true);
      const apiEndpoint = isEditMode ? updateAccountApi : createAccountApi;
      const conditionalData = {
        id: data.id,
        label: data.label,
        is_treasury: data.is_treasury,
        is_balance_fetched: data.is_balance_fetched,
        address: data.address,
      };

      // Trigger the mutation function
      mutate({ url: apiEndpoint, data: conditionalData });
    };

    const handleTreasuryChange = (e) => {
      setValue("is_treasury", e.target.checked ? 1 : 0);
    };
    const handleBalancesChange = (e) => {
      setValue("is_balance_fetched", e.target.checked ? 1 : 0);
    };

    useEffect(() => {
      if (isEditMode && editAccountData) {
        setValue("id", editAccountData.id);
        setValue("address", editAccountData.address);
        setValue("label", editAccountData.label);
        setValue("spend", editAccountData.spend);
        setValue("is_treasury", editAccountData.is_treasury ? 1 : 0);
        setValue("is_balance_fetched", editAccountData.is_balance_fetched? 1 : 0);
        setValue("starting_balance", editAccountData.starting_balance);
      } else {
        reset();
      }
    }, [isEditMode, editAccountData, setValue, reset]);

    return (
      <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal">
        <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Account" : "Create Account"}</ModalHeader>
        <Form onSubmit={handleSubmit(handleAccount)} noValidate>
          <ModalBody>
            <Row className="g-3">
              {isEditMode && (
                <Col sm="6">
                  <FormInput label={"No:"} name={"id"} errors={errors} register={register} placeholder={"Enter number"} classes={{ inputClass: "search-transaction" }} />
                </Col>
              )}
              {isEditMode && (
                <Col sm="6">
                  <FormInput label={"Label:"} name={"label"} errors={errors} register={register} placeholder={"Enter label"} classes={{ inputClass: "search-transaction" }} validationRules={{ required: "Label is required" }} />
                </Col>
              )}
              <Col sm="12">
                <FormInput label={"Address:"} name={"address"} errors={errors} register={register} placeholder={"Enter address"} classes={{ inputClass: "search-transaction" }} validationRules={{ required: "Address is required" }} />
              </Col>
              {!isEditMode && (
                <Col sm="6">
                  <FormInput label={"Label:"} name={"label"} errors={errors} register={register} placeholder={"Enter label"} classes={{ inputClass: "search-transaction" }} validationRules={{ required: "Label is required" }} />
                </Col>
              )}
              {isEditMode && (
                <Col sm="6">
                  <FormInput label={"Spend:"} name={"spend"} errors={errors} register={register} placeholder={"Enter spend amount"} classes={{ inputClass: "search-transaction" }} readOnly={isEditMode} />
                </Col>
              )}
              <Col sm="6" className="d-flex align-items-center">
                <div className="check-input gap-2">
                  <label htmlFor="treasury-switch " className="form-label mb-0">
                    Treasury
                  </label>
                  <FormGroup switch>
                    <Input type="switch" role="switch" onChange={handleTreasuryChange} checked={watch("is_treasury")} />
                  </FormGroup>
                </div>
              </Col>
              <Col sm="6" className="d-flex align-items-center">
                <div className="check-input gap-2">
                  <label htmlFor="treasury-switch " className="form-label mb-0">
                    Is Balance Fetched
                  </label>
                  <FormGroup switch>
                    <Input type="switch" role="switch" onChange={handleBalancesChange} checked={watch("is_balance_fetched")} />
                  </FormGroup>
                </div>  
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="" type="submit">
              {isEditMode ? "Update" : "Create"} {loading && <i className="fa-solid fa-circle-notch fa-spin" />}
            </Button>
            <Button
              color="light"
              className="border border-1"
              onClick={() => {
                toggleEdit();
                reset();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  };

  export default EditModal;
