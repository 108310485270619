import React, { Fragment, useState } from "react";
import { Badge, Tooltip } from "reactstrap";
import { handleCopyCode } from "../../Utils/CustomFunctions";

const CommonTooltip = ({ classes, placement, id, content, children, color, redirectLink, isBadge = false }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleCopy = (content) => {
    if (redirectLink) {
      // Open the link in a new tab using window.open
      window.open(`${redirectLink}`, "_blank");
    } else {
      handleCopyCode(content, "Text");
    }
  };

  return (
    <>
      {!isBadge ? (
        <Badge className={classes ? classes : ""} color={color} id={id}>
          {children}
        </Badge>
      ) : (
        <span className={classes ? classes : ""} id={id}>
          {children}
        </span>
      )}
      <Tooltip placement={placement} autohide={false} isOpen={tooltipOpen} target={id} toggle={toggle}>
        {copied ? "Copied!" : content}
      </Tooltip>
    </>
  );
};

export default CommonTooltip;
