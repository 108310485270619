import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createPoolAddress } from "../../Api/ApiList";
import { usePerformMutation } from "../../Api/PerformMutation"; // Use the mutation hook
import FormInput from "../../CommonComponents/InputFields/FormInput";

const AddContractModal = ({ refetchAddress, isEditMode, toggleEdit, editModal }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: "",
      abi: "",
      network: "",
    },
  });

  // Use the mutation hook for performing API requests
  const { mutate } = usePerformMutation(
    () => {
      refetchAddress(); // Refetch the data after mutation
      reset();
      toggleEdit();
    },
    () => {
      setLoading(false); // Reset loading state on error
    }
  );

  const handleAddPoolAddress = async (data) => {
    setLoading(true);
    try {
      const apiEndpoint = createPoolAddress;
      await mutate({
        url: apiEndpoint,
        data: {
          ...data,
          abi: JSON.parse(data.abi), // Ensure ABI is parsed correctly
        },
      });
    } catch (error) {
      console.error("Error adding pool address:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      centered
      isOpen={editModal}
      toggle={() => {
        toggleEdit();
        reset();
      }}
      className="transaction-modal"
    >
      <ModalHeader
        toggle={() => {
          toggleEdit();
          reset();
        }}
      >
        {"Add Contract"}
      </ModalHeader>
      <Form onSubmit={handleSubmit(handleAddPoolAddress)} noValidate>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormInput label={"Address:"} name={"address"} register={register} errors={errors} placeholder={"Enter pool address"} readOnly={isEditMode} classes={{ inputClass: "search-transaction " }} validationRules={{ required: "Address is required" }} />
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">ABI :</label>
                <textarea
                  rows={7}
                  {...register("abi", {
                    required: "ABI is required",
                    validate: (value) => {
                      try {
                        JSON.parse(value);
                        return true; // Valid JSON
                      } catch (error) {
                        return "ABI must be a valid JSON"; // Error message for invalid JSON
                      }
                    },
                  })}
                  className={`form-control search-transaction ${errors.abi ? "is-invalid" : ""}`}
                  disabled={isEditMode}
                  placeholder="Enter contract abi"
                />
                {errors.abi && <div className="invalid-feedback">{errors.abi.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Network:</label>
                <select {...register("network", { required: "Network is required" })} className={`form-control search-users ${errors.network ? "is-invalid" : ""}`}>
                  <option value="">Select network</option>
                  <option value="eth">{`Mainnet (mainnet.infura.io)`}</option>
                  <option value="base">{`BASE mainnet (base-mainnet.infura.io)`}</option>
                </select>
                {errors.network && <div className="invalid-feedback">{errors.network.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="" type="submit">
            {isEditMode ? "Update" : "Create"} {loading && <i className="fa-solid fa-circle-notch fa-spin" />}
          </Button>
          <Button
            color="light"
            className="border border-1"
            onClick={() => {
              toggleEdit();
              reset();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddContractModal;
