import React from "react";

const PriceDetailCard = ({ coinMarketData, priceChange }) => {
  return (
    <div className="price-usd">
      <h6 className="text-center">Price</h6>
      <div className="custom-price">
        <div className={`price badge-light-${priceChange > 0 ? "success" : priceChange < 0 ? "danger" : "warning"}`}>
          <h6>$ {coinMarketData?.price?coinMarketData?.price?.toFixed(5) : 0}</h6>
          <span>
            <i className={`fa-solid fa-arrow-${priceChange > 0 ? "trend-up" : priceChange < 0 ? "trend-down" : ""}`}/>
            <div className="usd"> { Number(priceChange) ? priceChange : "0 %"}</div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PriceDetailCard;
