import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useErrorMessageHandler } from "./middleware";

export const performMutation = async ({ url, data, isUpdate }) => {
  const method = isUpdate ? "put" : "post";

  const token = localStorage.getItem("finance_token");
  const parsedToken = JSON.parse(token);
  const response = await axios({
    method,
    url: `${process.env.REACT_APP_BASE_URL}/${url}`,
    data,
    headers: {
      "x-access-token": parsedToken,
    },
  });
  return response.data;
};

export const usePerformMutation = (onSuccess, onError, isToast = true) => {
  const { navigate } = useNavigate();
  const { handleError } = useErrorMessageHandler(navigate);

  return useMutation({
    mutationFn: performMutation,
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
      if (isToast) {
        toast.success(data?.message); // Display toast based on the isToast flag
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      } else {
        handleError(error);
      }
      if (isToast) {
        toast.error(error?.response?.data?.message || error?.response?.data?.error || "Something went wrong"); // Display toast based on the isToast flag
      }
    },
  });
};
