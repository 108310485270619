import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import { getMultisigTransactionApi, updateMultisigTransactionApi } from "../../../../Api/ApiList";
import { usePerformMutation } from "../../../../Api/PerformMutation";
import { useGetApi } from "../../../../Api/useGetApi";
import { Href } from "../../../../Constants";
import { useMultisig } from "../../../../Context/MultisigContext";
import { useWallet } from "../../../../Context/WalletContext";
import InnerLoader from "../../../../Layout/InnerLoader";
import Loader from "../../../../Layout/Loader";
import { getMultisigProposer } from "../../../Common/AvailCommon";
import { checkJsonFormat } from "../../../Common/CustomFunctions";
import CSVDataValidator from "./CsvDataValidator";
import useMultisigApprovals from "./Hooks/useMultisigApprovals";
import TransactionDetails from "./TransactionDetails";
import { truncateMiddle } from "../../../../Utils/CustomFunctions";

const PendingTransactionModal = ({ refetchProxied, refetchGetData, mainTransactionData, isOpen, toggle, senderDetails, multisigTransactions, setPendingMultisigTransactions }) => {
  const [loading, setLoading] = useState(false);
  const { transactionUpdates, setTransactionUpdates, setTransactionLoading: mainTransactionLoading } = useMultisig();
  const { api, accounts } = useWallet();
  const [decodedCallData, setDecodedCallData] = useState({});
  const [transactionsTableData, setTransactionsTableData] = useState([]);
  const [transactionsLoading, setTransactionLoading] = useState(false);
  const multisigMembers = checkJsonFormat(senderDetails?.members);
  const [composer, setComposer] = useState("");
  const [approvalsState, setApprovalsState] = useState({
    pendingApprovals: 0,
    approvals: [],
  });
  const hashApprovalType = [
    {
      text: "Approve this call hash",
      value: 1,
    },
    {
      text: "Cancel this call hash",
      value: 0,
    },
  ];

  const { mutate } = usePerformMutation(() => {
    refetchGetData();
    setTimeout(() => {
      refetchProxied();
    }, 500);
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      hash: "",
      approvalType: 1,
      amount: "",
      currentAddress: "",
      callData: "",
    },
  });
  const currentHash = watch("hash");
  const { isLoading, data: transactionData, refetch: refetchSingleHashData } = useGetApi({ url: getMultisigTransactionApi, params: { hash: currentHash, from: senderDetails?.proxied_address || senderDetails?.ms_address }, options: { enabled: false }, successFunction: false });

  const closeModal = () => {
    toggle("hashes");
  };

  // Get all pending multisig transaction hashes
  const fetchPendingTransactions = async (address) => {
    setTransactionLoading(true);
    try {
      const pendingTransactions = await mainTransactionData[address];
      return pendingTransactions;
    } finally {
      // Ensure loading is set to false even on error
      setTransactionLoading(false);
    }
  };
  // ====================================================================== //
  // Track approval by hashes
  const trackApprovals = async () => {
    const multisigInfo = await api.query.multisig.multisigs(senderDetails?.ms_address || senderDetails?.multisigAddress, currentHash);

    try {
      if (multisigInfo.isSome) {
        const { approvals } = multisigInfo.unwrap();
        const pendingApprovals = senderDetails?.threshold - approvals.length;
 
        setApprovalsState({
          approvals: approvals.map((address) => address.toHuman()),
          pendingApprovals,
        });
      }
    } catch (err) {
      toast.error(`Error occurred -${err.message}`);
    }
  };

  const handleCancel = () => {
    closeModal();
    reset({
      from_address: senderDetails?.ms_address || senderDetails?.multisigAddress || "",
      to_address: "",
      amount: "",
    });
  };
  const { signFinalApproval, rejectMultisigApproval, signNextApproval } = useMultisigApprovals(api, senderDetails, mutate, reset, closeModal, mainTransactionLoading, setLoading, updateMultisigTransactionApi, setTransactionUpdates, transactionData, refetchGetData);

  // To get the pending multisig transaction hashes
  useEffect(() => {
    if (api && senderDetails) {
      (async function fetchTransactions() {
        const pendingMultisigTransactions = await fetchPendingTransactions(senderDetails?.proxied_address || senderDetails?.ms_address);
        setPendingMultisigTransactions(pendingMultisigTransactions);
      })();
      // fetchTransactions();
    }
  }, [api, senderDetails, transactionUpdates]);

  // SenderDetails was dependancy
  useEffect(() => {
    if (currentHash) {
      refetchSingleHashData();
      trackApprovals();
    }
  }, [currentHash, transactionUpdates]);

  useEffect(() => {
    if (senderDetails?.ms_address || senderDetails?.multisigAddress) {
      const fetchComposer = async () => {
        if (currentHash) {
          const proposer = await getMultisigProposer(api, senderDetails?.ms_address, currentHash);
          setComposer(accounts.find((item) => item.address == proposer));
        }
      };
      fetchComposer();
    }
  }, [senderDetails, currentHash, transactionUpdates, toggle]);

  useEffect(() => {
    if (transactionData?.data?.length) {
      setValue("callData", transactionData?.data[0]?.call_data);
    }
  }, [transactionData]);
  useEffect(() => {
    if (multisigTransactions?.length) {
      setValue("hash", multisigTransactions[0]); // Set the first hash as default
    }
  }, [multisigTransactions, setValue]);

  if (!api) return <Loader />;
  return (
    <Modal size="xl" centered isOpen={isOpen} toggle={() => closeModal()} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Pending Call Hashes"}</ModalHeader>
      {transactionsLoading || isLoading ? (
        <InnerLoader />
      ) : multisigTransactions?.length ? (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(watch("approvalType") == 0 ? rejectMultisigApproval : approvalsState?.pendingApprovals == 1 ? signFinalApproval : signNextApproval)}>
          <ModalBody>
            <Row className="g-3">
              <Col sm="7">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Pending Hashes:</label>
                  <select
                    {...register("hash", {
                      required: "Hash selection is required",
                      onChange: (e) => {
                        setValue("hash", e.target.value);
                        setValue("callData", "");
                        setValue("currentAddress", "");
                      },
                    })}
                    className={`form-control ${errors.hash ? "is-invalid" : ""}`}
                    value={watch("hash")} // Controlled component
                  >
                    <option value="" disabled>
                      Select hash
                    </option>
                    {multisigTransactions?.map((hash) => (
                      <option key={hash} value={hash}>
                        {hash}
                      </option>
                    ))}
                  </select>
                  <p className="helper-text">The call hash from the list of available and unapproved calls.</p>
                  {errors.hash && <div className="invalid-feedback">{errors.hash.message}</div>}
                </FormGroup>
              </Col>
              <Col sm="5">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Approval Type:</label>
                  <select {...register("approvalType", { required: "Address is required" })} className={`form-control ${errors.approvalType ? "is-invalid" : ""}`}>
                    {hashApprovalType?.map((type, index) => (
                      <option key={index} value={type.value}>
                        {type.text}
                      </option>
                    ))}
                  </select>
                  <p className="helper-text">The operation type to apply. For approvals both non-final and final approvals are supported.</p>
                  {errors.approvalType && <div className="invalid-feedback">{errors.approvalType.message}</div>}
                </FormGroup>
              </Col>
              <Col sm="7">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Signatory:</label>
                  <select {...register("currentAddress", { required: "Signatory must be selected" })} className={`form-control ${errors.currentAddress ? "is-invalid" : ""}`}>
                    <option value="" disabled>
                      Select address
                    </option>
                    {watch("approvalType") == "1" ? (
                      multisigMembers
                        ?.filter((member) => !approvalsState?.approvals?.includes(member?.address))
                        ?.map((member, index) => (
                          <option key={index} value={member?.address}>
                            {`${member?.meta?.name?.toUpperCase()}  [${truncateMiddle(member?.address, 16)}]`}
                          </option>
                        ))
                    ) : (
                      <option value={composer?.address}>{composer ? `${composer?.meta?.name?.toUpperCase()} [${truncateMiddle(composer?.address, 16)}]` : ""}</option>
                    )}
                  </select>
                  <p className="helper-text">For approvals outstanding approvers will be shown, for hashes that should be cancelled the first approver is required.</p>
                  {errors.currentAddress && <div className="invalid-feedback">{errors.currentAddress.message}</div>}
                </FormGroup>
              </Col>
              {currentHash ? (
                <Col sm="5">
                  <FormGroup className="form-group">
                    <label className="mb-2 form-label">Approved By:</label>
                    <UncontrolledDropdown className="common-dropdown">
                      <DropdownToggle caret color="primary">
                        {`Existing approvals ${approvalsState?.approvals?.length + "/" + senderDetails?.threshold}`}
                      </DropdownToggle>
                      <DropdownMenu>
                        {approvalsState?.approvals?.map((address, i) => (
                          <DropdownItem href={Href}>{address}</DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div>
                      <p className="helper-text">The current approvals applied to this multisig</p>
                    </div>
                  </FormGroup>
                </Col>
              ) : null}

              {approvalsState?.pendingApprovals == 1 && watch("approvalType") != 0 ? (
                <Col sm="12">
                  <FormGroup className="form-group">
                    <label className="mb-2 form-label">Call data for final approval:</label>
                    <input
                      readOnly
                      {...register("callData", {
                        required: "Call data is required",
                      })}
                      className={`form-control ${errors.callData ? "is-invalid" : ""}`}
                      type="text"
                      placeholder="Enter call data"
                    />
                    <div className="d-flex align-items-ends justify-content-between">
                      <p className="helper-text">The call data for this transaction matching the hash. Once sent, the multisig will be executed against this. </p>
                      <Link className="extrinsic-link" to={`https://explorer.avail.so/?rpc=${process.env.REACT_APP_AVAIL_RPC_ENDPOINT}#/extrinsics/decode/${watch("callData")}`} target="_blank">
                        See on explorer
                      </Link>
                    </div>
                    {errors.callData && <div className="invalid-feedback">{errors.callData.message}</div>}
                  </FormGroup>
                </Col>
              ) : null}

              {watch("approvalType") != 0 && watch("callData") && <TransactionDetails transactionsTableData={transactionsTableData} setTransactionsTableData={setTransactionsTableData} setDecodedCallData={setDecodedCallData} callData={watch("callData")} />}

              {decodedCallData && Object.keys(decodedCallData)?.length && (decodedCallData?.method == "batch" || decodedCallData?.args[2]?.args?.calls?.length) ? <CSVDataValidator tableData={transactionsTableData} /> : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? "Submitting..." : watch("approvalType") == "0" ? "Reject" : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      ) : (
        <h4 className="text-center no-found">No Pending Transactions Found</h4>
      )}
    </Modal>
  );
};

export default PendingTransactionModal;
