import { BN } from "bn.js";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useWallet } from "../../Context/WalletContext";
import { generateEncodedCallData } from "../Common/AvailCommon";
import FormInput from "../../CommonComponents/InputFields/FormInput";

const GenerateCallDataModal = ({ callDataModal, toggleEdit, setSender, setCallData }) => {
  const [loading, setLoading] = useState(false);
  const [encodedCallData, setEncodedCallData] = useState(""); // New state for encoded call data
  const { api } = useWallet();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      from_address: "", // Leave it empty initially
      to_address: "",
      amount: "",
    },
  });

  const closeModal = () => {
    toggleEdit();
    // reset();
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, ""); // Remove any commas
    const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas as thousands separators
    setValue("amount", formattedValue); // Update the form state
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setSender(data?.from_address);

    // Convert amount to the required large unit (1e18)
    const amountInWei = new BN(String(data.amount).replace(/,/g, "")); // Remove commas, convert to BN
    const amountInLargeUnit = amountInWei.mul(new BN("1000000000000000000")); // Multiply by 1e18

    // Call generateEncodedCallData with the necessary parameters
    const generatedData = await generateEncodedCallData(api, "balances", "transferKeepAlive", [
      { Id: data.to_address }, // or directly use data.from_address
      amountInLargeUnit, // Use the amount in large unit
    ]);

    // Set the generated encoded call data in the state
    setEncodedCallData(generatedData);
    setCallData(generatedData);
    setLoading(false);
    // closeModal();
  };

  return (
    <Modal size="lg" centered isOpen={callDataModal} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Generate encoded call data"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormInput label={"From Address:"} name={"from_address"} errors={errors} register={register} validationRules={{ required: "Address is required" }} placeholder="Enter from address" />
            </Col>
            <Col sm="12">
              <FormInput label={"* To Address:"} name={"to_address"} errors={errors} register={register} validationRules={{ required: "Address is required" }} placeholder="Enter to address" />
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Amount:</label>
                <input
                  {...register("amount", {
                    required: "Amount is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Amount must be a number",
                    },
                  })}
                  className={`form-control ${errors.amount ? "is-invalid" : ""}`}
                  type="text"
                  placeholder="Enter amount"
                  onChange={handleAmountChange} // Call the handler on change
                />

                {errors.amount && <div className="invalid-feedback">{errors.amount.message}</div>}
              </FormGroup>
            </Col>

            {/* Display the generated encoded call data */}
            {encodedCallData && (
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Encoded Call Data:</label>
                  <textarea className="form-control" value={encodedCallData} rows="5" readOnly />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default GenerateCallDataModal;
