import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getExchangeBalances } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { ExchangeBalancesHistoryTableColumnData } from "../../Data/TableColumnsData";
import Loader from "../../Layout/Loader";
import CommonPagination from "../Common/CommonPagination";
import CommonTable from "../Common/CommonTable";
import TableTop from "../Common/TableTop";
import { calculatePercentChange, formatAmount, formatDateTime } from "../../Utils/CustomFunctions";
import AdvanceFilter from "../Common/AdvanceFilter";
import useDebouncedObject from "../../Hooks/useDebounceObject";
import BalanceHistoryChart from "./BalanceStatisticChart";

const ExchangeBalancesHistory = () => {
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [params, setParams] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [balanceComparison, setBalanceComparison] = useState([]);
  const [percentageChange, setPercentageChange] = useState("");
  const [advancedFilter, setAdvancedFilter] = useState({
    startDate: new Date().toISOString().split("T")[0],
    // endDate: new Date().toISOString().split("T")[0],
  });
  const debouncedObject = useDebouncedObject(advancedFilter, 500);
  const { startDate = null, endDate = null } = debouncedObject;

  const { isLoading, fetchStatus, data } = useGetApi({
    url: getExchangeBalances,
    params,
    options: {
      refetchOnWindowFocus: false,
      enabled: !!Object.keys(params)?.length,
      select: (data) => ({ ...data, data: data?.data?.filter((item) => item.date == new Date(startDate).toISOString().split("T")[0]) }),
    },
  });
  const dateSeries = data?.data?.map((item) => formatDateTime(item.date, true)) || [];

  useEffect(() => {
    if (data) {
      setTotalPages(data.totalPages);
    }
    const valueChange = calculatePercentChange(data?.latestStatistic?.totalAmount, data?.yesterdayStatistics?.totalAmount);
    setPercentageChange(valueChange);
  }, [data]);

  useEffect(() => {
    if (startDate) {
      const formattedStartDate = new Date(startDate).toISOString().split("T")[0];

      setParams((prevParams) => ({
        ...prevParams,
        page,
        limit,
        startDate: formattedStartDate,
        endDate: formattedStartDate,
      }));
    }
  }, [page, limit, startDate, endDate]);

  const getUpdatedBalanceHistory = () => {
    if (!data?.data || data.data.length === 0) return [];

    const groupedData = {};

    data.data.forEach((entry) => {
      const { amountTotalRounded, exchange_account, date } = entry;

      const coin = exchange_account?.label;
      if (!groupedData[coin]) {
        groupedData[coin] = [];
      }
      groupedData[coin].push({ date: new Date(date), amountTotalRounded });
    });

    Object.keys(groupedData).forEach((coin) => {
      groupedData[coin].sort((a, b) => b.date - a.date); // Sort by date (latest first)
    });

    const comparisonResult = Object.keys(groupedData).map((coin) => {
      const coinData = groupedData[coin];

      if (coinData.length < 2) return { coin, currentBalance: coinData[0]?.amountTotalRounded, previousBalance: null, absoluteChange: 0, percentageChange: 0 };

      const currentBalance = coinData[0]?.amountTotalRounded;
      const previousBalance = coinData[1]?.amountTotalRounded;

      const absoluteChange = currentBalance - previousBalance;
      const percentageChange = ((absoluteChange / previousBalance) * 100).toFixed(2);

      return { coin, currentBalance, previousBalance, absoluteChange, percentageChange };
    });

    return comparisonResult;
  };

  useEffect(() => {
    if (data?.data) {
      const balanceComparison = getUpdatedBalanceHistory();
      setBalanceComparison(balanceComparison);
    }
  }, [data]);

  if (isLoading && fetchStatus != "idle") return <Loader />;

  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader className="avail-info">
            <h4>Exchange Balances History</h4>
            <Row className="avail">
              <Col xxl="4" xl="6">
                <div className="price-usd">
                  <h6 className="text-center">Total Balance</h6>
                  <div className="custom-price">
                    <div className={`price badge-light-${percentageChange?.percentageChange > 0 ? "success" : percentageChange?.percentageChange < 0 ? "danger" : "warning"}`}>
                      <h6> {formatAmount(data?.latestStatistic?.totalAmount)} </h6>
                      {/* <span>
                        <i className={`fa-solid fa-arrow-${percentageChange?.percentageChange > 0 ? "trend-up" : percentageChange?.percentageChange < 0 ? "trend-down" : ""}`} />
                        <div className="usd"> {Number(percentageChange?.percentageChange) ? percentageChange?.percentageChange : "0 %"}</div>
                      </span> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl="4" xl="6">
                <div className="price-usd">
                  <h6 className="text-center">24h Change</h6>
                  <div className="custom-price">
                    <div className={`price badge-light-${percentageChange?.absoluteChange > 0 ? "success" : percentageChange?.absoluteChange < 0 ? "danger" : "warning"}`}>
                      <h6>{percentageChange.absoluteChange ? (Number(percentageChange.absoluteChange) > 0 ? "+" + formatAmount(percentageChange.absoluteChange) : Number(percentageChange.absoluteChange).toFixed(2)) : "--"}</h6>
                      {/* <span>{percentageChange?.absoluteChange ? <i className={`fa-solid fa-arrow-${percentageChange.absoluteChange > 0 ? "up" : "down"}`} /> : null}</span> */}
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl="4" xl="6">
                <div className="price-usd">
                  <h6 className="text-center">24h % Change</h6>
                  <div className="custom-price">
                    <div className={`price badge-light-${percentageChange?.percentageChange ? (percentageChange.percentageChange > 0 ? "success" : "danger") : "warning"}`}>
                      <h6>{percentageChange?.percentageChange ? `${Number(percentageChange.percentageChange).toFixed(2)} %` : "--"}</h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <TableTop noSearchbar limit={limit} advanceFilter={{ setShowFilter: setShowFilter, showFilter: showFilter }} setLimit={setLimit} setPage={setPage} />
            {showFilter ? <AdvanceFilter advancedFilter={advancedFilter} setAdvancedFilter={setAdvancedFilter} setParams={setParams} params={params} fieldNotToInclude={["minAmount", "maxAmount", "fromAddress", "toAddress", "endDate"]} /> : null}

            <div className="custom-scrollbar table-responsive">
              <CommonTable serialNo perPage={limit} currentPage={page} columns={ExchangeBalancesHistoryTableColumnData(balanceComparison)} data={data?.data} isLoading={isLoading} />
            </div>
            {!isLoading && data?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
        <BalanceHistoryChart data={data} dateSeries={dateSeries} />
      </section>
    </Fragment>
  );
};

export default ExchangeBalancesHistory;
