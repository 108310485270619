import { isAddress } from "@polkadot/util-crypto";
import Papa from "papaparse";

export const useBatchCallCsv = (setCsvData, setCsvFile, watch, reset, getValues, csvData, fileInputRef, setCsvText, setPendingCsvErrors, setPendingCsvData) => {
  const processCSVData = (rawData) => {
    if (!rawData || rawData.length === 0) {
      setPendingCsvErrors(["CSV is empty or invalid"]);
      return null;
    }

    const newCsvData = [];
    const errors = [];

    rawData.forEach((row, index) => {
      // Process rows as arrays (column index-based)
      const to_address = (row[0] || "").toString().trim();
      const amount = (row[1] || "").toString().trim().replace(/,/g, "");

      const rowErrors = [];
      if (!isAddress(to_address)) {
        rowErrors.push(`Row ${index + 1}: Address is not valid`);
      }
      if (!/^\d+(\.\d+)?$/.test(amount)) {
        rowErrors.push(`Row ${index + 1}: Amount must be a valid number (integer or decimal)`);
      }

      if (rowErrors.length > 0) {
        errors.push(...rowErrors);
      } else {
        newCsvData.push({ to_address, amount });
      }
    });

    if (errors.length > 0) {
      setPendingCsvErrors(errors);
      return null;
    }

    return newCsvData;
  };

  const preprocessCSVText = (text) => {
    return text
      .split("\n")
      .map(
        (line) =>
          line
            .trim()
            .replace(/[\s,]+/g, ",") // Normalize separators to commas
            .replace(/,+/g, ",") // Remove multiple commas
      )
      .join("\n");
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const rawText = e.target.result;
      const processedText = preprocessCSVText(rawText);

      setCsvText(rawText);
      setPendingCsvErrors([]);

      Papa.parse(processedText, {
        header: false, // Parse without headers
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          const newCsvData = processCSVData(result.data);
          setPendingCsvData(newCsvData || []);
          if (newCsvData) setCsvFile(file);
        },
      });
    };
    reader.readAsText(file);
  };

  const handleCSVText = (text) => {
    const processedText = preprocessCSVText(text);

    setCsvText(text);
    setPendingCsvErrors([]);
    setCsvFile(null);

    Papa.parse(processedText, {
      header: false, // Parse without headers
      skipEmptyLines: true,
      delimiter: ",",
      complete: (result) => {
        const newCsvData = processCSVData(result.data);
        setPendingCsvData(newCsvData || []);
      },
    });
  };

  const removeCSV = () => {
    setCsvFile(null);
    setCsvData([]);
    setPendingCsvData([]);
    setPendingCsvErrors([]);
    setCsvText("");
    if (fileInputRef.current) fileInputRef.current.value = "";

    const currentTransfers = getValues("transfers");
    const remainingTransfers = currentTransfers.filter((transfer) => !csvData.some((csvRow) => csvRow.to_address === transfer.to_address && csvRow.amount === transfer.amount));

    if (remainingTransfers.length === 0) remainingTransfers.push({ to_address: "", amount: "" });
    reset({ ...getValues(), transfers: remainingTransfers });
  };

  return { handleCSVUpload, removeCSV, handleCSVText };
};
