import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { deleteExchangeAccounts, getExchangeAccounts } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { ExchangeBalancesTableColumnData } from "../../Data/TableColumnsData";
import Loader from "../../Layout/Loader";
import CommonTable from "../Common/CommonTable";
import TableTop from "../Common/TableTop";
import { Plus } from "react-feather";
import CommonPagination from "../Common/CommonPagination";
import EditModal from "./EditModal";
import { useExchangeBalance } from "../../Context/ExchangeBalanceContext";
import DeleteModal from "../Common/DeleteModal";
import BaseHolderStats from "./BalanceStatisticChart";

const ExchangeBalances = () => {
  const { toggleEdit, setEditExchangeBalanceData, toggleDelete, editExchangeBalanceData, deleteModal } = useExchangeBalance();
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [params, setParams] = useState({});
  const [editMode, setEditMode] = useState(false);

  const {
    isLoading,
    data,
    refetch: refetchGetData,
  } = useGetApi({
    url: getExchangeAccounts,
    params,
    options: {
      refetchOnWindowFocus: false,
      enabled: !!Object.keys(params)?.length,
    },
  });

  const handleEdit = (id) => {
    setEditMode(true);
    const singleEditData = data?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditExchangeBalanceData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = data?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditExchangeBalanceData(singleEditData);
    }
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data.totalPages);
    }
  }, [data]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
    }));
  }, [page, limit]);

  if (isLoading) return <Loader />;

  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader className="avail-info">
            <h4>Exchange Balances</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              noSearchbar
              limit={limit}
              setLimit={setLimit}
              setPage={setPage}
              extraComponents={[
                {
                  jsx: (
                    <Button color="border border-1" onClick={toggleEdit}>
                      <Plus size={15} /> Add Account
                    </Button>
                  ),
                },
              ]}
            />
            <div className="custom-scrollbar table-responsive">
              <CommonTable serialNo perPage={limit} onEdit={handleEdit} onDelete={handleDelete} currentPage={page} columns={ExchangeBalancesTableColumnData} data={data?.data} isLoading={isLoading} />
            </div>
            <EditModal setEditMode={setEditMode} refetchGetData={refetchGetData} isEditMode={editMode} />
            {deleteModal && <DeleteModal isOpen={deleteModal} deleteEndpoint={deleteExchangeAccounts} itemData={{ id: editExchangeBalanceData?.id }} itemType="Account" toggleModal={toggleDelete} refetchCallback={refetchGetData} />}
            {!isLoading && data?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
    </Fragment>
  );
};

export default ExchangeBalances;
