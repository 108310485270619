import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { formatAmount } from "../../Utils/CustomFunctions";

const TotalBalanceCard = ({ totalBalance }) => {
  return (
    <Row>
      <Col>
        <Card className="custom-multisig badge-light-success">
          <CardBody>
            <h6 className="custom-chain-heading">Total balance</h6>
            <span>{totalBalance === null || totalBalance === undefined ? "Loading..." : formatAmount(totalBalance) + " AVAIL"}</span>
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card className="custom-multisig badge-light-success">
          <CardBody>
            <h6 className="custom-chain-heading">Total transferable balance</h6>
            <span>{totalBalance === null || totalBalance === undefined ? "Loading..." : formatAmount(totalBalance) + " AVAIL"}</span>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TotalBalanceCard;
