import { web3FromSource } from "@polkadot/extension-dapp";
import toast from "react-hot-toast";
import { createProxiedApi } from "../../../../../Api/ApiList";
import { useMultisig } from "../../../../../Context/MultisigContext";
import { checkIfProxied, getMaxWeightDetails, getMaybeTimePoint, getMultisigProposer, getOtherSignatories } from "../../../../Common/AvailCommon";
import { deepParseJson } from "../../../../Common/CustomFunctions";
import { decodeCallData } from "../../../Utils";

const useMultisigApprovals = (api, senderDetails, mutate, reset, closeModal, mainTransactionLoading, setLoading, updateMultisigTransactionApi, setTransactionUpdates, transactionData, refetchGetData) => {
  const { multisigData } = useMultisig();

  const signNextApproval = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      setLoading(true);
      const heightAndIndex = await getMaybeTimePoint(api, senderDetails?.ms_address || senderDetails?.multisigAddress, data?.hash);
      const injector = await web3FromSource("subwallet-js");
      const fromAddress = data.currentAddress;
      const otherSignatories = getOtherSignatories(senderDetails?.members, fromAddress);

      const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, heightAndIndex, data?.hash, {
        refTime: 196085000,
        proofSize: 3593,
      });

      mainTransactionLoading(true);
      await transfer.signAndSend(fromAddress, { signer: injector.signer }, async ({ status, events }) => {
        if (status.isInBlock) {
          mainTransactionLoading(false);
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
          const existingSigners = transactionData?.data?.[0]?.signers ? deepParseJson(transactionData?.data?.[0]?.signers) : [];
          const mutableData = {
            hash: data?.hash,
            from: senderDetails?.proxied_address || senderDetails?.ms_address,
            status: "pending",
            signers: JSON.stringify([...existingSigners, fromAddress]), // Just send the new signer
          };

          await mutate({
            url: updateMultisigTransactionApi,
            data: mutableData,
            isUpdate: true,
          });
        } else if (status.isFinalized) {
          refetchGetData();
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }
        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`Next approval done for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || senderDetails?.multisigAddress || "",
        to_address: "",
        amount: "",
      });
      setTransactionUpdates(`Next approval done for ${data.hash}`);
      closeModal();
      setLoading(false);
      mainTransactionLoading(false);
    } catch (err) {
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      setLoading(false);
      mainTransactionLoading(false);
    }
  };

  // ====================================================================== //
  // Final approval for multisig transaction
  const signFinalApproval = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }

    try {
      setLoading(true);
      const heightAndIndex = await getMaybeTimePoint(api, senderDetails?.ms_address || senderDetails?.multisigAddress, data?.hash);
      const maybeTimePoint = heightAndIndex ? api.createType("Option<PalletMultisigTimepoint>", heightAndIndex) : null;
      const injector = await web3FromSource("subwallet-js");
      const fromAddress = data.currentAddress;
      const otherSignatories = getOtherSignatories(senderDetails?.members, fromAddress);
      const decodedCallData = await decodeCallData(api, data?.callData);
      // await injector.metadata.provide(getInjectorMetadata(api));

      if (!decodedCallData) {
        throw new Error("Failed to decode call data. Please ensure the data is correctly formatted.");
      }

      let callData;
      // For multisig and proxied batch call transactions
      if ((decodedCallData.section === "utility" && decodedCallData.method === "batch") || (decodedCallData.section === "proxy" && decodedCallData.method === "proxy" && decodedCallData?.args?.[2]?.args?.calls?.length)) {
        const isProxiedBatchCallTransaction = decodedCallData.section === "proxy" && decodedCallData.method === "proxy" && decodedCallData?.args?.[2]?.args?.calls?.length;
        const batchTransfers = isProxiedBatchCallTransaction ? decodedCallData?.args?.[2]?.args?.calls : decodedCallData?.args[0] ?? [];

        // Map transfers correctly
        const transferCalls = batchTransfers?.map((transfer) => {
          const destinationAddress = transfer.args?.dest?.Id;
          const amount = transfer.args?.value?.replace(/,/g, ""); // Ensure BN format
          return api.tx.balances.transferKeepAlive(destinationAddress, amount);
        });

        // Wrap batch transactions correctly
        const batchCall = api.tx.utility.batch(transferCalls);

        // Ensure proxy call wraps a SINGLE CALL (batchCall)
        if (isProxiedBatchCallTransaction) {
          console.log("proxy");
          callData = await api.tx.proxy.proxy(
            decodedCallData.args[0]?.Id, // Proxy Address
            decodedCallData.args?.[1], // Proxy Type (e.g., "Any")
            batchCall // Wrap batchCall inside proxy
          );
        } else if (decodedCallData.section === "utility" && decodedCallData.method === "batch") {
          callData = batchCall;
        }
        // For proxied transactions to single account
      } else if (decodedCallData?.method == "proxy" && decodedCallData?.section == "proxy") {
        const destinationAddress = decodedCallData.args?.[2]?.args?.dest?.Id ?? decodedCallData.args?.[0]?.Id;
        const amount = decodedCallData.args?.[2]?.args?.value?.replace(/,/g, "") ?? decodedCallData.args?.[1]?.replace(/,/g, "");
        const hashCall = await api.tx.balances.transferKeepAlive(destinationAddress, amount);
        callData = await api.tx.proxy.proxy(
          decodedCallData.args[0]?.Id, // Proxy Address
          decodedCallData.args?.[1], // Proxy Type (e.g., "Any")
          hashCall
        );
        // For create proxied account final approval
      } else if (decodedCallData?.method === "createPure") {
        callData = await api.tx[decodedCallData.section][decodedCallData.method](...decodedCallData?.args);
        // For single account transfer
      } else {
        callData = await api.tx[decodedCallData.section][decodedCallData.method](decodedCallData.args[0]?.Id, decodedCallData.args[1]?.split(",")?.join(""));
      }

      const dynamicMaxWeight = await getMaxWeightDetails(callData, fromAddress);
      const encodedCallData = callData;
      const transfer = api.tx.multisig.asMulti(Number(senderDetails?.threshold), otherSignatories, maybeTimePoint, encodedCallData, dynamicMaxWeight);

      mainTransactionLoading(true);
      await transfer.signAndSend(fromAddress, { signer: injector.signer }, async ({ status, events }) => {
        if (status.isInBlock) {
          mainTransactionLoading(false);
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
          if (decodedCallData?.method !== "createPure") {
            const existingSigners = transactionData?.data?.[0]?.signers ? deepParseJson(transactionData?.data?.[0]?.signers) : [];
            const mutableData = {
              hash: data?.hash,
              status: "completed",
              from: senderDetails?.proxied_address || senderDetails?.ms_address,
              signers: JSON.stringify([...existingSigners, fromAddress]), // Just send the new signer
            };

            await mutate({
              url: updateMultisigTransactionApi,
              data: mutableData,
              isUpdate: true,
            });
          }
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }
        events.forEach(async ({ phase, event: { data: mainData, method, section } }) => {
          setTransactionUpdates(`Final approval done for ${mainData.hash}`);
          if (section == "proxy" && method == "PureCreated") {
            const existingSigners = transactionData?.data?.[0]?.signers ? deepParseJson(transactionData?.data?.[0]?.signers) : [];
            const mutableData = {
              hash: data?.hash,
              from: senderDetails?.proxied_address || senderDetails?.ms_address,
              status: "completed",
              signers: JSON.stringify([...existingSigners, fromAddress]), // Just send the new signer
              comment: JSON.stringify(mainData),
            };

            await mutate({
              url: updateMultisigTransactionApi,
              data: mutableData,
              isUpdate: true,
            });
            const multisigDetailsOfProxied = await checkIfProxied(api, mainData[0]);
            const customizedData = {
              // id: data.id,
              proxied_address: mainData[0],
              proxied_name: `Proxy On ${senderDetails?.ms_name}`,
              multisig_details: JSON.stringify(multisigData?.data?.find((item) => multisigDetailsOfProxied?.delegate == item?.ms_address)),
            };
            await mutate({ url: createProxiedApi, data: customizedData });
          }
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || senderDetails?.multisigAddress || "",
        to_address: "",
        amount: "",
      });
      closeModal();
      // setEncodedCallData("");
      setTransactionUpdates(`Final approval done for ${data.hash}`);
      setLoading(false);
      mainTransactionLoading(false);
    } catch (err) {
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      setLoading(false);
      mainTransactionLoading(false);
    }
  };

  // To Cancel selected multisig hash
  const rejectMultisigApproval = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      setLoading(true);
      const proposer = await getMultisigProposer(api, senderDetails?.ms_address, data?.hash);
      // const fromAddress = approvalsState?.approvals[0];
      const threshold = senderDetails?.threshold; // Replace with the actual threshold value
      const otherSignatories = getOtherSignatories(senderDetails?.members, proposer);
      const timePoint = await getMaybeTimePoint(api, senderDetails?.ms_address || senderDetails?.multisigAddress, data.hash);

      // Ensure the injector is ready for signing
      const injector = await web3FromSource("subwallet-js");

      // Create the `cancelAsMulti` transaction
      const cancelTransaction = await api.tx.multisig.cancelAsMulti(
        threshold,
        otherSignatories.sort(), // Ensure the signatories are sorted
        timePoint,
        data.hash
      );

      // Sign and send the transaction
      mainTransactionLoading(true);
      await cancelTransaction.signAndSend(proposer, { signer: injector.signer }, async ({ status, events }) => {
        if (status.isInBlock) {
          mainTransactionLoading(false);
          toast.success(`Transaction included in block: ${status.asInBlock.toHex()}`);
          const mutableData = { hash: data?.hash, from: senderDetails?.proxied_address || senderDetails?.ms_address, status: "cancelled" };
          await mutate({ url: updateMultisigTransactionApi, data: mutableData, isUpdate: true });
          // mutate({url:updateMultisigTransactionApi,})
        } else if (status.isFinalized) {
          toast.success(`Transaction finalized`);
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        // Log events
        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`Transaction rejected for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || senderDetails?.multisigAddress || "",
        to_address: "",
        amount: "",
      });
      closeModal();
      // setEncodedCallData("");
      toast.success("Multisig approval rejected successfully.");
      setTransactionUpdates(`Transaction rejected for ${data.hash}`);
      setLoading(true);
      mainTransactionLoading(false);
    } catch (err) {
      toast.error("Failed to reject multisig approval:", err.message);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      setLoading(false);
      mainTransactionLoading(false);
    }
  };

  return { signNextApproval, signFinalApproval, rejectMultisigApproval };
};

export default useMultisigApprovals;
