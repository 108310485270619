import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, Col } from "reactstrap";
import { formatAmount } from "../../../Utils/CustomFunctions";

const PriceStats = ({ data, dateSeries }) => {
  const priceSeries = data?.map((item) => parseFloat(item.price ? item.price : 0));
  const [chartType, setChartType] = useState(false);

  const updateChartType = () => {
    setChartType((prev) => !prev);
  };

  const series = [
    {
      name: "Avail",
      type: chartType ? "line" : "bar",
      color: "#155e75",
      data: priceSeries,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
          customIcons: [
            {
              icon: `${chartType ? `<i className="fa-solid fa-chart-simple"/>` : '<i className="fa-solid fa-chart-line"/>'}`, // Add custom icon (can also use SVG path)
              title: "Chart Type", // Tooltip text
              index: "start", // Position in the toolbar
              click: updateChartType,
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 4],
    },
    title: {
      text: "Daily Price Stats",
      style: {
        fontSize: "18px",
        color: "var(--table-font-color)",
      },
    },
    xaxis: {
      categories: dateSeries,
    },
    yaxis: [
      {
        seriesName: "Income",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#155e75",
        },
        labels: {
          style: {
            colors: "#155e75",
          },
          formatter: (value) => formatAmount(value),
        },
        title: {
          text: "Price (in USD)",
          style: {
            color: "#155e75",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <Col lg="12">
      <Card className="common-chart">
        <CardBody>
          <ReactApexChart options={options} series={series} type="line" height={350} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default PriceStats;
