import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createProxiedApi, updateProxiedApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useMultisig } from "../../../Context/MultisigContext";
import { useProxied } from "../../../Context/ProxiedContext";
import { useWallet } from "../../../Context/WalletContext";
import { checkIfProxied } from "../../Common/AvailCommon";

const AddProxiedModal = ({ isOpen, toggle, senderDetails, refetchProxied }) => {
  const [loading, setLoading] = useState(false);
  const { toggleProxiedEdit, isProxiedEditMode, setIsProxiedEditMode, editProxiedModal, editProxiedData } = useProxied();
  const { multisigData } = useMultisig();
  const { api } = useWallet();

  const { mutate } = usePerformMutation(
    () => {
      reset();
      toggleProxiedEdit();
      setIsProxiedEditMode(false);
      setTimeout(() => {
        refetchProxied();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: null,
      proxiedAddress: "", // Leave it empty initially
      proxiedName: "",
      amount: "",
    },
  });

  const closeModal = () => {
    toggleProxiedEdit();
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const multisigDetailsOfProxied = await checkIfProxied(api, data?.proxiedAddress);
    const multisigDetails = multisigData?.data?.find((item) => multisigDetailsOfProxied?.delegate == item?.ms_address);
    if (multisigDetails) {
      const customizedData = {
        id: data.id,
        proxied_address: data.proxiedAddress,
        proxied_name: data?.proxiedName,
        multisig_details: JSON.stringify(multisigDetails),
      };
      await mutate({ url: isProxiedEditMode ? updateProxiedApi : createProxiedApi, data: customizedData, isUpdate: isProxiedEditMode || false });
    } else {
      toast.error("Multisig details for this proxy address do not exist, please create a multisig first.");
    }
    setLoading(false);
  };

  const handleCancel = () => {
    closeModal();
    reset({
      proxiedAddress: senderDetails?.ms_address || "",
      proxiedName: "",
      amount: "",
    });
    setIsProxiedEditMode(false);
  };

  useEffect(() => {
    if (isProxiedEditMode && editProxiedData) {
      setValue("id", editProxiedData.id);
      setValue("proxiedName", editProxiedData.proxied_name);
      setValue("proxiedAddress", editProxiedData.proxied_address);
    } else {
      reset();
    }
  }, [isProxiedEditMode, editProxiedData, setValue, reset, toggle]);

  return (
    <Modal size="lg" centered isOpen={editProxiedModal} toggle={handleCancel} className="transaction-modal budget-modal">
      <ModalHeader toggle={handleCancel}>{isProxiedEditMode ? "Edit Proxied Account" : "Add Proxied Account"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormInput
                label="* Proxied Account:"
                name={"proxiedAddress"}
                helperText={"The address that has previously setup a proxy to one of the accounts that you control."}
                errors={errors}
                placeholder="Address being proxied"
                register={register}
                validationRules={{
                  required: "Proxied account address is required",
                  validate: async (value) => {
                    const isProxied = await checkIfProxied(api, value);
                    if (!isProxied) {
                      return `Address is not a proxied`;
                    }
                    return true;
                  },
                }}
              />
            </Col>
            <Col sm="12">
              <FormInput label="* Name:" name={"proxiedName"} helperText={"The name is for unique identification of the account in your owner lists."} errors={errors} placeholder="Proxied name" register={register} validationRules={{ required: "Proxied name is required" }} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? "Saving..." : isProxiedEditMode ? "Update" : "Add"} {loading && <i className="fa-solid fa-circle-notch fa-spin" />}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddProxiedModal;
