import React, { createContext, useContext, useState } from "react";

const ProxiedContext = createContext();

export const ProxiedProvider = ({ children }) => {
  const [editProxiedModal, setEditProxiedModal] = useState(false);
  const [deleteProxiedModal, setDeleteProxiedModal] = useState(false);
  const toggleProxiedEdit = () => setEditProxiedModal((prev) => !prev);
  const toggleProxiedDelete = () => setDeleteProxiedModal((prev) => !prev);
  const [transactionUpdates, setTransactionUpdates] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentModal, setCurrentModal] = useState("connect");
  const [proxiedMultisigAccounts, setMultisigProxiedAccounts] = useState([]);
  const [editProxiedData, setEditProxiedData] = useState({});
  const [proxiedData, setProxiedData] = useState({});
  const [totalProxiedPages, setTotalProxiedPages] = useState();
  const [proxiedPage, setProxiedPage] = useState(1);
  const [proxiedPagelimit, setProxiedPageLimit] = useState(25);
  const [searchProxied, setSearchProxied] = useState("");
  const [proxiedLoading, setProxiedLoading] = useState(false);
  const [isProxiedEditMode, setIsProxiedEditMode] = useState(false);

  return <ProxiedContext.Provider value={{ toggleProxiedEdit, setIsProxiedEditMode, isProxiedEditMode, setProxiedLoading, proxiedLoading, proxiedPagelimit, setProxiedPageLimit, searchProxied, setSearchProxied, totalProxiedPages, setTotalProxiedPages, proxiedPage, setProxiedPage, proxiedData, setProxiedData, proxiedMultisigAccounts, setMultisigProxiedAccounts, transactionUpdates, setTransactionUpdates, toggleProxiedDelete, deleteProxiedModal, setEditProxiedData, editProxiedData, setCurrentModal, currentModal, editProxiedModal, setEditProxiedModal, toggleProxiedEdit, searchTerm, setSearchTerm }}>{children}</ProxiedContext.Provider>;
};
export const useProxied = () => {
  return useContext(ProxiedContext);
};
