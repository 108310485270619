import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import { blake2AsHex, isAddress } from "@polkadot/util-crypto";
import { BN } from "bn.js";
import React, { useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createMultisigTransactionApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import FormInput from "../../../CommonComponents/InputFields/FormInput";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import { truncateMiddle } from "../../../Utils/CustomFunctions";
import { getAvailBalance, getInjectorMetadata, getOtherSignatories } from "../../Common/AvailCommon";
import { deepParseJson } from "../../Common/CustomFunctions";
import { decodeCallData, getBlockNumber, getStructuredData } from "../Utils";
import { useBatchCallCsv } from "../Utils/useBatchCallCsv";

const BatchCallsProxiedModal = ({ isOpen, toggle, senderDetails, refetchGetData }) => {
  const [loading, setLoading] = useState(false);
  const { setTransactionUpdates, setTransactionLoading } = useMultisig();
  const [encodedCallData, setEncodedCallData] = useState(""); // New state for encoded call data
  const multisigDetailsOfProxiedAddress = deepParseJson(senderDetails.multisig_details);
  const [csvData, setCsvData] = useState([]); //
  const fileInputRef = useRef(null); // Reference for file input
  const [csvFile, setCsvFile] = useState(null); // Store uploaded CSV file
  const { api, accounts } = useWallet();
  const [pendingCsvData, setPendingCsvData] = useState([]);
  const [pendingCsvErrors, setPendingCsvErrors] = useState([]);
  const [csvText, setCsvText] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      from_address: senderDetails?.proxiedAddress || senderDetails?.proxied_address || "",
      transfers: [{ to_address: "", amount: "" }],
      selected_signatory: accounts[0]?.address || "",
    },
  });
  const { mutate } = usePerformMutation(() => refetchGetData());

  const { handleCSVUpload, removeCSV, handleCSVText } = useBatchCallCsv(setCsvData, setCsvFile, watch, reset, getValues, csvData, fileInputRef, setCsvText, setPendingCsvErrors, setPendingCsvData);

  const { fields, append, remove } = useFieldArray({ control, name: "transfers" });

  const signTransaction = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      setLoading(true);
      const injector = await web3FromSource("subwallet-js");
      if (!api) await injector.metadata.provide(getInjectorMetadata(api));
      const fromAddress = data?.selected_signatory;
      const callHash = await blake2AsHex(hexToU8a(encodedCallData));
      const decodedCallData = await decodeCallData(api, encodedCallData);
      const receiversDetails = await getStructuredData(decodedCallData?.args[2]?.args?.calls);
      const otherSignatories = getOtherSignatories(multisigDetailsOfProxiedAddress?.members, fromAddress);

      const transfer = api.tx.multisig.approveAsMulti(Number(multisigDetailsOfProxiedAddress?.threshold), otherSignatories, null, callHash, {
        refTime: 196085000,
        proofSize: 3593,
      });

      setTransactionLoading(true);
      await transfer.signAndSend(fromAddress, { signer: injector.signer }, async ({ status, events }) => {
        let extrinsicIndex = null;
        if (status.isInBlock) {
          events.forEach(({ phase }) => {
            if (phase.isApplyExtrinsic) {
              extrinsicIndex = phase.asApplyExtrinsic.toNumber();
            }
          });
          const blockNumber = await getBlockNumber(api, status);
          const mutableData = { call_data: encodedCallData, extrinsic: String(blockNumber) + "-" + String(extrinsicIndex), block: blockNumber, from: senderDetails?.proxied_address, to: JSON.stringify(receiversDetails), signers: JSON.stringify([fromAddress]), hash: callHash, threshold: multisigDetailsOfProxiedAddress?.threshold, status: "pending", amount: JSON.stringify(receiversDetails), comment: "Proxied transaction (batch call transfer)" };
          await mutate({ url: createMultisigTransactionApi, data: mutableData });
          setTransactionLoading(false);
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`First approval done for ${data.hash}`);
        });
      });

      reset({
        from_address: senderDetails?.proxied_address || senderDetails?.proxiedAddress || "",
        to_address: "",
        amount: "",
      });
      closeModal();
      setLoading(false);
      setEncodedCallData("");
      setTransactionUpdates(`First approval done for ${data.hash}`);
    } catch (err) {
      setLoading(false);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      toast.error(`Transaction failed: ${err.message}`);
    }
  };

  const closeModal = () => {
    toggle("batchCallsProxied");
  };

  const handleCancel = () => {
    closeModal();
    reset({
      from_address: senderDetails?.proxied_address || senderDetails?.proxiedAddress || "",
      transfers: [{ to_address: "", amount: "" }],
    });
  };

  const handleApplyCSV = () => {
    if (pendingCsvErrors.length > 0 || !pendingCsvData.length) return;

    const currentTransfers = watch("transfers");
    const nonEmptyTransfers = currentTransfers.filter((t) => t.to_address || t.amount);
    const emptySlots = currentTransfers.length - nonEmptyTransfers.length;

    const mergedTransfers = [...nonEmptyTransfers, ...pendingCsvData.slice(0, emptySlots), ...pendingCsvData.slice(emptySlots)].filter((t, i) => i < currentTransfers.length || t.to_address || t.amount);

    reset({
      ...getValues(),
      transfers: mergedTransfers,
    });

    setCsvData(pendingCsvData);
    setPendingCsvData([]);
    setPendingCsvErrors([]);
    setCsvText("");
  };

  const handleAddMore = () => {
    setEncodedCallData("");
    append({ to_address: "", amount: "" });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const proxyType = "Any"; // Change if using a specific proxy type
      // Generate batch transactions
      const proxiedCalls = data.transfers.map(({ to_address, amount }) => {
        const amountString = String(amount).replace(/,/g, ""); // Remove commas
        const amountFloat = parseFloat(amountString); // Convert to float

        if (isNaN(amountFloat)) {
          throw new Error(`Invalid amount: ${amount}`);
        }

        const amountInWei = new BN((amountFloat * 1e18).toFixed(0)); // Convert to BN (fixed to integer)
        return api.tx.balances.transferKeepAlive(to_address, amountInWei);
      });

      // Wrap transactions in a batch call
      const batchTransaction = api.tx.utility.batch(proxiedCalls);

      // Wrap batch call inside a proxied call
      const proxyCall = api.tx.proxy.proxy(data?.from_address, proxyType, batchTransaction);

      // Encode the final proxied batch call data
      const callData = proxyCall.method.toHex();

      setEncodedCallData(callData);
    } catch (error) {
      console.error("Error generating proxied batch call data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Batch Transfer Via Proxied"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(encodedCallData ? signTransaction : onSubmit)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12" className="mb-0">
              <FormInput label="From Address:" name={"from_address"} errors={errors} register={register} validationRules={{ required: "Address is required" }} readOnly />
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Signatory:</label>
                <select {...register("selected_signatory", { required: "Signatory must be selected" })} className={`form-control ${errors.selected_signatory ? "is-invalid" : ""}`}>
                  <option value="" disabled>
                    Select address
                  </option>
                  {accounts
                    ?.filter((member) => multisigDetailsOfProxiedAddress?.members?.some((account) => account.address.toLowerCase() === member.address.toLowerCase()))
                    ?.map((member, index) => (
                      <option key={index} value={member?.address}>
                        {`${member?.meta?.name?.toUpperCase()} [${truncateMiddle(member?.address, 16)}]`}
                      </option>
                    ))}
                </select>
                {errors.selected_signatory && <div className="invalid-feedback">{errors.selected_signatory.message}</div>}
              </FormGroup>
            </Col>

            {fields.map((field, index) => (
              <Row key={field.id} className="g-3 custom-bath-calls mt-0">
                <Col sm="7">
                  <FormGroup className="form-group">
                    <label className="mb-2 form-label">* To Address:</label>
                    <input
                      {...register(`transfers.${index}.to_address`, {
                        required: "Address is required",
                        validate: async (value) => {
                          if (!isAddress(value)) {
                            return `Address is not valid`;
                          }
                          return true;
                        },
                      })}
                      className={`form-control ${errors.transfers?.[index]?.to_address ? "is-invalid" : ""}`}
                      type="text"
                      placeholder="Enter recipient address"
                    />
                    {errors.transfers?.[index]?.to_address && <div className="invalid-feedback">{errors.transfers[index].to_address.message}</div>}
                  </FormGroup>
                </Col>

                <Col sm="5">
                  <FormGroup className="form-group">
                    <label className="mb-2 form-label">* Amount:</label>
                    <input
                      {...register(`transfers.${index}.amount`, {
                        required: "Amount is required",
                        pattern: { value: /^[0-9]+(\.[0-9]+)?$/, message: "Amount must be a number" },

                        validate: async (value) => {
                          const availableBalance = await getAvailBalance(api, watch("from_address"));
                          const transfers = watch("transfers");
                          const totalAmount = transfers.reduce((sum, t) => sum + parseFloat(t.amount || "0"), 0);
                          if (totalAmount > availableBalance) {
                            return `Total amount (${totalAmount}) exceeds available balance (${availableBalance} AVAIL)`;
                          }
                          return true;
                        },
                      })}
                      className={`form-control ${errors.transfers?.[index]?.amount ? "is-invalid" : ""}`}
                      type="text"
                      placeholder="Enter amount"
                    />
                    {errors.transfers?.[index]?.amount && <div className="invalid-feedback">{errors.transfers[index].amount.message}</div>}
                  </FormGroup>
                </Col>

                <div className="close-round-icon">
                  {index > 0 && (
                    <div
                      onClick={() => {
                        setEncodedCallData("");
                        remove(index);
                      }}
                    >
                      <i className="fa-regular fa-circle-xmark" />
                    </div>
                  )}
                </div>
              </Row>
            ))}
            <Col sm="12" className="mb-3">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Upload CSV File:</label>
                <input ref={fileInputRef} type="file" accept=".csv" className="form-control" defaultValue="" onChange={handleCSVUpload} />
                {csvFile && <i onClick={removeCSV}>Remove </i>}
              </FormGroup>
            </Col>
            {/* Preview Section */}
            <Col sm={12}>
              <FormGroup>
                <label className="mb-2 form-label">Or Paste CSV</label>
                <textarea className={`form-control ${pendingCsvErrors.length ? "is-invalid" : ""}`} value={csvText} onChange={(e) => handleCSVText(e.target.value)} rows={5} placeholder={`address1,100\naddress2,200`} />
                {pendingCsvErrors.length > 0 && <div className="invalid-feedback">{pendingCsvErrors[0]}</div>}
              </FormGroup>

              <div className="d-flex gap-2 mt-2">
                <Button
                  // outline
                  color="primary"
                  disabled={!pendingCsvData.length || pendingCsvErrors.length > 0}
                  onClick={handleApplyCSV}
                  size="sm"
                >
                  Apply CSV Data
                </Button>
                <Button
                  // outline
                  color="danger"
                  onClick={() => {
                    setCsvText("");
                    setPendingCsvData([]);
                    setPendingCsvErrors([]);
                    removeCSV();
                  }}
                  size="sm"
                >
                  Clear
                </Button>
              </div>
            </Col>
            {/* Display the generated encoded call data */}
            {encodedCallData && (
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Encoded Call Data:</label>
                  <textarea className="form-control" value={encodedCallData} rows="5" readOnly />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between align-items-center w-100 mt-3">
            <div>
              <Button type="button" color="success" onClick={handleAddMore}>
                + Add More
              </Button>
            </div>

            <div className="d-flex gap-2">
              <Button type="button" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                {loading ? (encodedCallData ? "Signing..." : "Submitting...") : encodedCallData ? "Sign And Send" : "Submit Batch"}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default BatchCallsProxiedModal;
