import React, { useEffect } from "react";
import { Col, FormGroup, Table } from "reactstrap";
import { useWallet } from "../../../../Context/WalletContext";
import { decodeCallData } from "../../Utils";

const TransactionDetails = ({ callData, setDecodedCallData, setTransactionsTableData, transactionsTableData }) => {
  const { api } = useWallet();

  useEffect(() => {
    const fetchDecodedTransaction = async () => {
      if (callData && api) {
        try {
          const decodedData = await decodeCallData(api, callData);
          setDecodedCallData(decodedData);
          let transactions = [];
          if (decodedData?.section == "utility" && decodedData?.method == "batch") {
            transactions = decodedData?.args[0]?.map((tx) => ({
              id: tx.args.dest.Id,
              value: tx.args.value,
              method: tx.method,
              section: tx.section,
            }));
          } else if (decodedData?.section == "proxy" && decodedData?.method == "proxy") {
            console.log("hello");
            transactions = decodedData?.args[2].args?.calls?.length
              ? decodedData?.args[2].args?.calls?.map((tx) => ({
                  id: tx.args.dest.Id,
                  value: tx.args.value,
                  method: tx.method,
                  section: tx.section,
                }))
              : [
                  {
                    id: decodedData?.args?.[2]?.args?.dest?.Id,
                    value: decodedData?.args?.[2]?.args?.value,
                    method: decodedData?.args[2]?.method,
                    section: decodedData?.args[2]?.section,
                  },
                ];
          } else {
            transactions = [
              {
                id: decodedData?.args?.[0]?.Id,
                value: decodedData?.args?.[1],
                method: decodedData?.method,
                section: decodedData?.section,
              },
            ];
          }
          setTransactionsTableData(transactions);
        } catch (error) {
          console.error("Failed to decode call data:", error);
        }
      }
    };

    fetchDecodedTransaction();
  }, [callData, api]);

  return (
    <Col sm="12">
      <FormGroup className="form-group">
        <label className="mb-2 form-label">Transaction Details</label>
        <Table bordered>
          <thead>
            <tr>
              <th>Receiver</th>
              <th>Amount</th>
              <th>Method</th>
              <th>Section</th>
            </tr>
          </thead>
          <tbody>
            {transactionsTableData?.length > 0 ? (
              transactionsTableData.map((tx, index) => (
                <tr key={index}>
                  <td>{tx.id}</td>
                  <td>{Number(tx.value?.replace(/,/g, "")) / Math.pow(10, 18) + " AVAIL"}</td>
                  <td>{tx.method}</td>
                  <td>{tx.section}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No transaction details available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </FormGroup>
    </Col>
  );
};

export default TransactionDetails;
